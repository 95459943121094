import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Col, Row, Button, Card, Alert, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  updateTransById,
  selectTransById,
} from '../../../redux/features/laudo/transSlice';
import {
  loadingOpen,
  loadingClose,
} from '../../../redux/features/context/contextSlice';
import Loading from '../../../pages/Index/Loading';
import api from '../../../services/api';
import { dblaudo, enviarLaudo } from '../../../util/laudo';
import history from '../../../services/history';
import CustomFileInput from '../../../components/CustomFileInput';
import { liberarLaudoByPac } from '../../../services/prontuario';

export default function FormTrans() {
  const dispatch = useDispatch();
  const { trans } = useSelector(state => state.trans);
  const { loading } = useSelector(state => state.contexto);
  const medico = useSelector(state => state.doctor.profile);
  const [show, setShow] = useState(false);
  const [files, setFiles] = useState([]);
  const [showUpload, setShowUpload] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [transLaudo, setTransLaudo] = useState({
    ...trans[0],
    CODTRANS: trans[0]?.CODTRANS || '',
    ECOTRANS: trans[0]?.ECOTRANS || '',
  });

  useEffect(() => {
    if (medico?.tipo === 'Externo') history.push('/notAllowed');
  }, [medico]);

  const handleTipoTrans = () => {
    setTransLaudo({ ...transLaudo, ECOTRANS: dblaudo.trans[0].normal });
    document.getElementById('editDescricao').focus();
  };

  const handleGravar = async event => {
    event.preventDefault();
    dispatch(loadingOpen());
    await dispatch(updateTransById(transLaudo));
    await dispatch(selectTransById(transLaudo.CODTRANS));
    dispatch(loadingClose());
  };

  async function liberarLaudo(e) {
    e.preventDefault();

    const fatura = { codLaudo: transLaudo.CODTRANS, codPac: transLaudo.CODPAC };

    try {
      const response = await liberarLaudoByPac({ fatura });
      setShow(true);
      setMensagem(response);
    } catch (error) {
      setMensagem('Erro ao liberar laudo!');
    }
  }

  // TODO: generate presignedUrl in the backend, then move this fn to a custom hook
  async function handleUploadFile(file) {
    if (!file) return toast.warn('Selecione um arquivo!');

    const encodedFilename = encodeURIComponent(file.name);

    try {
      const { data } = await api.put(
        `https://j87jesh814.execute-api.us-east-1.amazonaws.com/dev/image-upload/folder/trans/laudo/${transLaudo?.CODTRANS}/key/${encodedFilename}`
      );

      await fetch(data.preSignedUrl, { method: 'PUT', body: file });
    } catch (error) {
      toast.error('Erro ao fazer upload do arquivo!');
    }
  }

  async function confirmUpload() {
    for (const file of files) {
      await handleUploadFile(file);
    }

    toast.success('Arquivo enviado com sucesso!');
  }

  function closeFileModal() {
    setShowUpload(false);
    setFiles([]);
  }

  return (
    <>
      <Card style={{ marginTop: 10 }}>
        <Card.Header className="text-center">
          <Card.Title>LAUDO DE TRANSESOFÁGICO</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form noValidate>
            <strong className="mr-auto">Nome do Paciente: </strong>
            {`${transLaudo.CODPAC} - ${transLaudo.NOMEPAC}`}
            <br></br>
            <strong className="mr-auto">Data de Nasc: </strong>
            {`${transLaudo.dataNasc}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Sexo: </strong>
            {`${transLaudo.SEXOPAC}`}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Idade: </strong>
            {`${transLaudo.idadePAC} anos`}
            <br></br>
            <strong className="mr-auto">Convênio: </strong>
            {transLaudo.SIGLACONV}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Cod Trans: </strong>
            {transLaudo.CODTRANS}&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Data do Exame: </strong>
            {transLaudo.dataTrans}
            <br></br>
            <strong className="mr-auto">Médico que Realizou: Dr. </strong>
            {`${transLaudo.Realizou}`}
            <br></br>
            <strong className="mr-auto">Médico que Solicitou: Dr. </strong>
            {`${transLaudo.Solicitou}`}
            <hr></hr>
            <Card>
              <div key="radio" className="m-3">
                <Row xs={12} sm={6} md={2} lg={2}>
                  <Col sm={6}>
                    <strong>TRANSESOFÁGICO</strong>
                    <Form.Check
                      label="Transesofágico Normal"
                      name="groupTrans"
                      type="radio"
                      onClick={handleTipoTrans}
                    />
                  </Col>
                </Row>
              </div>
            </Card>
            &nbsp;
            {show && <Alert variant="success">{mensagem}</Alert>}
            <Form.Row>
              <Col sm>
                <Form.Group size="sm" controlId="editDescricao">
                  <Form.Label>
                    <strong>DESCRIÇÃO</strong>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={transLaudo?.ECOTRANS?.split('\n').length + 3 || 2}
                    name="ECOTRANS"
                    value={transLaudo.ECOTRANS ? transLaudo.ECOTRANS : ''}
                    onChange={e =>
                      setTransLaudo({ ...transLaudo, ECOTRANS: e.target.value })
                    }
                    onKeyDown={e => {
                      if (e.key === 'F2') {
                        const posStart = transLaudo.ECOTRANS.indexOf('---');
                        const elDesc = document.getElementById('editDescricao');
                        elDesc.setSelectionRange(posStart, posStart + 3);
                      }
                    }}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Card className="mt-3 text-center">
              <Card.Footer className="text-muted">
                <Button variant="success" onClick={handleGravar}>
                  Gravar
                </Button>{' '}
                <Button
                  variant="dark"
                  disabled={medico ? false : true}
                  onClick={liberarLaudo}
                >
                  Liberar
                </Button>{' '}
                {/* <Button
                  variant="secondary"
                  onClick={() =>
                    enviarLaudo({
                      CODPROCEDI: transLaudo.CODPROCEDI,
                      CODLAUDO: transLaudo.CODTRANS,
                      // CODFATURA: transLaudo.CODFATURA,
                      NOMEPAC: transLaudo.NOMEPAC,
                      CODPAC: transLaudo.CODPAC,
                      CELULARPAC: transLaudo.CELULARPAC,
                      GUIA1: 'SIM', // transLaudo.GUIA1,
                    })
                  }
                >
                  Enviar Laudo
                </Button>{' '} */}
                <Button onClick={() => history.push('/reportTrans')}>
                  Imprimir
                </Button>{' '}
                <Button variant="warning" onClick={() => setShowUpload(true)}>
                  Anexar
                </Button>{' '}
                <Button
                  variant="danger"
                  onClick={() => history.push('/agenda')}
                >
                  Fechar
                </Button>
              </Card.Footer>
            </Card>
          </Form>
        </Card.Body>
      </Card>

      {loading && <Loading />}

      <Modal show={showUpload} onHide={closeFileModal}>
        <Modal.Header closeButton>
          <Modal.Title>Anexar arquivo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomFileInput
            id="file-upload"
            label="Selecionar arquivo"
            multiple={true}
            onChange={e => setFiles(Array.from(e.target.files))}
          />
          <ul>{files && files.map(file => <li>{file.name}</li>)}</ul>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={confirmUpload}>Confirmar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
