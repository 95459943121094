import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { addDays, parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { Table, Button, Alert } from 'react-bootstrap';
import {
  loadingOpen,
  loadingClose,
  printOpen,
} from '../../../redux/features/context/contextSlice';
import {
  selectStressById,
  selectStressByIdOLD,
} from '../../../redux/features/laudo/stressSlice';
import { selectEcgById } from '../../../redux/features/laudo/ecgSlice';
import { selectTransById } from '../../../redux/features/laudo/transSlice';
import { selectErgoById } from '../../../redux/features/laudo/ergoSlice';
import { selectEcoById } from '../../../redux/features/laudo/ecoSlice';
import { selectFetalById } from '../../../redux/features/laudo/fetalSlice';
import api from '../../../services/api';
import history from '../../../services/history';
import Loading from '../../../pages/Index/Loading';

export default function Atividade() {
  const dispatch = useDispatch();
  const paciente = useSelector(state => state.patient.profile);
  const { loading } = useSelector(state => state.contexto);
  const [atividades, setAtividades] = useState([]);
  const [search, setSearch] = useState(false);
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    async function handleAtividades(paciente) {
      dispatch(printOpen());
      setSearch(false);
      let c = 0;
      if (paciente.CODPAC !== 0) {
        dispatch(loadingOpen());
        api
          .get(`paciente/atividades?id=${paciente.codPac}`)
          .then(result => {
            const arrayAtividades = result.data
              .filter(
                atividade =>
                  atividade.CODPROCEDI !== 1 &&
                  atividade.CODPROCEDI !== 6 &&
                  atividade.CODPROCEDI !== 7 &&
                  atividade.CODPROCEDI !== 58 &&
                  atividade.CODPROCEDI !== 800 &&
                  atividade.GUIA1 === 'SIM'
              )
              .map(atividade => ({
                ...atividade,
                dataFormatada: format(
                  addDays(parseISO(atividade.DATALAUDO), 1),
                  'dd/MM/yyyy',
                  { locale: pt }
                ),
                counter: (c += 1),
              }));

            setAtividades(arrayAtividades);
            if (arrayAtividades.length > 0) {
              setSearch(true);
              setAlert(false);
            } else {
              setSearch(false);
              setAlert(true);
            }
            dispatch(loadingClose());
          })
          .catch(err => toast.error(err.response.data.error));
      } else {
        toast.warn('Paciente não identificado!');
      }
    }
    handleAtividades(paciente);
  }, [dispatch, paciente]);

  async function handleStress(atividade) {
    dispatch(loadingOpen());
    if (atividade.CODLAUDO > 81610) {
      await dispatch(selectStressById(atividade.CODLAUDO));
      history.push('/reportStress');
    } else {
      await dispatch(selectStressByIdOLD(atividade.CODLAUDO));
      history.push('/reportStress');
    }

    dispatch(loadingClose());
  }

  async function handleTrans(atividade) {
    dispatch(loadingOpen());
    await dispatch(selectTransById(atividade.CODLAUDO));
    history.push('/reportTrans');
    dispatch(loadingClose());
  }

  async function handleEcg(atividade) {
    dispatch(loadingOpen());
    await dispatch(selectEcgById(atividade.CODLAUDO));
    history.push('/reportEcg');
    dispatch(loadingClose());
  }

  async function handleErgo(atividade) {
    dispatch(loadingOpen());
    await dispatch(selectErgoById(atividade.CODLAUDO));
    history.push('/reportErgo');
    dispatch(loadingClose());
  }

  async function handleEco(atividade) {
    dispatch(loadingOpen());
    let c = 0;

    dispatch(selectEcoById(atividade.CODLAUDO)).then(eco => {
      const listaEco = { ...eco, counter: (c += 1) };
      if (listaEco.TIPOECO === 'EcoC') {
        if (listaEco.CODREALIZOU === 4860) {
          history.push('/reportEcoCongenitoTXT');
        } else {
          history.push('/reportEcoCongenito');
        }
      } else if (listaEco.TIPOECO === 'EcoD') {
        history.push('/reportDoppler');
      }
    });

    dispatch(loadingClose());
  }

  async function handleFetal(atividade) {
    dispatch(loadingOpen());
    await dispatch(selectFetalById(atividade.CODLAUDO));
    history.push('/reportFetal');
    dispatch(loadingClose());
  }

  return (
    <>
      <h4 style={{ marginTop: 10, color: 'blue' }}>Resultado de Exames</h4>

      {search ? (
        <Table responsive="sm" bordered hover size="sm" variant="primary">
          <thead>
            <tr>
              <th>#</th>
              <th>Procedimento</th>
              <th>Convênio</th>
              <th>Data</th>
              <th>Laudo</th>
            </tr>
          </thead>
          <tbody>
            {atividades.map(a => (
              <tr key={a.counter}>
                <td>{a.counter}</td>
                {a.CODPROCEDI === 13 ? (
                  <td>
                    <Button
                      size="sm"
                      variant="link"
                      onClick={() => handleStress(a)}
                    >
                      {a.DESCPROCEDI}
                    </Button>
                  </td>
                ) : a.CODPROCEDI === 14 || a.CODPROCEDI === 15 ? (
                  <td>
                    <Button
                      size="sm"
                      variant="link"
                      onClick={() => {
                        history.push({
                          pathname: '/reportCarotida',
                          state: { codLaudo: a.CODLAUDO },
                        });
                      }}
                    >
                      {a.DESCPROCEDI}
                    </Button>
                  </td>
                ) : a.CODPROCEDI === 17 ? (
                  <td>
                    <Button
                      size="sm"
                      variant="link"
                      onClick={() => handleFetal(a)}
                    >
                      {a.DESCPROCEDI}
                    </Button>
                  </td>
                ) : a.CODPROCEDI === 4 ? (
                  <td>
                    <Button
                      size="sm"
                      variant="link"
                      onClick={() => handleErgo(a)}
                    >
                      {a.DESCPROCEDI}
                    </Button>
                  </td>
                ) : a.CODPROCEDI === 3 ? (
                  <td>
                    <Button
                      size="sm"
                      variant="link"
                      onClick={() => handleEcg(a)}
                    >
                      {a.DESCPROCEDI}
                    </Button>
                  </td>
                ) : a.CODPROCEDI === 12 ? (
                  <td>
                    <Button
                      size="sm"
                      variant="link"
                      onClick={() => handleTrans(a)}
                    >
                      {a.DESCPROCEDI}
                    </Button>
                  </td>
                ) : a.CODPROCEDI === 11 ||
                  a.CODPROCEDI === 712 ||
                  a.CODPROCEDI === 713 ||
                  a.CODPROCEDI === 714 ? (
                  <td>
                    <Button
                      size="sm"
                      variant="link"
                      onClick={() => handleEco(a)}
                    >
                      {a.DESCPROCEDI}
                    </Button>
                  </td>
                ) : (
                  <td>{a.DESCPROCEDI}</td>
                )}
                <td>{a.SIGLACONV}</td>
                <td>{a.dataFormatada}</td>
                <td>{a.CODLAUDO}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : alert ? (
        <Alert variant="primary">
          - Resultado de Exame ainda não foi liberado
        </Alert>
      ) : null}

      {loading && <Loading />}
    </>
  );
}
