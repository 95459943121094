import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Button, Card } from 'react-bootstrap';
import espergo from '../../../assets/esp-ergo.png';
import styles from '../../../styles/styles.module.css';
import getPrefix from '../../../util/getPrefix';

export default function EspelhoErgo() {
  const { ergo } = useSelector(state => state.ergo);

  return (
    <Card>
      <Card.Header className="text-center">
        <Card.Title className={styles.titleReport}>
          LAUDO DE TESTE ERGOMÉTRICO
        </Card.Title>
      </Card.Header>

      <Card.Body>
        <Form noValidate className={styles.contentReport}>
          <strong className="mr-auto">Cod Ergo: </strong>
          {ergo?.CODEXAME}&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Nome do Paciente: </strong>
          {`${ergo?.CODPAC} - ${ergo?.NOMEPAC}`}
          <br></br>
          <strong className="mr-auto">Data de Nasc: </strong>
          {`${ergo?.dataNasc}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Sexo: </strong>
          {`${ergo?.SEXOPAC}`}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Idade: </strong>
          {`${ergo?.idadePAC} anos`}
          <br></br>
          <strong className="mr-auto">Convênio: </strong>
          {ergo?.SIGLACONV}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Data do Exame: </strong>
          {ergo?.dataErgo}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Peso: </strong>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Altura: </strong>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Fone: </strong>
          {ergo?.CELULARPAC}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <br></br>
          <strong className="mr-auto">
            Médico que Realizou: {getPrefix(ergo?.CODREALIZOU)}{' '}
          </strong>
          {`${ergo?.Realizou}`}
          <br></br>
          <strong className="mr-auto">
            Médico que Solicitou: {getPrefix(ergo?.CODSOLICITANTE)}{' '}
          </strong>
          {`${ergo?.Solicitou}`}
          <hr></hr>
          <div className={styles.imageReport}>
            <img
              width="100%"
              height="130%"
              loading="lazy"
              src={espergo}
              alt="espelho ergo"
            />{' '}
          </div>
          <div id="removeMe" className="text-right">
            <Button variant="outline-primary" onClick={() => window.print()}>
              Imprimir
            </Button>{' '}
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
}
