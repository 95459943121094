import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Spinner } from 'react-bootstrap';
import api from '../services/api';

const ShortUrl = () => {
  const { token } = useParams();

  useEffect(() => {
    api
      .get(`/url/${token}`)
      .then(response => {
        window.location.href = response.data[0].url_original;
      })
      .catch(error => {
        console.error('Erro ao buscar URL encurtada:', error);
      });
  }, [token]);

  if (token) {
    return (
      <Container className="mt-5 text-center">
        <Spinner animation="border" variant="success" role="status" />
      </Container>
    );
  }

  return <p>...</p>;
};

export default ShortUrl;
