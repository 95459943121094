import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Form, Card, Row, Col, Button } from 'react-bootstrap';
import InputMask from 'react-bootstrap-maskedinput';
import { toast } from 'react-toastify';
import api from '../../../services/api';

export default function PacienteInfo({ patient }) {
  const initialState = useMemo(
    () => ({
      CODPAC: 0,
      NOMEPAC: '',
      DTNPAC: '',
      CELULARPAC: '',
      ENDERECO_NR: '',
      BAIRROPAC: '',
      ENDRESPAC: '',
      ESTADOPAC: '',
      LOGRA_PAC: '',
      CIDADEPAC: '',
      FONERESPAC: '',
      FONECOMPAC: '',
      INFORMACAO: '',
      EMPRESA: '',
      PROFISSPAC: '',
      CODFUNC: '',
      RGPAC: '',
      DATAPAC: '',
      SEXOPAC: '',
      LOCNASCPAC: '', // carterinha
      SETORPAC: '',
      CEPPAC: '',
      ENDCOMPAC: '',
      ENDMARPAC: '', // cpf
      FONERECPAC: '',
      EMAILPAC: '',
      ESTCIVIL: '',
      CORPAC: '',
      NACIONPAC: '',
      FOTOPAC: '',
      OBSPAC: '',
      CODINTERNADO: '',
      DATACADPAC: '',
      VALIDADE: '',
      ENDERECO_COMPL: '',
      CODCIDADE_PAC: '',
      SENHA: '',
      FOTOPACIENTE: '',
      EMAIL_PAC: '',
      VALIDADOR: '',
      dataFormatada: '',
    }),
    []
  );
  const numInput = useRef(null);
  const [paciente, setPaciente] = useState(initialState);

  useEffect(() => {
    setPaciente(patient);
  }, [patient]);

  async function handleInsertPaciente(e) {
    e.preventDefault();
    if (!e.currentTarget.checkValidity()) {
      e.stopPropagation();
    } else {
      try {
        await api.post(`/pacientes`, paciente);
        toast.success('Paciente Cadastrado com Sucesso!');
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  }

  async function handleUpdatePaciente(e) {
    e.preventDefault();
    if (!e.currentTarget.checkValidity()) {
      e.stopPropagation();
    } else {
      try {
        await api.put(`/paciente`, paciente);
        toast.success('Paciente cadastrado com sucesso!');
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  }

  const handleGravarPaciente = e => {
    if (patient?.CODPAC !== 0) {
      handleUpdatePaciente(e);
    } else {
      handleInsertPaciente(e);
    }
  };

  const handleChange = e => {
    e.preventDefault();
    setPaciente({ ...paciente, [e.target.name]: e.target.value });
  };

  const handleCep = () => {
    fetch(`https://viacep.com.br/ws/${paciente?.CEPPAC}/json/`)
      .then(response => response.json())
      .then(data => {
        setPaciente({
          ...paciente,
          ENDRESPAC: data.logradouro,
          BAIRROPAC: data.bairro,
          CIDADEPAC: data.localidade,
          ESTADOPAC: data.uf,
        });
        numInput.current.focus();
      })
      /* eslint-disable no-console */
      .catch(error => console.error(`Erro CEP: ${error}`));
  };

  return (
    <>
      <Card.Body>
        <Form>
          <Row>
            <Col sm={2}>
              <Form.Group controlId="formCodPac">
                <Form.Label>Código</Form.Label>
                <Form.Control
                  type="text"
                  value={paciente?.CODPAC || ''}
                  name="CODPAC"
                  onChange={e => handleChange(e)}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group controlId="formNome">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  style={{
                    textTransform: 'uppercase',
                    borderColor: 'green',
                    backgroundColor: 'rgba(255, 255, 0, 0.3)',
                  }}
                  type="text"
                  value={paciente?.NOMEPAC || ''}
                  name="NOMEPAC"
                  onChange={e => handleChange(e)}
                />
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group controlId="formDtNasc">
                <Form.Label>Sexo</Form.Label>
                <Col>
                  <Form.Check
                    className="my-0 mr-1"
                    style={{
                      borderColor: 'green',
                      backgroundColor: 'rgba(255, 255, 0, 0.3)',
                    }}
                    inline
                    label="Masculino"
                    type="radio"
                    id="radioMasculino"
                    checked={paciente?.SEXOPAC === 'M'}
                    name="SEXOPAC"
                    onChange={() => setPaciente({ ...paciente, SEXOPAC: 'M' })}
                  />
                  <Form.Check
                    inline
                    style={{
                      borderColor: 'green',
                      backgroundColor: 'rgba(255, 255, 0, 0.3)',
                    }}
                    label="Feminino"
                    type="radio"
                    id="radioFeminino"
                    name="SEXOPAC"
                    checked={paciente?.SEXOPAC === 'F'}
                    onChange={() => setPaciente({ ...paciente, SEXOPAC: 'F' })}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group controlId="formCPF">
                <Form.Label>Status</Form.Label>
                <Col>
                  <Form.Check
                    className="my-0 mr-1"
                    style={{
                      borderColor: 'green',
                      backgroundColor: 'rgba(255, 255, 0, 0.3)',
                    }}
                    inline
                    label="Ativo"
                    type="radio"
                    id="radioAtivo"
                    checked={paciente?.SETORPAC === 1}
                    name="SETORPAC"
                    onChange={() => setPaciente({ ...paciente, SETORPAC: 1 })}
                  />
                  <Form.Check
                    inline
                    style={{
                      borderColor: 'green',
                      backgroundColor: 'rgba(255, 255, 0, 0.3)',
                    }}
                    label="Inativo"
                    type="radio"
                    id="radioInativo"
                    name="SETORPAC"
                    checked={paciente?.SETORPAC === 0}
                    onChange={() => setPaciente({ ...paciente, SETORPAC: 0 })}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <Form.Group controlId="formDtNasc">
                <Form.Label>Data de Nascimento</Form.Label>
                <InputMask
                  type="text"
                  style={{
                    textTransform: 'uppercase',
                    borderColor: 'green',
                    backgroundColor: 'rgba(255, 255, 0, 0.3)',
                  }}
                  mask="11/11/1111"
                  onChange={e => handleChange(e)}
                  name="DTNPAC"
                  value={paciente?.dataFormatada || ''}
                />
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group controlId="formCPF">
                <Form.Label>CPF</Form.Label>
                <InputMask
                  type="text"
                  style={{
                    textTransform: 'uppercase',
                    borderColor: 'green',
                    backgroundColor: 'rgba(255, 255, 0, 0.3)',
                  }}
                  mask="111.111.111-11"
                  onChange={e => handleChange(e)}
                  name="ENDMARPAC"
                  value={paciente?.ENDMARPAC || ''}
                />
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group controlId="formEmpresa">
                <Form.Label>Nr Carteirinha</Form.Label>
                <Form.Control
                  type="text"
                  value={paciente?.LOCNASCPAC || ''}
                  name="LOCNASCPAC"
                  onChange={e => handleChange(e)}
                />
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group controlId="formProfiss">
                <Form.Label>Profissão</Form.Label>
                <Form.Control
                  type="text"
                  value={paciente?.PROFISSPAC || ''}
                  name="PROFISSPAC"
                  onChange={e => handleChange(e)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={2}>
              <Form.Group controlId="formEnd">
                <Form.Label>CEP</Form.Label>
                <InputMask
                  type="text"
                  style={{
                    textTransform: 'uppercase',
                    borderColor: 'green',
                    backgroundColor: 'rgba(255, 255, 0, 0.3)',
                  }}
                  mask="11111-111"
                  onChange={e => handleChange(e)}
                  name="CEPPAC"
                  value={paciente?.CEPPAC || ''}
                  onBlur={handleCep}
                  maxLength="8"
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group controlId="formCidade">
                <Form.Label>Endereço</Form.Label>
                <Form.Control
                  type="text"
                  value={paciente?.ENDRESPAC || ''}
                  name="ENDRESPAC"
                  onChange={e => handleChange(e)}
                />
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Número</Form.Label>
                <Form.Control
                  onChange={e => handleChange(e)}
                  name="ENDERECO_NR"
                  value={paciente?.ENDERECO_NR || ''}
                  ref={numInput}
                />
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Complemento</Form.Label>
                <Form.Control
                  onChange={e => handleChange(e)}
                  name="ENDERECO_COMPL"
                  value={paciente?.ENDERECO_COMPL || ''}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Form.Group controlId="formCidade">
                <Form.Label>Cidade</Form.Label>
                <Form.Control
                  type="text"
                  value={paciente?.CIDADEPAC || ''}
                  name="CIDADEPAC"
                  onChange={e => handleChange(e)}
                />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group controlId="formBairro">
                <Form.Label>Bairro</Form.Label>
                <Form.Control
                  type="email"
                  value={paciente?.BAIRROPAC || ''}
                  name="BAIRROPAC"
                  onChange={e => handleChange(e)}
                />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group controlId="formEstado">
                <Form.Label>Estado</Form.Label>
                <Form.Control
                  type="text"
                  value={paciente?.ESTADOPAC || ''}
                  name="ESTADOPAC"
                  onChange={e => handleChange(e)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <Form.Group controlId="foneCelular">
                <Form.Label>Fone Celular</Form.Label>
                <InputMask
                  type="text"
                  style={{
                    textTransform: 'uppercase',
                    borderColor: 'green',
                    backgroundColor: 'rgba(255, 255, 0, 0.3)',
                  }}
                  mask="(111)11111-1111"
                  onChange={e => handleChange(e)}
                  name="CELULARPAC"
                  value={paciente?.CELULARPAC || ''}
                />
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group controlId="formFoneRes">
                <Form.Label>Fone Residencial</Form.Label>
                <Form.Control
                  type="text"
                  value={paciente?.FONERESPAC || ''}
                  name="FONERESPAC"
                  onChange={e => handleChange(e)}
                />
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group controlId="formFoneComer">
                <Form.Label>Fone Comercial</Form.Label>
                <Form.Control
                  type="text"
                  value={paciente?.FONECOMPAC || ''}
                  name="FONECOMPAC"
                  onChange={e => handleChange(e)}
                />
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group controlId="formEmpresa">
                <Form.Label>Empresa</Form.Label>
                <Form.Control
                  type="text"
                  value={paciente?.EMPRESA || ''}
                  name="EMPRESA"
                  onChange={e => handleChange(e)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group controlId="formObs">
                <Form.Label>Observações</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={paciente?.INFORMACAO || ''}
                  name="INFORMACAO"
                  onChange={e => handleChange(e)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Card.Body>
      <Card.Footer className="text-center">
        <Button onClick={e => handleGravarPaciente(e)}>Gravar</Button>
      </Card.Footer>
    </>
  );
}
