import React, { useState } from 'react';

const TELEGRAM_BOT_TOKEN = '7676441315:AAER1YLWOUASJ1qMXh59V-T39pwE_Qcfpho';

const CHAT_ID = '-4729648546';

const sendMessage = async message => {
  const url = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`;

  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      chat_id: CHAT_ID,
      text: message,
    }),
  });

  return response.json();
};

const TelegramBot = () => {
  const [message, setMessage] = useState('');

  const handleSendMessage = async () => {
    if (!message) return;
    const result = await sendMessage(message);
    alert(result.ok ? 'Message sent!' : 'Failed to send');
    setMessage('');
  };

  return (
    <div className="p-4">
      <h2 className="text-lg font-bold">Send Message to Telegram Bot</h2>
      <input
        type="text"
        className="border p-2 rounded w-full"
        placeholder="Type your message"
        value={message}
        onChange={e => setMessage(e.target.value)}
      />
      <button
        onClick={handleSendMessage}
        className="mt-2 p-2 bg-blue-500 text-white rounded"
      >
        Send
      </button>
    </div>
  );
};

export default TelegramBot;
