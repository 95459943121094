import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import md5 from 'md5';
import {
  Container,
  Col,
  Row,
  Card,
  Image,
  Spinner,
  Alert,
  Button,
} from 'react-bootstrap';
import api from '../../../services/api';
import header from '../../../assets/icm-header.jpg';
import styles from '../../../styles/styles.module.css';
import getPrefix from '../../../util/getPrefix';

export default function PublicEcg() {
  const { token } = useParams();
  const [ecgData, setEcgData] = useState(null);
  const [error, setError] = useState(null);

  function stripHtmlTags(html) {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  }

  useEffect(() => {
    const fetchEcgData = async () => {
      try {
        const response = await api.get(`/report/${token}`);
        setEcgData(response.data.laudo[0]);
      } catch (error) {
        setError(error.response.data.error);
      }
    };

    fetchEcgData();
  }, [token]);

  function removeDiv(divName) {
    document.getElementById(divName).style.visibility = 'hidden';
    window.scrollTo(0, 0); // Scroll to the top of the page
    window.print();
    document.getElementById(divName).style.visibility = 'visible';
  }

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  if (!ecgData) {
    return (
      <Container className="mt-5 text-center">
        <Spinner animation="border" role="status" />
      </Container>
    );
  }

  return (
    <Container className="mt-4">
      <div className={styles.imageReport}>
        <img width="600" height="110" src={header} alt="header" />
      </div>
      <Card>
        <Card.Body>
          <Row className="mt-2">
            <Col>
              <Image src={ecgData.ARQUIVO_URL} alt="ECG Imagem" fluid />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <strong>Descrição</strong>
              <p>{stripHtmlTags(ecgData.OBSERVACAO)}</p>
            </Col>
          </Row>
        </Card.Body>
        {/* ESSE HASH SERÁ GERADO NO SERVIDOR! */}
        <Card.Footer className="mt-3 text-center">
          Autenticação -{' '}
          <strong className="mr-auto">
            {getPrefix(ecgData?.CODREALIZOU)} {ecgData?.Realizou}
          </strong>{' '}
          <i className="mr-auto">
            -{' '}
            {md5(JSON.stringify(`${ecgData.CODPAC} - ${ecgData.CODECG}` || ''))}
          </i>
          <div id="removeMe" className="text-muted">
            <Button onClick={() => removeDiv('removeMe')}>Imprimir</Button>{' '}
          </div>
        </Card.Footer>
      </Card>
    </Container>
  );
}
