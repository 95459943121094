import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Card,
  Row,
  Col,
  Form,
  Button,
  Tabs,
  Tab,
  Table,
  Alert,
} from 'react-bootstrap';
import Creatable from 'react-select/creatable';
import Loading from '../../../pages/Index/Loading';
import history from '../../../services/history';
import api from '../../../services/api';
import { dblaudo } from '../../../util/laudo';
import {
  selectAvaliacaoByPac,
  avaliacaoSuccess,
  selectMarcapassoById,
} from '../../../redux/features/prontuario/avaliacaoSlice';
import {
  printClose,
  printOpen,
} from '../../../redux/features/context/contextSlice';

export default function FormAvaliacao() {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.contexto);
  const medico = useSelector(state => state.doctor.profile);
  const { avaliacao, marcapasso, avaliacoes } = useSelector(
    state => state.avaliacao
  );
  const memoMotivo = useMemo(
    () =>
      dblaudo.motivo
        .map(i => ({ value: i.CODMOTIVO, label: i.DESCRICAO.toUpperCase() }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    []
  );
  const memoEtiologia = useMemo(
    () =>
      dblaudo.etiologia
        .map(item => ({
          value: item.CODETIOLOGIA,
          label: item.DESCRICAO.toUpperCase(),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    []
  );
  const memoFabricante = useMemo(
    () =>
      dblaudo.fabricante
        .map(item => ({
          value: item.CODFABRICANTE,
          label: item.FABRICANTE.toUpperCase(),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    []
  );
  const memoModelo = useMemo(
    () =>
      dblaudo.modelo
        .map(i => ({ value: i.CODMODELO, label: i.MODELO.toUpperCase() }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    []
  );
  const memoHospital = useMemo(
    () =>
      dblaudo.hospital
        .map(i => ({ value: i.value, label: i.label.toUpperCase() }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    []
  );
  const [marcaLaudo, setMarcaLaudo] = useState(marcapasso);
  const [guia, setGuia] = useState('');
  const [carta, setCarta] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [activeKey, setActiveKey] = useState('avaliacao');
  const [btnImprimir, setBtnImprimir] = useState(true);
  const [showAlertEditAvaliacao, setShowAlertEditAvaliacao] = useState(false);

  useEffect(() => {
    if (medico?.tipo === 'Externo') history.push('/notAllowed');
  }, [medico]);

  useEffect(() => {
    setMarcaLaudo(marcapasso);
  }, [marcapasso]);

  const handleGravarAvaliacao = async () => {
    const av = { ...avaliacao, CARTA: carta, GUIA: guia };
    dispatch(avaliacaoSuccess({ avaliacao: av }));
    try {
      const resp = await api.put(`avaliacao`, av);
      toast.success(resp.data.message);
      setBtnImprimir(false);
      dispatch(selectAvaliacaoByPac(avaliacao.CODPAC));
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };

  const handleGravarMarcapasso = async () => {
    try {
      const resp = await api.put(`/marcapasso`, marcaLaudo);
      toast.success(resp.data.message);
      setBtnImprimir(false);
      dispatch(selectAvaliacaoByPac(marcaLaudo.CODPAC));
      dispatch(selectMarcapassoById(marcaLaudo.CODMARCA));
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };

  const handleAvaliacao = avaliacao => {
    setShowAlertEditAvaliacao(false);
    setCarta(avaliacao.CARTA);
    setGuia(avaliacao.GUIA);
    dispatch(avaliacaoSuccess({ avaliacao }));
    setShowAlertEditAvaliacao(true);
  };

  const handleEditarAvaliacao = () => {
    setCarta(
      '\nQueixas: \n\n' +
        'Exame físico: \n\n' +
        'Medicações: \n\n' +
        'Avaliação de limiares:   LCA:              LCV: \n\n' +
        'Condução atrioventricular: \n\n' +
        'Arritmias: \n\n' +
        'Terapias: \n\n' +
        'Longevidade da Bateria:       Impedância-Atrial:                 Ventricular: \n\n' +
        'SEM IMÃ: FR =           ; LP =                 / COM IMÃ: FR =          ; LP =    \n\n' +
        'Programação final:   Modo de Estimulação:           FR Basal:       AVP:        AVS: \n\n' +
        'Átrio:                Ventrículo:      \n\n' +
        'HDX: \n\n' +
        'Conduta: '
    );
  };

  const handleTipoAvaliacao = tipo => {
    switch (tipo) {
      case 'Marcapasso':
        setMarcaLaudo({ ...marcaLaudo, MODOESTIM: tipo });
        break;
      case 'TRC':
        setMarcaLaudo({ ...marcaLaudo, MODOESTIM: tipo });
        break;
      case 'CDI':
        setMarcaLaudo({ ...marcaLaudo, MODOESTIM: tipo });
        break;
      case 'CDI/TRC':
        setMarcaLaudo({ ...marcaLaudo, MODOESTIM: tipo });
        break;
      default:
    }
  };

  return (
    <>
      <Tabs
        defaultActiveKey="avaliacao"
        id="tabs-avaliacao"
        activeKey={activeKey}
        onSelect={key => setActiveKey(key)}
        className="mb-3"
        style={{
          borderColor: 'green',
          background: 'green',
          borderRadius: '2em',
          padding: '0.3em 1.5em',
          letterSpacing: '0.3em',
          fontWeight: 'bolder',
        }}
        fill
      >
        <Tab eventKey="avaliacao" title="Avaliação de Implante">
          <Row>
            <Col sm={4}>
              <Card>
                <Card.Header>
                  <Card.Title>Avaliações do Paciente</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Table
                    className="m-0 p-0"
                    responsive="sm"
                    striped
                    bordered
                    hover
                    size="sm"
                    variant="success"
                  >
                    <thead className="m-0 p-0">
                      {avaliacoes?.length > 0 ? (
                        <tr>
                          <th>#</th>
                          <th>Avaliação</th>
                          <th>Data</th>
                          <th>Paciente</th>
                          <th>#</th>
                        </tr>
                      ) : null}
                    </thead>
                    <tbody className="m-0 p-0">
                      {avaliacoes?.length > 0
                        ? avaliacoes.map(a => (
                            <tr key={a.counter} className="m-0 p-0">
                              <td className="m-0 p-0">{a.counter}</td>
                              <td className="m-0 p-0">{a.CODAVALIACAO}</td>
                              <td className="m-0 p-0">{a.dataAvaliacao}</td>
                              <td className="m-0 p-0">{a.CODPAC}</td>
                              <td>
                                <Form.Check
                                  type="radio"
                                  defaultChecked
                                  label=""
                                  onClick={() => handleAvaliacao(a)}
                                />
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td style={{ textAlign: 'right' }} colSpan="4">
                          TOTAL:
                        </td>
                        <td style={{ textAlign: 'left' }} colSpan="1">
                          {avaliacoes.length + 1}
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={8}>
              <Card>
                <Card.Header>
                  <Card.Title>Editar Avaliação do Paciente</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Alert show={showAlertEditAvaliacao} variant="warning">
                    <Alert.Heading>
                      Deseja editar uma Nova Avaliação de Implante?
                    </Alert.Heading>
                    <p>
                      Avaliação:{' '}
                      {avaliacao.CODAVALIACAO
                        ? `${avaliacao.CODAVALIACAO} - ${avaliacao.dataAvaliacao}`
                        : ''}{' '}
                      - Paciente: {avaliacao.NOMEPAC}
                    </p>
                    <hr />
                    <div className="d-flex justify-content-">
                      <Button
                        onClick={() => handleEditarAvaliacao()}
                        variant="warning"
                      >
                        Confirma Edição de Nova AVALIAÇÃO?
                      </Button>
                    </div>
                  </Alert>
                  <Card className="mb-1">
                    <Card.Body>
                      <Card.Header>
                        <h4 className="mb-0">
                          <span className="text-success">Dados Pessoais</span>
                        </h4>
                      </Card.Header>
                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="editNomePAC">
                            <Form.Label>Nome do Paciente</Form.Label>
                            <Form.Control
                              readOnly
                              defaultValue={avaliacao.NOMEPAC}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="editNOMEMED">
                            <Form.Label>Nome do Médico</Form.Label>
                            <Form.Control
                              readOnly
                              defaultValue={avaliacao.Avaliou}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="editNomePAC">
                            <Form.Label>Convênio</Form.Label>
                            <Form.Control
                              readOnly
                              defaultValue={avaliacao.SIGLACONV}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group controlId="editNomePAC">
                            <Form.Label>Data</Form.Label>
                            <Form.Control
                              readOnly
                              defaultValue={avaliacao.dataAvaliacao}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group controlId="editNomePAC">
                            <Form.Label>Guia</Form.Label>
                            <Form.Control
                              value={guia || ''}
                              onChange={e => setGuia(e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Form.Group className="mb-3" controlId="editCarta">
                    <Form.Label>Descrição da Avaliação</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={25}
                      value={carta || ''}
                      onChange={e => setCarta(e.target.value)}
                    />
                  </Form.Group>
                  <Alert show={showAlertEditAvaliacao} variant="success">
                    <Alert.Heading>
                      Deseja GRAVAR a Avaliação de Implante?
                    </Alert.Heading>
                    <p>
                      Avaliação:{' '}
                      {avaliacao.CODAVALIACAO
                        ? `${avaliacao.CODAVALIACAO} - ${avaliacao.dataAvaliacao}`
                        : ''}{' '}
                      - Paciente: {avaliacao.NOMEPAC}
                    </p>
                    <hr />
                    <div className="d-flex justify-content-">
                      <Button
                        onClick={handleGravarAvaliacao}
                        size="lg"
                        variant="success"
                      >
                        Gravar
                      </Button>
                      {'  '}&nbsp;
                      <Button
                        variant="secondary"
                        size="lg"
                        disabled={btnImprimir}
                        onClick={() => {
                          dispatch(printClose());
                          history.push('/reportAvaliacao');
                        }}
                      >
                        Imprimir
                      </Button>
                      {'  '}&nbsp;
                      <Button
                        variant="info"
                        size="lg"
                        disabled={btnImprimir}
                        onClick={() => {
                          dispatch(printOpen());
                          history.push('/reportAvaliacao');
                        }}
                      >
                        Timbrado
                      </Button>{' '}
                    </div>
                  </Alert>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="marcapasso" title="Implante de Marcapasso">
          {' '}
          <div>
            <Card.Header className="mb-1">
              <Card.Title className="text-center">MARCAPASSO</Card.Title>
            </Card.Header>
            <Form noValidate>
              <Card className="mb-1">
                <Card.Body>
                  <Card.Header>
                    <h4 className="mb-0">
                      <span className="text-success">Dados Pessoais</span>
                    </h4>
                  </Card.Header>
                  <Row>
                    <Col sm={1}>
                      <Form.Group controlId="editNomePAC">
                        <Form.Label>CodMarca</Form.Label>
                        <Form.Control
                          readOnly
                          defaultValue={marcaLaudo.CODMARCA}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <Form.Group controlId="editNomePAC">
                        <Form.Label>Data</Form.Label>
                        <Form.Control
                          readOnly
                          defaultValue={marcaLaudo.dataMarca}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="editNomePAC">
                        <Form.Label>Cadastro</Form.Label>
                        <Form.Control
                          readOnly
                          defaultValue={marcaLaudo.HORAMARCA}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <Form.Group controlId="editNomePAC">
                        <Form.Label>CodConv</Form.Label>
                        <Form.Control
                          readOnly
                          defaultValue={marcaLaudo.CODCONV}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={5}>
                      <Form.Group controlId="editNomePAC">
                        <Form.Label>Convênio</Form.Label>
                        <Form.Control
                          readOnly
                          defaultValue={marcaLaudo.SIGLACONV}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={1}>
                      <Form.Group controlId="editNomePAC">
                        <Form.Label>CodPac</Form.Label>
                        <Form.Control
                          style={{ fontSize: '14px' }}
                          readOnly
                          defaultValue={marcaLaudo.CODPAC}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={5}>
                      <Form.Group controlId="editNomePAC">
                        <Form.Label>Nome do Paciente</Form.Label>
                        <Form.Control
                          readOnly
                          defaultValue={marcaLaudo.NOMEPAC}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <Form.Group controlId="editNOMEMED">
                        <Form.Label>CodMed</Form.Label>
                        <Form.Control
                          readOnly
                          defaultValue={marcaLaudo.CODREALIZOU}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={5}>
                      <Form.Group controlId="editNOMEMED">
                        <Form.Label>Nome do Médico</Form.Label>
                        <Form.Control
                          readOnly
                          defaultValue={marcaLaudo.NOMEMED}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card className="mb-1">
                <Card.Body>
                  <Card.Header>
                    <h4 className="mb-0">
                      <span className="text-success">
                        Informações do Implante
                      </span>
                    </h4>
                  </Card.Header>
                  <Row>
                    <Col sm={4}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Hospital</Form.Label>
                        <Creatable
                          name="hospital"
                          menuPlacement="top"
                          placeholder={marcaLaudo?.HOSPITAL || 'Hospital'}
                          options={memoHospital}
                          value={marcaLaudo?.HOSPITAL}
                          onCreateOption={option =>
                            setMarcaLaudo({
                              ...marcaLaudo,
                              HOSPITAL: option.label,
                            })
                          }
                          onChange={option => {
                            setSelectedOption(option.label);
                            setMarcaLaudo({
                              ...marcaLaudo,
                              HOSPITAL: option.label,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Tipo</Form.Label>
                        <Card>
                          <div
                            key={`radio`}
                            className="m-1"
                            style={{ fontSize: 12 }}
                          >
                            <Row xs={12} sm={6} md={2} lg={2}>
                              <Col sm={6} style={{ align: 'left' }}>
                                <Form.Check
                                  label="Marcapasso"
                                  checked={
                                    marcaLaudo.MODOESTIM === 'Marcapasso'
                                      ? true
                                      : false
                                  }
                                  name="groupTipoAv"
                                  type="radio"
                                  id="Marcapasso"
                                  onChange={event =>
                                    handleTipoAvaliacao(event.target.id)
                                  }
                                />
                                <Form.Check
                                  label="TRC"
                                  checked={marcaLaudo.MODOESTIM === 'TRC'}
                                  name="groupTipoAv"
                                  type="radio"
                                  id="TRC"
                                  onChange={event =>
                                    handleTipoAvaliacao(event.target.id)
                                  }
                                />
                                <Form.Check
                                  label="CDI"
                                  checked={marcaLaudo.MODOESTIM === 'CDI'}
                                  name="groupTipoAv"
                                  type="radio"
                                  id="CDI"
                                  onChange={event =>
                                    handleTipoAvaliacao(event.target.id)
                                  }
                                />
                                <Form.Check
                                  label="CDI/TRC"
                                  name="groupTipoAv"
                                  checked={marcaLaudo.MODOESTIM === 'CDI/TRC'}
                                  type="radio"
                                  id="CDI/TRC"
                                  onChange={event =>
                                    handleTipoAvaliacao(event.target.id)
                                  }
                                />
                              </Col>
                            </Row>
                          </div>
                        </Card>{' '}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Motivo Implante</Form.Label>
                        <Creatable
                          name="motivo"
                          menuPlacement="top"
                          placeholder={
                            (marcaLaudo.motivo &&
                              marcaLaudo.motivo[0]?.DESCRICAO) ||
                            'Motivo Implante'
                          }
                          options={memoMotivo}
                          value={dblaudo.motivo.filter(
                            opt => opt.label === selectedOption
                          )}
                          onCreateOption={option =>
                            setMarcaLaudo({
                              ...marcaLaudo,
                              motivo: {
                                CODMOTIVO: option.value,
                                DESCRICAO: option.label,
                              },
                            })
                          }
                          onChange={option => {
                            setSelectedOption(option.label);
                            setMarcaLaudo({
                              ...marcaLaudo,
                              motivo: option.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Etiologia</Form.Label>
                        <Creatable
                          name="etiologia"
                          menuPlacement="top"
                          placeholder={
                            (marcaLaudo.etiologia &&
                              marcaLaudo.etiologia[0]?.DESCRICAO) ||
                            'Etiologia'
                          }
                          options={memoEtiologia}
                          value={dblaudo.etiologia.filter(
                            opt => opt.label === selectedOption
                          )}
                          onCreateOption={option =>
                            setMarcaLaudo({
                              ...marcaLaudo,
                              etiologia: {
                                CODETIOLOGIA: option.value,
                                DESCRICAO: option.label,
                              },
                            })
                          }
                          onChange={option => {
                            setSelectedOption(option.label);
                            setMarcaLaudo({
                              ...marcaLaudo,
                              etiologia: option.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card className="mb-1">
                <Card.Body>
                  <Card.Header>
                    <h4 className="mb-0">
                      <span className="text-success">Dados do Gerador</span>
                    </h4>
                  </Card.Header>
                  <Row>
                    <Col sm={3}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Fabricante do Gerador</Form.Label>
                        <Creatable
                          name="fabricante"
                          menuPlacement="top"
                          placeholder={marcaLaudo?.CODFABGER || 'Fabricante'}
                          options={memoFabricante}
                          value={dblaudo.fabricante.filter(
                            opt => opt.label === selectedOption
                          )}
                          onCreateOption={option =>
                            setMarcaLaudo({
                              ...marcaLaudo,
                              CODFABGER: option.label,
                            })
                          }
                          onChange={option => {
                            setSelectedOption(option.label);
                            setMarcaLaudo({
                              ...marcaLaudo,
                              CODFABGER: option.label,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Modelo do Gerador</Form.Label>
                        <Creatable
                          name="modelo"
                          menuPlacement="top"
                          placeholder={marcaLaudo?.CODMODGER || 'Modelo'}
                          options={memoModelo}
                          value={dblaudo.modelo.filter(
                            opt => opt.label === selectedOption
                          )}
                          onCreateOption={option =>
                            setMarcaLaudo({
                              ...marcaLaudo,
                              CODMODGER: option.label,
                            })
                          }
                          onChange={option => {
                            setSelectedOption(option.label);
                            setMarcaLaudo({
                              ...marcaLaudo,
                              CODMODGER: option.label,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Nr Série</Form.Label>
                        <Form.Control
                          defaultValue={marcaLaudo.SERIEGER}
                          onChange={e => {
                            setMarcaLaudo({
                              ...marcaLaudo,
                              SERIEGER: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Dt Implante</Form.Label>
                        <Form.Control
                          defaultValue={marcaLaudo.dataGer}
                          onChange={e => {
                            setMarcaLaudo({
                              ...marcaLaudo,
                              DTIMPGER: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card className="mb-1">
                <Card.Body>
                  <Card.Header>
                    <h4 className="mb-0">
                      <span className="text-success">Dados dos Eletrodos</span>
                    </h4>
                  </Card.Header>
                  <Row>
                    <Col sm={3}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Eletrodo Atrial</Form.Label>
                        <Creatable
                          name="fabricante"
                          menuPlacement="top"
                          placeholder={marcaLaudo?.CODFABELAT || 'Fabricante'}
                          options={memoFabricante}
                          value={dblaudo.fabricante.filter(
                            opt => opt.label === selectedOption
                          )}
                          onCreateOption={option =>
                            setMarcaLaudo({
                              ...marcaLaudo,
                              CODFABELAT: option.label,
                            })
                          }
                          onChange={option => {
                            setSelectedOption(option.label);
                            setMarcaLaudo({
                              ...marcaLaudo,
                              CODFABELAT: option.label,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Modelo do Eletrodo</Form.Label>
                        <Creatable
                          name="modelo"
                          menuPlacement="top"
                          placeholder={marcaLaudo?.CODMODELAT || 'Modelo'}
                          options={memoModelo}
                          value={dblaudo.modelo.filter(
                            opt => opt.label === selectedOption
                          )}
                          onCreateOption={option =>
                            setMarcaLaudo({
                              ...marcaLaudo,
                              CODMODELAT: option.label,
                            })
                          }
                          onChange={option => {
                            setSelectedOption(option.label);
                            setMarcaLaudo({
                              ...marcaLaudo,
                              CODMODELAT: option.label,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Nr Série</Form.Label>
                        <Form.Control
                          defaultValue={marcaLaudo.SERIEELAT}
                          onChange={e => {
                            setMarcaLaudo({
                              ...marcaLaudo,
                              SERIEELAT: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Dt Implante</Form.Label>
                        <Form.Control
                          defaultValue={marcaLaudo.dataElAt}
                          onChange={e => {
                            setMarcaLaudo({
                              ...marcaLaudo,
                              DTIMPELAT: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={3}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Eletrodo VD</Form.Label>
                        <Creatable
                          name="fabricante"
                          menuPlacement="top"
                          placeholder={marcaLaudo?.CODFABELVE || 'Fabricante'}
                          options={memoFabricante}
                          value={dblaudo.fabricante.filter(
                            opt => opt.label === selectedOption
                          )}
                          onCreateOption={option =>
                            setMarcaLaudo({
                              ...marcaLaudo,
                              CODFABELVE: option.label,
                            })
                          }
                          onChange={option => {
                            setSelectedOption(option.label);
                            setMarcaLaudo({
                              ...marcaLaudo,
                              CODFABELVE: option.label,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Modelo do VD</Form.Label>
                        <Creatable
                          name="modelo"
                          menuPlacement="top"
                          placeholder={marcaLaudo?.CODMODELVE || 'Modelo'}
                          options={memoModelo}
                          value={dblaudo.modelo.filter(
                            opt => opt.label === selectedOption
                          )}
                          onCreateOption={option =>
                            setMarcaLaudo({
                              ...marcaLaudo,
                              CODMODELVE: option.label,
                            })
                          }
                          onChange={option => {
                            setSelectedOption(option.label);
                            setMarcaLaudo({
                              ...marcaLaudo,
                              CODMODELVE: option.label,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Nr Série</Form.Label>
                        <Form.Control
                          defaultValue={marcaLaudo.SERIEELVE}
                          onChange={e => {
                            setMarcaLaudo({
                              ...marcaLaudo,
                              SERIEELVE: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Dt Implante</Form.Label>
                        <Form.Control
                          defaultValue={marcaLaudo.dataElVEDir}
                          onChange={e => {
                            setMarcaLaudo({
                              ...marcaLaudo,
                              DTIMPELVE: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={3}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Eletrodo VE</Form.Label>
                        <Creatable
                          name="fabricante"
                          menuPlacement="top"
                          placeholder={
                            marcaLaudo?.CODFABELVEESQ || 'Fabricante'
                          }
                          options={memoFabricante}
                          value={dblaudo.fabricante.filter(
                            opt => opt.label === selectedOption
                          )}
                          onCreateOption={option =>
                            setMarcaLaudo({
                              ...marcaLaudo,
                              CODFABELVEESQ: option.label,
                            })
                          }
                          onChange={option => {
                            setSelectedOption(option.label);
                            setMarcaLaudo({
                              ...marcaLaudo,
                              CODFABELVEESQ: option.label,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Modelo do VE</Form.Label>
                        <Creatable
                          name="modelo"
                          menuPlacement="top"
                          placeholder={marcaLaudo?.CODMODELVEESQ || 'Modelo'}
                          options={memoModelo}
                          value={dblaudo.modelo.filter(
                            opt => opt.label === selectedOption
                          )}
                          onCreateOption={option =>
                            setMarcaLaudo({
                              ...marcaLaudo,
                              CODMODELVEESQ: option.label,
                            })
                          }
                          onChange={option => {
                            setSelectedOption(option.label);
                            setMarcaLaudo({
                              ...marcaLaudo,
                              CODMODELVEESQ: option.label,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Nr Série</Form.Label>
                        <Form.Control
                          defaultValue={marcaLaudo.SERIEELVEESQ}
                          onChange={e => {
                            setMarcaLaudo({
                              ...marcaLaudo,
                              SERIEELVEESQ: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Dt Implante</Form.Label>
                        <Form.Control
                          defaultValue={marcaLaudo.dataElVEEsq}
                          onChange={e => {
                            setMarcaLaudo({
                              ...marcaLaudo,
                              DTIMPELVEESQ: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card className="mb-1">
                <Card.Body>
                  <Card.Header>
                    <h4 className="mb-0">
                      <span className="text-success">Medições</span>
                    </h4>
                  </Card.Header>
                  <Row>
                    <Col sm={2}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label></Form.Label>
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Eletrodo Atrial UNI</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Eletrodo Atrial BI</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Eletrodo VD UNI</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Eletrodo VD BI</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Eletrodo VE UNI</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Eletrodo VE BI</Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={2}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Impedância (Ohms)</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Control
                          defaultValue={marcaLaudo.RESITAT}
                          onChange={e => {
                            setMarcaLaudo({
                              ...marcaLaudo,
                              RESITAT: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Control
                          defaultValue={marcaLaudo.RESISTATBI}
                          onChange={e => {
                            setMarcaLaudo({
                              ...marcaLaudo,
                              RESISTATBI: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Control
                          defaultValue={marcaLaudo.RESITVE}
                          onChange={e => {
                            setMarcaLaudo({
                              ...marcaLaudo,
                              RESITVE: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Control
                          defaultValue={marcaLaudo.RESISTVDBI}
                          onChange={e => {
                            setMarcaLaudo({
                              ...marcaLaudo,
                              RESISTVDBI: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Control
                          defaultValue={marcaLaudo.RESISTVESQUNI}
                          onChange={e => {
                            setMarcaLaudo({
                              ...marcaLaudo,
                              RESISTVESQUNI: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Control
                          defaultValue={marcaLaudo.RESISTVESQBI}
                          onChange={e => {
                            setMarcaLaudo({
                              ...marcaLaudo,
                              RESISTVESQBI: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={2}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Sensibilidade (mV)</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Control
                          defaultValue={marcaLaudo.ONDAP}
                          onChange={e => {
                            setMarcaLaudo({
                              ...marcaLaudo,
                              ONDAP: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Control
                          defaultValue={marcaLaudo.ONDAPBI}
                          onChange={e => {
                            setMarcaLaudo({
                              ...marcaLaudo,
                              ONDAPBI: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Control
                          defaultValue={marcaLaudo.ONDAR}
                          onChange={e => {
                            setMarcaLaudo({
                              ...marcaLaudo,
                              ONDAR: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Control
                          defaultValue={marcaLaudo.ONDARVDBI}
                          onChange={e => {
                            setMarcaLaudo({
                              ...marcaLaudo,
                              ONDARVDBI: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Control
                          defaultValue={marcaLaudo.ONDARVESQUNI}
                          onChange={e => {
                            setMarcaLaudo({
                              ...marcaLaudo,
                              ONDARVESQUNI: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Control
                          defaultValue={marcaLaudo.ONDARVESQBI}
                          onChange={e => {
                            setMarcaLaudo({
                              ...marcaLaudo,
                              ONDARVESQBI: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={2}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Label>Limiar de Comando</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Control
                          defaultValue={marcaLaudo.LIMIARAT}
                          onChange={e => {
                            setMarcaLaudo({
                              ...marcaLaudo,
                              LIMIARAT: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Control
                          defaultValue={marcaLaudo.LIMIARATBI}
                          onChange={e => {
                            setMarcaLaudo({
                              ...marcaLaudo,
                              LIMIARATBI: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Control
                          defaultValue={marcaLaudo.LIMIARVE}
                          onChange={e => {
                            setMarcaLaudo({
                              ...marcaLaudo,
                              LIMIARVE: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Control
                          defaultValue={marcaLaudo.LIMIARVDBI}
                          onChange={e => {
                            setMarcaLaudo({
                              ...marcaLaudo,
                              LIMIARVDBI: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Control
                          defaultValue={marcaLaudo.LIMIARVESQUNI}
                          onChange={e => {
                            setMarcaLaudo({
                              ...marcaLaudo,
                              LIMIARVESQUNI: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <Form.Group size="sm" controlId="obs">
                        <Form.Control
                          defaultValue={marcaLaudo.LIMIARVESQBI}
                          onChange={e => {
                            setMarcaLaudo({
                              ...marcaLaudo,
                              LIMIARVESQBI: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Form>
            <Card className="mb-1">
              <Button
                variant="primary"
                size="lg"
                onClick={() => handleGravarMarcapasso()}
              >
                Gravar
              </Button>
            </Card>
          </div>
        </Tab>
      </Tabs>

      {loading && <Loading />}
    </>
  );
}
