/* eslint-disable no-bitwise */
/* eslint-disable no-shadow */
import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { makeStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import { IoMdClipboard } from 'react-icons/io';
import { format, subDays, subHours, addDays, addMonths } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { ptBR } from 'date-fns/locale';
import { Modal, Button, Alert } from 'react-bootstrap';
import api from '../../../services/api';
import medicoAgendaPac from '../../../util/medicoAgendaPac';
import convenioAgendaPac from '../../../util/convenioAgendaPac';
import formatarHora from '../../../util/formatarHora';

const useStyles = makeStyles(() => ({}));

function getSteps() {
  return [
    'Selecione um Convênio',
    'Selecione um Médico',
    'Selecione uma Data e Horário',
    'Finalizar o Agendamento',
  ];
}

function getStepContent(
  step,
  classes,
  selectedDate,
  selectedIdxMed,
  selectedIdxConv,
  selectedIdxAgenda,
  handleDateChange,
  handleMedicoClick,
  handleConvenioClick,
  medicos,
  convenios,
  handlePrevDay,
  handleNextDay,
  dateFormatted,
  horariosAgenda,
  handleHorarioClick,
  formatarHora,
  selectedHorario,
  handleChangeDtAgenda,
  dateAgenda
) {
  switch (step) {
    case 0:
      return (
        <List dense component="nav" aria-label="Lista de Convênios">
          {convenios.map((convenio, idxConv) => (
            <ListItem
              dense
              button
              key={convenio.codConv}
              selected={selectedIdxConv === idxConv}
              onClick={e =>
                handleConvenioClick(
                  e,
                  idxConv,
                  convenio.codConv,
                  convenio.sigla
                )
              }
            >
              <ListItemText>{convenio.sigla}</ListItemText>
            </ListItem>
          ))}
        </List>
      );
    case 1:
      return (
        <List dense component="nav" aria-label="Lista de Médicos">
          {medicos.map((medico, idxMed) => (
            <ListItem
              dense
              button
              key={medico.codigo}
              selected={selectedIdxMed === idxMed}
              onClick={e =>
                handleMedicoClick(e, idxMed, medico.codigo, medico.medico)
              }
            >
              <ListItemText>{medico.medico}</ListItemText>
            </ListItem>
          ))}
        </List>
      );
    case 2:
      return (
        <>
          <DatePicker
            selected={dateAgenda}
            onChange={handleChangeDtAgenda}
            inline
            locale={ptBR}
            minDate={new Date()}
            maxDate={addMonths(new Date(), 12)}
            showDisabledMonthNavigation
          />
          {horariosAgenda.length !== 0 ? (
            horariosAgenda.map((agenda, idxAgenda) => (
              <div key={agenda.CODAGENDA}>
                <ListItem
                  dense
                  button
                  key={agenda.CODAGENDA}
                  selected={selectedIdxAgenda === idxAgenda}
                  onClick={e =>
                    handleHorarioClick(e, idxAgenda, agenda.HORARIO, agenda)
                  }
                >
                  <ListItemText>{formatarHora(agenda.HORARIO)}</ListItemText>
                  <ListItemIcon>
                    <IoMdClipboard />
                  </ListItemIcon>
                </ListItem>
                <Divider />
              </div>
            ))
          ) : (
            <Alert variant="danger">
              NÃO TEM HORÁRIO DE CONSULTA PARA ESTE DIA SELECIONADO!
            </Alert>
          )}
        </>
      );
    case 3:
      return (
        <Alert variant="primary">
          <cite>Estamos quase concluindo o seu Agendamento online.</cite>
          <br />
          Clique em <strong>AGENDAR</strong> e confira os dados do seu
          Agendamento!
        </Alert>
      );
    default:
      return 'N/A';
  }
}

export default function PanelPaciente() {
  const paciente = useSelector(state => state.patient.profile);
  const [codMedico, setCodMedico] = useState();
  const [nomeMedico, setNomeMedico] = useState();
  const [codConvenio, setCodConvenio] = useState();
  const [siglaConvenio, setSiglaConvenio] = useState();
  const [dateAgenda, setDateAgenda] = useState(new Date());
  const [horariosAgenda, setHorariosAgenda] = useState([]);
  const [horario, setHorario] = useState();
  const [agenda, setAgenda] = useState([]);
  const [selectedHorario, setSelectedHorario] = useState(false);
  const [selectedIdxMed, setSelectedIdxMed] = useState();
  const [selectedIdxConv, setSelectedIdxConv] = useState();
  const [selectedIdxAgenda, setSelectedIdxAgenda] = useState();
  const [medicos, setMedicos] = useState(medicoAgendaPac);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [activeStep, setActiveStep] = useState(0);
  const [isProtocolo, setIsProtocolo] = useState(false);
  const [isError422, setIsError422] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    variant: 'primary',
    mensagem: '',
  });
  const [payload, setPayload] = useState({
    mensagem: '',
    celular: '',
    protocolo: '',
  });
  const [show, setShow] = useState(true);
  const steps = getSteps();
  const classes = useStyles();

  const dateFormatted = useMemo(
    () => format(dateAgenda, "dd 'de' MMMM 'de' yyyy", { locale: pt }),
    [dateAgenda]
  );

  const dateAgendaFormatted = useMemo(
    () => subHours(dateAgenda, 3).toISOString(),
    [dateAgenda]
  );

  useEffect(() => {
    async function loadAgendaAberta() {
      setIsProtocolo(false);
      const page = 1;
      if (codMedico) {
        const response = await api.get(
          `medico/agendaaberta?page=${page}&id=${codMedico}&dt=${dateAgendaFormatted}`
        );
        setHorariosAgenda(response.data);
      }
    }

    loadAgendaAberta();
  }, [
    agenda,
    codMedico,
    dateAgenda,
    dateAgendaFormatted,
    horario,
    paciente.codPac,
  ]);

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    if (activeStep === 1) {
      setMedicos(medicoAgendaPac);
    }
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  function handlePrev() {
    toast.error('Selecione uma opção!');
    setActiveStep(prevActiveStep => prevActiveStep);
  }

  function handlePrevDay() {
    setDateAgenda(subDays(dateAgenda, 1));
  }

  function handleNextDay() {
    setDateAgenda(addDays(dateAgenda, 1));
  }

  function handleChangeDtAgenda(dtAgenda) {
    setDateAgenda(dtAgenda);
  }

  function handleHorarioClick(_, index, horaAgenda, objAgenda) {
    objAgenda.codPac = paciente.codPac;
    objAgenda.situacao = 'Agendada';
    objAgenda.codConv = codConvenio; // 1;
    objAgenda.codProcedi = 1;
    objAgenda.atividade = 'CONSULTA';
    objAgenda.protocolo = `ICM-${new Date().getTime()}`;
    setHorario(horaAgenda);
    setAgenda(objAgenda);
    setSelectedHorario(true);
    setSelectedIdxAgenda(index);
    handleNext();
    toast.info(`Horário selecionado: ${formatarHora(horaAgenda)}`);
  }

  function handleReset() {
    setActiveStep(0);
    setSelectedDate(new Date());
  }

  function handleConfirm() {
    const cel = paciente.celular.replace(/\D/g, '').replace(/^0/, '+55');
    const msg = `[ICM Marília]: Olá ${paciente.nome},
Protocolo: ${agenda?.protocolo}
Médico: ${nomeMedico}
Convênio: ${siglaConvenio}
Data: ${dateFormatted} às ${formatarHora(horario)}
Local: Instituto do Coração de Marília - ICM
Endereço: Av. Vicente Ferreira 780 – ao lado do P.S. da Santa Casa
Chegar com 15 minutos de antecedência`;

    setPayload({
      mensagem: encodeURIComponent(msg),
      celular: cel,
      protocolo: agenda?.protocolo,
    });

    api
      // grava na Agenda e grava na tabela SMS_SEND
      .put('agenda', { agenda, sms: msg, celular: cel })
      .then(() => {
        toast.success('AGENDAMENTO REALIZADO COM SUCESSO!');
        setIsProtocolo(true);
        handleReset();
      })
      .catch(err => {
        if (err.response?.status === 422) {
          setAlert({
            show: true,
            variant: 'warning',
            mensagem: `${err.response?.data?.error || 'Dados inválidos'}!`,
          });
          setIsError422(true);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
          setAlert({
            show: true,
            variant: 'danger',
            mensagem: `${err.response?.data?.error || 'Erro inesperado.'}!`,
          });
        }
        handleReset();
        // toast.error(err.response.data.error);
      });
  }

  function checkConvenio(codConv) {
    const listMedicos = [];

    // 25 Particular Todos  - (1/5/958/957/24/2030/2657/2796/3283/1628/3794/4190)
    // 1 Cabesp Todos
    if (codConv === 25 || codConv === 1) {
      medicoAgendaPac.map(medico => {
        listMedicos.push(medico);
        setMedicos(listMedicos);
        setCodConvenio(codConv);
        return listMedicos;
      });
    }
    // 14 / 68 /69 /70 Unimed menos 5 Fábio, 1 Braga e 1628 Roberto
    // 2796 dr joao
    // if (codConv === 14 || codConv === 68 || codConv === 69 || codConv === 70) {
    if (codConv === 14 || codConv === 68 || codConv === 70) {
      medicoAgendaPac.map(medico => {
        if (
          medico.codigo !== 1 &&
          medico.codigo !== 5 &&
          // medico.codigo !== 2796 &&
          medico.codigo !== 1628
        ) {
          listMedicos.push(medico);
        }
        setMedicos(listMedicos);
        setCodConvenio(codConv);
        return listMedicos;
      });
    }
    // 65 Unimed Jacto Todos (1/5/958/957/24/2030/2657/2796/3283/3794/4190) menos 1628
    if (codConv === 65) {
      medicoAgendaPac.map(medico => {
        if (medico.codigo !== 1628) listMedicos.push(medico);
        setMedicos(listMedicos);
        setCodConvenio(codConv);
        return listMedicos;
      });
    }
    // 69 Unimed Intercambio - Dr Joao nao atende
    if (codConv === 69) {
      medicoAgendaPac.map(medico => {
        if (medico.codigo !== 2796 && medico.codigo !== 5)
          listMedicos.push(medico);
        setMedicos(listMedicos);
        setCodConvenio(codConv);
        return listMedicos;
      });
    }
    // 108 AMIL e 9 GEAP - Todos (1/5/958/957/24/2030/2657/2796/3283/3794/4190) menos Dr JOAO 2796
    if (codConv === 108 || codConv === 9) {
      medicoAgendaPac.map(medico => {
        if (medico.codigo !== 2796) listMedicos.push(medico);
        setMedicos(listMedicos);
        setCodConvenio(codConv);
        return listMedicos;
      });
    }

    // 732 São Francisco Médicos que atendem: 4190 Érica, 3283 Igor,
    // 2657 Marina, 3794 Raphael e 1628 Roberto
    if (codConv === 732) {
      medicoAgendaPac.map(med => {
        if (
          med.codigo === 4190 ||
          med.codigo === 2657 ||
          med.codigo === 3794 ||
          med.codigo === 1628
        ) {
          listMedicos.push(med);
        }
        setMedicos(listMedicos);
        setCodConvenio(codConv);
        return listMedicos;
      });
    }
    // 43 Bradesco Saúde Menos 1 Dr.Braga e Dr Joao 2796
    // 2 Cassi Menos Dr.Braga e Dr Joao 2796
    // 4 Economus Menos Dr.Braga1 e Dr Joao 2796
    // 108 - AMIL Menos Dr.Braga1 e Dr Joao 2796
    if (
      codConv === 43 ||
      codConv === 801 ||
      codConv === 2 ||
      codConv === 4 ||
      codConv === 108
    ) {
      medicoAgendaPac.map(med => {
        if (med.codigo !== 1 && med.codigo !== 2796) {
          listMedicos.push(med);
        }
        setMedicos(listMedicos);
        setCodConvenio(codConv);
        return listMedicos;
      });
    }
    // 5 Saúde Caixa Menos Dr.Braga 1
    // 3 Funcesp Menos Dr.Braga1
    // 20 APAS Menos Dr.Braga 1
    // 23 ACE Menos Dr.Braga 1
    // 7 Afresp Menos Dr.Braga 1
    // 13 SSPP Menos Dr.Braga 1
    if (
      codConv === 3 ||
      codConv === 7 ||
      codConv === 13 ||
      codConv === 20 ||
      codConv === 23
    ) {
      medicoAgendaPac.map(med => {
        if (med.codigo !== 1 && med.codigo !== 5) {
          listMedicos.push(med);
        }
        setMedicos(listMedicos);
        setCodConvenio(codConv);
        return listMedicos;
      });
    }

    return handleNext();
  }

  function handleDateChange(date) {
    setSelectedDate(date);
  }

  function calculaIdade(dateString) {
    const birthday = +new Date(dateString);
    return ~~((Date.now() - birthday) / 31557600000);
  }

  function handleMedicoClick(_, index, codMed, nomeMed) {
    const dtNascimento = Date.parse(paciente.dtNasc);
    const idade = calculaIdade(dtNascimento);

    if ((codMed === 4190 || codMed === 4613) && idade > 14) {
      toast.error(
        `Médico ${nomeMed} é Cardio Pediatra e só atende pacientes até 14 anos. Favor agendar com outro médico da equipe que atenda o seu convênio!`
      );
      handleReset();
    } else {
      setSelectedIdxMed(index);
      setCodMedico(codMed);
      setNomeMedico(nomeMed);
      handleNext();
    }
  }

  function handleConvenioClick(_, index, codConv, siglaConv) {
    setSelectedIdxConv(index);
    setCodConvenio(codConv);
    setSiglaConvenio(siglaConv);
    checkConvenio(codConv);
  }

  return (
    <>
      <h1 className="m-4 text-center">Agendamento de Consulta</h1>
      {isProtocolo && (
        <div className="container mt-1">
          <div className="alert alert-success">
            <h4>Agendamento Confirmado!</h4>
            <p>
              Seu agendamento foi realizado com sucesso. Guarde o protocolo
              abaixo para futuras consultas.
            </p>
            <p>
              <strong>Protocolo:</strong> {payload.protocolo}
            </p>
            <hr />
            <p>
              Para entrar em contato com nossa equipe, clique no botão abaixo
              para conversar via WhatsApp:
            </p>
            <a
              href="https://api.whatsapp.com/send?phone=5514998974587&text=Olá,%20não%20encontrei%20meu%20cadastro%20no%20sistema.%20Poderiam%20me%20ajudar?"
              className="btn btn-success btn-lg"
              target="_blank"
              rel="noopener noreferrer"
            >
              Falar com a Clínica no WhatsApp
            </a>
            <p className="mt-3">
              Ou, se preferir, ligue para nós pelo telefone:{' '}
              <strong>(14) 3402-5252</strong>.
            </p>
          </div>
        </div>
      )}
      <div className="row justify-content-center">
        <div className="col-lg-auto col-auto mt-2 p-2">
          <div style={{ textAlign: 'center' }}>
            <Alert variant="info">
              <Alert.Heading>Selecione um Convênio</Alert.Heading>
              <p>
                Lembre-se de selecionar um <strong>Médico</strong> antes de
                Selecionar o <strong>Horário</strong>!
              </p>
            </Alert>
          </div>
        </div>
      </div>
      <div>
        <div className="row justify-content-center">
          {alert.show && (
            <Alert variant={alert.variant} style={{ textAlign: 'right' }}>
              {alert.mensagem}
            </Alert>
          )}
          {isError422 && (
            <a
              href="https://api.whatsapp.com/send?phone=5514998974587&text=Olá,%20poderiam%20me%20ajudar?"
              className="btn btn-success btn-lg"
              target="_blank"
              rel="noopener noreferrer"
            >
              Falar com a Clínica no WhatsApp
            </a>
          )}
        </div>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {getStepContent(
                  index,
                  classes,
                  selectedDate,
                  selectedIdxMed,
                  selectedIdxConv,
                  selectedIdxAgenda,
                  handleDateChange,
                  handleMedicoClick,
                  handleConvenioClick,
                  medicos,
                  convenioAgendaPac,
                  handlePrevDay,
                  handleNextDay,
                  dateFormatted,
                  horariosAgenda,
                  handleHorarioClick,
                  formatarHora,
                  selectedHorario,
                  handleChangeDtAgenda,
                  dateAgenda
                )}
                <hr />
                <Button
                  size="sm"
                  variant="warning"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  Anterior
                </Button>{' '}
                <Button
                  size="sm"
                  onClick={
                    activeStep === steps.length - 1 ? handleNext : handlePrev
                  }
                >
                  {activeStep === steps.length - 1 ? 'Agendar' : 'Próximo'}
                </Button>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Modal show={show} onHide={() => setShow(false)} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmação de Agendamento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ul>
                <li>
                  Olá <strong>{paciente.nome}</strong>,
                </li>
                <li>
                  Estamos <i>"finalizando"</i> o seu Agendamento, por favor
                  confira os dados abaixo e clique em{' '}
                  <strong>"CONFIRMAR"</strong>!
                </li>
                <li>
                  <strong>Médico:</strong> {nomeMedico}
                </li>
                <li>
                  <strong>Convênio:</strong> {siglaConvenio}
                </li>
                <li>
                  <strong>Data:</strong> {dateFormatted} às{' '}
                  {formatarHora(horario)}
                </li>
                <li>
                  <strong>LOCAL:</strong> Av. Vicente Ferreira 780 – ao lado do
                  P.S. da Santa Casa
                </li>
                <li>Temos estacionamento próprio na Rua Amazonas, 777</li>
                <li>
                  <strong>OBS:</strong> Chegar com 15 minutos de antecedência
                </li>
                <li>
                  Você receberá uma Mensagem de confirmação do seu agendamento
                  no celular{' '}
                  <strong>
                    {paciente.celular.replace(/\D/g, '').replace(/^0/, '+55')}
                  </strong>
                </li>
              </ul>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="success"
                size="sm"
                onClick={e => handleConfirm(e, agenda)}
              >
                Confirmar
              </Button>{' '}
              <Button variant="warning" size="sm" onClick={handleReset}>
                Alterar
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </>
  );
}
