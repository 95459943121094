export default function getPrefix(code) {
  const isProf = code === 1 || code === 5 || code === 2796 || code === 958;
  return isProf ? 'Prof. Dr. ' : 'Dr. ';
}

function prefixo(laudo) {
  const codMedico = laudo?.codMedico || laudo?.CODMEDICO;
  if (
    codMedico === 1 ||
    codMedico === 5 ||
    codMedico === 2796 ||
    codMedico === 958
  ) {
    return 'Prof. Dr.';
  }
  return 'Dr.';
}

export { prefixo };
