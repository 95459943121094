import React from 'react';
import { Switch, Route as RouterDom } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import { toast } from 'react-toastify';
import Route from './routes';
import SignInMed from '../pages/doctor/SignInMed';
import SignInMedExterno from '../pages/doctor/SignInMedExterno';
import SignInFunc from '../pages/funcionario/SignInFunc';
import SignInPac from '../pages/patient/SignInPac';
import Tokenizer from '../pages/Index/Tokenizer';
import FormMedico from '../pages/doctor/Medico/FormMedico';
import DashboardPac from '../pages/patient/Dashboard';
import Laudos from '../pages/patient/Atividade';
import PanelPaciente from '../pages/patient/PanelPaciente';
import AgendaPaciente from '../pages/patient/AgendaPaciente';
import PerfilMedico from '../pages/doctor/Medico/PerfilMedico';
import PerfilPaciente from '../pages/patient/Paciente/PerfilPaciente';
import Home from '../pages/doctor/Index/Home';
import Agenda from '../pages/doctor/Agendamento/Agenda';
import Atividade from '../pages/doctor/Index/Atividade';
import ReportProntuario from '../pages/laudo/prontuario/ReportProntuario';
import FormProntuario from '../pages/laudo/prontuario/FormProntuario';
import FormDoppler from '../pages/laudo/eco/FormDoppler';
import ReportDoppler from '../pages/laudo/eco/ReportDoppler';
import EspelhoDoppler from '../pages/laudo/eco/EspelhoDoppler';
import EspelhoCong from '../pages/laudo/eco/EspelhoCong';
import FormCongenito from '../pages/laudo/eco/FormCongenito';
import ReportCong from '../pages/laudo/eco/ReportCong';
import ReportCongTXT from '../pages/laudo/eco/ReportCongTXT';
import ReportTrans from '../pages/laudo/trans/ReportTrans';
import EspelhoTrans from '../pages/laudo/trans/EspelhoTrans';
import FormTrans from '../pages/laudo/trans/FormTrans';
import FormEcg from '../pages/laudo/ecg/FormEcg';
import ReportStress from '../pages/laudo/stress/ReportStress';
import EspelhoFisio from '../pages/laudo/stress/EspelhoFisio';
import EspelhoFarmaco from '../pages/laudo/stress/EspelhoFarmaco';
import FormStress from '../pages/laudo/stress/FormStress';
import ReportCarotida from '../pages/laudo/carotida/ReportCarotida';
import FormAvaliacao from '../pages/laudo/avaliacao/FormAvaliacao';
import ReportAvaliacao from '../pages/laudo/avaliacao/ReportAvaliacao';
import EspelhoCarotida from '../pages/laudo/carotida/EspelhoCarotida';
import FormCarotida from '../pages/laudo/carotida/FormCarotida';
import FormFetal from '../pages/laudo/fetal/FormFetal';
import ReportFetal from '../pages/laudo/fetal/ReportFetal';
import EspelhoFetal from '../pages/laudo/fetal/EspelhoFetal';
import ReportErgo from '../pages/laudo/ergo/ReportErgo';
import EspelhoErgo from '../pages/laudo/ergo/EspelhoErgo';
import { signOutPac } from '../store/modules/auth/patient/actions';
import { signOutMed } from '../store/modules/auth/doctor/actions';
import Index from '../pages/Index';
import ReportMapa from '../pages/laudo/mapa/ReportMapa';
import ReportHolter from '../pages/laudo/holter/ReportHolter';
import EspelhoMapa from '../pages/laudo/mapa/EspelhoMapa';
import EspelhoHolter from '../pages/laudo/holter/EspelhoHolter';
import ReportEcg from '../pages/laudo/ecg/ReportEcg';
import NotAllowed from '../components/NotAllowed';
import FormFechamento from '../pages/laudo/atividade/FormFechamento';
import FormAgendaDiaria from '../pages/laudo/atividade/FormAgendaDiaria';
import ValidaAtestado from '../pages/laudo/receita/ValidaAtestado';
import ValidaRequisicao from '../pages/laudo/receita/ValidaRequisicao';
import ValidaReceita from '../pages/laudo/receita/ValidaReceita';
import ImagemCarotida from '../pages/laudo/carotida/ImagemCarotida';
import Agendamento from '../pages/patient/Agendamento';
import PublicEcg from '../pages/laudo/ecg/PublicEcg';
import PublicCongTXT from '../pages/laudo/eco/PublicCongTXT';
import PublicCongenito from '../pages/laudo/eco/PublicCongenito';
import PublicDoppler from '../pages/laudo/eco/PublicDoppler';
import PublicErgo from '../pages/laudo/ergo/PublicErgo';
import PublicTrans from '../pages/laudo/trans/PublicTrans';
import PublicStress from '../pages/laudo/stress/PublicStress';
import PublicCarotida from '../pages/laudo/carotida/PublicCarotida';
import PublicFetal from '../pages/laudo/fetal/PublicFetal';
import ShortUrl from '../components/ShortUrl';
import TelegramBot from '../components/TelegramBot';

export default function Routes() {
  const dispatch = useDispatch();
  const signed = useSelector(stateInitial => stateInitial.patient.signed);
  const { logged } = useSelector(stateInitial => stateInitial.doctor);

  function onIdle() {
    if (logged) {
      toast.success('Desconectando Médico!');
      dispatch(signOutMed());
    }

    if (signed) {
      toast.success('Desconectando Paciente!');
      dispatch(signOutPac());
    }
  }

  return (
    <>
      <IdleTimer
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={1000 * 60 * 60}
      />
      <RouterDom path="/notAllowed" component={NotAllowed} />
      <Switch>
        <RouterDom path="/telegram" exact component={TelegramBot} />
        <RouterDom path="/url/:token" exact component={ShortUrl} />
        <RouterDom path="/laudo/ecg/:token" exact component={PublicEcg} />
        <RouterDom
          path="/laudo/congenitotxt/:token"
          component={PublicCongTXT}
        />
        <RouterDom path="/laudo/congenito/:token" component={PublicCongenito} />
        <RouterDom path="/laudo/doppler/:token" component={PublicDoppler} />
        <RouterDom path="/laudo/ergo/:token" component={PublicErgo} />
        <RouterDom path="/laudo/trans/:token" component={PublicTrans} />
        <RouterDom path="/laudo/stress/:token" component={PublicStress} />
        <RouterDom path="/laudo/carotida/:token" component={PublicCarotida} />
        <RouterDom path="/laudo/fetal/:token" component={PublicFetal} />
      </Switch>
      <Switch>
        <Route path="/" exact component={Index} />
        <Route path="/agendamento" component={Agendamento} />
        <Route path="/mlogin" component={SignInMed} />
        <Route path="/externo" component={SignInMedExterno} />
        <Route path="/plogin" component={SignInPac} />
        <Route path="/flogin" component={SignInFunc} />
        <Route path="/perfilpaciente" component={PerfilPaciente} />
        <Route path="/perfilmedico" component={PerfilMedico} />
        <Route path="/receita/validacao" component={ValidaReceita} />
        <Route path="/atestado/validacao" component={ValidaAtestado} />
        <Route path="/requisicao/validacao" component={ValidaRequisicao} />
        <Route path="/tokenizer" component={Tokenizer} isPrivate />
        <Route path="/agenda" component={Agenda} isPrivate />
        <Route path="/frmatv" component={Atividade} isPrivate />
        <Route path="/frmmedico" component={FormMedico} isPrivate />
        <Route path="/frmhome" component={Home} isPrivate />
        <Route
          path="/reportProntuario"
          component={ReportProntuario}
          isPrivate
        />
        <Route path="/formProntuario" component={FormProntuario} isPrivate />
        <Route path="/reportMapa" component={ReportMapa} isPrivate />
        <Route path="/espelhoMapa" component={EspelhoMapa} isPrivate />
        <Route path="/reportHolter" component={ReportHolter} isPrivate />
        <Route path="/espelhoHolter" component={EspelhoHolter} isPrivate />
        <Route path="/formDoppler" component={FormDoppler} isPrivate />
        <Route path="/reportDoppler" component={ReportDoppler} isPrivate />
        <Route path="/espelhoDoppler" component={EspelhoDoppler} isPrivate />
        <Route path="/espelhoCong" component={EspelhoCong} isPrivate />
        <Route path="/formCongenito" component={FormCongenito} isPrivate />
        <Route path="/reportEcoCongenito" component={ReportCong} isPrivate />
        <Route
          path="/reportEcoCongenitoTXT"
          component={ReportCongTXT}
          isPrivate
        />
        <Route path="/formEcg" component={FormEcg} isPrivate />
        <Route path="/reportEcg" component={ReportEcg} isPrivate />
        <Route path="/reportTrans" component={ReportTrans} isPrivate />
        <Route path="/espelhoTrans" component={EspelhoTrans} isPrivate />
        <Route path="/formTrans" component={FormTrans} isPrivate />
        <Route path="/reportStress" component={ReportStress} isPrivate />
        <Route path="/espelhoFisio" component={EspelhoFisio} isPrivate />
        <Route path="/espelhoFarmaco" component={EspelhoFarmaco} isPrivate />
        <Route path="/formStress" component={FormStress} isPrivate />
        <Route path="/formCarotida" component={FormCarotida} isPrivate />
        <Route path="/reportCarotida" component={ReportCarotida} isPrivate />
        <Route path="/espelhoCarotida" component={EspelhoCarotida} isPrivate />
        <Route path="/imagemCarotida" component={ImagemCarotida} isPrivate />
        <Route path="/reportFetal" component={ReportFetal} isPrivate />
        <Route path="/espelhoFetal" component={EspelhoFetal} isPrivate />
        <Route path="/formFetal" component={FormFetal} isPrivate />
        <Route path="/reportErgo" component={ReportErgo} isPrivate />
        <Route path="/espelhoErgo" component={EspelhoErgo} isPrivate />
        <Route path="/panelpac" component={PanelPaciente} isPrivate />
        <Route path="/dashboardpac" component={DashboardPac} isPrivate />
        <Route path="/agendapac" component={AgendaPaciente} isPrivate />
        <Route path="/frmatvpac" component={Laudos} isPrivate />
        <Route path="/formAvaliacao" component={FormAvaliacao} isPrivate />
        <Route path="/reportAvaliacao" component={ReportAvaliacao} isPrivate />
        <Route path="/fechamento" component={FormFechamento} isPrivate />
        <Route path="/agendadiaria" component={FormAgendaDiaria} isPrivate />
      </Switch>
    </>
  );
}
