import { toast } from 'react-toastify';
import api from './api';

const updateRequisicao = async payload => {
  try {
    const response = await api.put(
      `/requisicao/${payload.codRequisicao}`,
      payload
    );
    return response;
  } catch (error) {
    toast.error(error.response.data.error);
  }
};

const updateAtestado = async payload => {
  try {
    const response = await api.put(`/atestado/${payload.codAtestado}`, payload);
    return response;
  } catch (error) {
    toast.error(error.response.data.error);
  }
};

const updateReceita = async payload => {
  try {
    const response = await api.put(`/receita/${payload.codReceita}`, payload);
    return response;
  } catch (error) {
    toast.error(error.response.data.error);
  }
};

const liberarLaudoById = async payload => {
  try {
    const { fatura } = payload;
    const result = await api.put(
      `/liberar/atividade/${fatura.codFatura}`,
      fatura
    );

    return result.data;
  } catch (error) {
    toast.error(error.response.data.error);
  }
};

const liberarLaudoByPac = async payload => {
  try {
    const { fatura } = payload;
    const response = await api.put(`/atividade/liberacao/`, fatura);
    return response.data.message;
  } catch (error) {
    toast.error(error.response.data.error);
  }
};

const insertReceita = async payload => {
  try {
    const response = await api.post(`receita/`, payload);
    return response.data;
  } catch (error) {
    toast.error(error.response.data.error);
  }
};

export {
  updateRequisicao,
  updateAtestado,
  updateReceita,
  liberarLaudoById,
  liberarLaudoByPac,
  insertReceita,
};
