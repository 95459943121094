import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Col, Row, Button, Card, Alert, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { selectFetalById } from '../../../redux/features/laudo/fetalSlice';
import {
  loadingOpen,
  loadingClose,
  printOpen,
  printClose,
} from '../../../redux/features/context/contextSlice';
import Loading from '../../../pages/Index/Loading';
import api from '../../../services/api';
import s3 from '../../../util/s3';
import { dblaudo } from '../../../util/laudo';
import history from '../../../services/history';
import { liberarLaudoByPac } from '../../../services/prontuario';

const updateFetalById = async payload => {
  try {
    const { CODFETAL } = payload;
    let fetal = '';
    const result = await api.put(`/fetal/${CODFETAL}`, payload);
    if (result.statusText === 'OK') {
      fetal = result.data;
    }
    return fetal;
  } catch (error) {
    toast.error(`ERRO na atualização de um ECO-FETAL.`);
  }
};

export default function FormFetal() {
  const dispatch = useDispatch();
  const { fetal } = useSelector(state => state.fetal);
  const { loading } = useSelector(state => state.contexto);
  const medico = useSelector(state => state.doctor.profile);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [urls, setURLs] = useState([]);
  const [files, setFiles] = useState([]);
  const [showUpload, setShowUpload] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [loadingUploadFile, setLoadingUploadFile] = useState(false);
  const [fetalLaudo, setFetalLaudo] = useState({});

  useEffect(() => {
    if (medico?.tipo === 'Externo') history.push('/notAllowed');
  }, [medico]);

  useEffect(() => {
    if (fetal) {
      setFetalLaudo({
        ...fetal,
        CODFETAL: fetal?.CODFETAL || '',
        TIPOFETAL: fetal?.TIPOFETAL || '',
        DESCRICAO: fetal?.DESCRICAO || '',
        CODFUNC: medico?.idFunc || fetal?.CODFUNC,
      });
    }
  }, [fetal, medico]);

  useEffect(() => {
    s3.listObjectsV2(
      { Bucket: 'prevencor', Prefix: `fetal/${fetal?.CODFETAL}` },
      (err, data) => {
        if (err) toast.error('Erro ao buscar arquivos. Entre em contato.');
        else
          setFiles(
            data.Contents.filter(
              item =>
                item.Key.includes('.jpeg') ||
                item.Key.includes('.pdf') ||
                item.Key.includes('.mp4')
            )
          );
      }
    );
  }, [fetal]);

  useEffect(() => {
    async function getFiles(f) {
      f = f.Key.split('/');

      return await api.get(
        `https://j87jesh814.execute-api.us-east-1.amazonaws.com/dev/image-upload/folder/fetal/laudo/${fetal?.CODFETAL}/key/${f[2]}`
      );
    }

    async function showFiles() {
      const array = [];

      for (let file of files) {
        const url = await getFiles(file);
        array.push(url);
      }

      setURLs(array);
    }

    showFiles();
  }, [fetal, files]);

  const handleChangeDESCRICAO = event => {
    setFetalLaudo({ ...fetalLaudo, DESCRICAO: event.target.value });
  };

  const handleTipoFetal = tipo => {
    let descricao = '';
    switch (tipo) {
      case 'normal':
        descricao = dblaudo.fetal[0].normal;
        setFetalLaudo({ ...fetalLaudo, DESCRICAO: descricao, TIPOFETAL: tipo });
        document.getElementById('editDescricao').focus();
        break;
      case 'alterado':
        descricao = dblaudo.fetal[1].alterado;
        setFetalLaudo({ ...fetalLaudo, DESCRICAO: descricao, TIPOFETAL: tipo });
        document.getElementById('editDescricao').focus();
        break;
      default:
    }
  };

  const handleGravar = async event => {
    event.preventDefault();
    setShow(false);
    dispatch(loadingOpen());
    const fatura = { codLaudo: fetalLaudo.CODFETAL, codPac: fetalLaudo.CODPAC };
    await updateFetalById(fetalLaudo);
    if (medico) {
      try {
        const response = await liberarLaudoByPac({ fatura });
        setShow(true);
        setMensagem(response);
      } catch (error) {
        setMensagem('Erro ao liberar laudo!');
      }
    }
    await dispatch(selectFetalById(fetalLaudo.CODFETAL));
    dispatch(loadingClose());
  };

  async function handleUploadFile(file) {
    if (!file) return toast.warn('Selecione um arquivo!');

    const encodedFilename = encodeURIComponent(file.name);

    try {
      const { data } = await api.put(
        `https://j87jesh814.execute-api.us-east-1.amazonaws.com/dev/image-upload/folder/fetal/laudo/${fetalLaudo?.CODFETAL}/key/${encodedFilename}`
      );

      await fetch(data.preSignedUrl, { method: 'PUT', body: file });
    } catch (error) {
      toast.error('Erro ao fazer upload do arquivo!');
    }
  }

  async function confirmUpload() {
    for (const file of files) {
      await handleUploadFile(file);
    }

    setLoadingUploadFile(true);
  }

  function closeFileModal() {
    setShowUpload(false);
    setLoadingUploadFile(false);
    setFiles([]);
  }

  return (
    <>
      <Card style={{ marginTop: 10 }}>
        <Card.Header className="text-center font-weight-bold">
          LAUDO DE ECO FETAL
        </Card.Header>

        <Card.Body>
          <Form noValidate>
            <strong className="mr-auto">Nome do Paciente: </strong>
            {`${fetalLaudo.CODPAC} - ${fetalLaudo.NOMEPAC}`}
            <br></br>
            <strong className="mr-auto">Data de Nasc: </strong>
            {`${fetalLaudo.dataNasc}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Sexo: </strong>
            {`${fetalLaudo.SEXOPAC}`}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Idade: </strong>
            {`${fetalLaudo.idadePAC} anos`}
            <br></br>
            <strong className="mr-auto">Convênio: </strong>
            {fetalLaudo.SIGLACONV}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Cod FETAL: </strong>
            {fetalLaudo.CODFETAL}&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Data do Exame: </strong>
            {fetalLaudo.dataFetal}
            <br></br>
            <strong className="mr-auto">Médico que Realizou: Dr. </strong>
            {`${fetalLaudo.Realizou}`}
            <br></br>
            <strong className="mr-auto">Médico que Solicitou: Dr. </strong>
            {`${fetalLaudo.Solicitou}`}
            <hr></hr>{' '}
            <Card>
              <div key={`radio`} className="m-3" style={{ fontSize: 12 }}>
                <Row xs={12} sm={6} md={2} lg={2}>
                  <Col sm={6} style={{ align: 'left' }}>
                    <strong>ECO FETAL</strong>
                    <Form.Check
                      label="Fetal Regular NORMAL"
                      name="groupFetal"
                      type="radio"
                      id="normal"
                      onClick={event => handleTipoFetal(event.target.id)}
                    />
                    <Form.Check
                      label="Fetal Regular ALTERADO"
                      name="groupFetal"
                      type="radio"
                      id="alterado"
                      onClick={event => handleTipoFetal(event.target.id)}
                    />
                  </Col>
                </Row>
              </div>
            </Card>
            &nbsp;
            {show && <Alert variant="success">{mensagem}</Alert>}
            <Form.Row>
              <Col sm>
                <Form.Group size="sm" controlId="editDescricao">
                  <Form.Label>
                    <strong>DESCRIÇÃO</strong>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={fetalLaudo?.DESCRICAO?.split('\n').length + 3 || 2}
                    name="DESCRICAO"
                    value={fetalLaudo.DESCRICAO ? fetalLaudo.DESCRICAO : ''}
                    onChange={handleChangeDESCRICAO}
                    onKeyDown={e => {
                      if (e.key === 'F2') {
                        const posStart = fetalLaudo.DESCRICAO.indexOf('---');
                        const memoDESCRICAO = document.getElementById(
                          'editDescricao'
                        );
                        memoDESCRICAO.setSelectionRange(posStart, posStart + 3);
                      }
                    }}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Card className="mt-3 text-center">
              <Card.Footer className="text-muted">
                <Button variant="success" onClick={handleGravar}>
                  Gravar
                </Button>{' '}
                <Button
                  variant="secondary"
                  onClick={() => {
                    dispatch(printClose());
                    history.push('/reportFetal');
                  }}
                >
                  Imprimir
                </Button>{' '}
                <Button
                  variant="info"
                  onClick={() => {
                    dispatch(printOpen());
                    history.push('/reportFetal');
                  }}
                >
                  Timbrado
                </Button>{' '}
                <Button variant="warning" onClick={() => setShowUpload(true)}>
                  Anexar
                </Button>{' '}
                <Button
                  variant="danger"
                  onClick={() => history.push('/agenda')}
                >
                  Fechar
                </Button>
              </Card.Footer>
            </Card>
          </Form>
        </Card.Body>
      </Card>

      {loading && <Loading />}

      <Modal show={showUpload} onHide={closeFileModal}>
        <Modal.Header closeButton>
          <Modal.Title>Anexar arquivo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="laudos">Selecione pelo menos 1 arquivo</label>
          <br />
          <input
            type="file"
            style={{ color: 'white' }}
            multiple
            onChange={e => setFiles(Array.from(e.target.files))}
          />
          <ul>{files && files.map(file => <li>{file.name}</li>)}</ul>
          <div>
            <br />
            {loadingUploadFile && (
              <text style={{ color: 'green', marginRight: '100px' }}>
                Upload finalizado com sucesso!
              </text>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={confirmUpload}>Confirmar</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Visualização</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {urls.map(url => {
            return url.data.preSignedUrl.includes('.webm') ||
              url.data.preSignedUrl.includes('.mp4') ? (
              <div style={{ textAlign: 'center', display: 'block' }}>
                <video id="video-laudo" width="500" height="500" controls>
                  <source
                    src={url.data.preSignedUrl}
                    type={
                      url.data.preSignedUrl.endsWith('.mp4')
                        ? 'video/mp4'
                        : 'video/webm'
                    }
                  />
                </video>
              </div>
            ) : (
              <div style={{ textAlign: 'center', display: 'block' }}>
                <embed
                  src={url.data.preSignedUrl}
                  width="600px"
                  height="900px"
                />
              </div>
            );
          })}
        </Modal.Body>
      </Modal>
    </>
  );
}
