import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Button, Card } from 'react-bootstrap';
import styles from '../../../styles/styles.module.css';
import espfetal from '../../../assets/esp-fetal.png';
import getPrefix from '../../../util/getPrefix';

export default function EspelhoFetal() {
  const { fetal } = useSelector(state => state.fetal);

  return (
    <Card>
      <Card.Header className="text-center">
        <Card.Title className={styles.titleReport}>
          LAUDO DE ECO FETAL
        </Card.Title>
      </Card.Header>

      <Card.Body>
        <Form noValidate className={styles.contentReport}>
          <strong className="mr-auto">Código: </strong>
          {fetal.CODFETAL}&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Nome do Paciente: </strong>
          {`${fetal.CODPAC} - ${fetal.NOMEPAC}`}
          <br></br>
          <strong className="mr-auto">Data de Nasc: </strong>
          {`${fetal.dataNasc}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Sexo: </strong>
          {`${fetal.SEXOPAC}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Idade: </strong>
          {`${fetal.idadePAC} anos`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">SC: </strong>
          <br></br>
          <strong className="mr-auto">Convênio: </strong>
          {fetal.SIGLACONV}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Data do Laudo: </strong>
          {fetal.dataFetal}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Fone: </strong>
          {fetal.CELULARPAC}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <br></br>
          <strong className="mr-auto">
            Médico que Realizou: {getPrefix(fetal?.CODREALIZOU)}{' '}
          </strong>
          {`${fetal.Realizou}`}
          <br></br>
          <strong className="mr-auto">
            Médico que Solicitou: {getPrefix(fetal?.CODSOLICITANTE)}{' '}
          </strong>
          {`${fetal.Solicitou}`}
          <hr></hr>
          <div>
            <img
              width="85%"
              height="50%"
              loading="lazy"
              src={espfetal}
              alt="espelho fetal"
            />{' '}
          </div>
          <div id="removeMe" className="text-right">
            <Button variant="outline-primary" onClick={() => window.print()}>
              Imprimir
            </Button>{' '}
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
}
