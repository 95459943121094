import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import md5 from 'md5';
import { Form, Button, Card, Spinner, Container, Alert } from 'react-bootstrap';
import { addDays, parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import api from '../../../services/api';
import header from '../../../assets/icm-header.jpg';
import styles from '../../../styles/styles.module.css';
import getPrefix from '../../../util/getPrefix';

export default function PublicTrans() {
  const { token } = useParams();
  const [trans, setTrans] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLaudoData = async () => {
      try {
        const response = await api.get(`/report/${token}`);
        const t = response?.data?.laudo.map(transeso => ({
          ...transeso,
          dataTrans: format(
            addDays(parseISO(transeso.DATATRANS), 1),
            'dd/MM/yyyy',
            { locale: pt }
          ),
          dataNasc: format(
            addDays(parseISO(transeso.DTNPAC), 1),
            'dd/MM/yyyy',
            {
              locale: pt,
            }
          ),
          idadePAC: Math.floor(
            (parseISO(transeso.DATATRANS) - parseISO(transeso.DTNPAC)) /
              (1000 * 3600 * 24) /
              365.25
          ),
        }));
        setTrans(t);
      } catch (error) {
        setError(error.response.data.error);
      }
    };

    fetchLaudoData();
  }, [token]);

  function removeDiv(divName) {
    document.getElementById(divName).style.visibility = 'hidden';
    window.scrollTo(0, 0); // Scroll to the top of the page
    window.print();
    document.getElementById(divName).style.visibility = 'visible';
  }

  function handleDescricao() {
    return <div className={styles.preContent}>{trans[0]?.ECOTRANS}</div>;
  }

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  if (!trans) {
    return (
      <Container className="mt-5 text-center">
        <Spinner animation="border" role="status" />
      </Container>
    );
  }

  return (
    <Card>
      <div className={styles.imageReport}>
        <img width="550" height="100" src={header} alt="header" />
      </div>

      <Card.Header>
        <Card.Title className={styles.titleReport}>
          LAUDO DE ECO TRANSESOFÁGICO
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Form noValidate className={styles.contentReport}>
          <strong className="mr-auto">Nome do Paciente: </strong>
          {`${trans[0]?.CODPAC} - ${trans[0]?.NOMEPAC}`}
          <br></br>
          <strong className="mr-auto">Data de Nasc: </strong>
          {`${trans[0]?.dataNasc}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Sexo: </strong>
          {`${trans[0]?.SEXOPAC}`}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Idade: </strong>
          {`${trans[0]?.idadePAC} anos`}
          <br></br>
          <strong className="mr-auto">Convênio: </strong>
          {trans[0]?.SIGLACONV}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Cod Trans: </strong>
          {trans[0]?.CODTRANS}&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Data do Exame: </strong>
          {trans[0]?.dataTrans}
          <br></br>
          <strong className="mr-auto">Médico que Realizou: Dr. </strong>
          {`${trans[0]?.Realizou}`}
          <br></br>
          <strong className="mr-auto">Médico que Solicitou: Dr. </strong>
          {`${trans[0]?.Solicitou}`}
          <hr></hr>
          <Form.Group size="sm">
            <div className="row">
              <div id="ecoTrans" style={{ fontSize: 17 }} className="col-12">
                {handleDescricao()}
              </div>
            </div>
          </Form.Group>
          <Card className="text-center">
            <Card.Body>
              <div className={styles.messageReport}>
                Imagens disponíveis no site:{' '}
                <strong>www.icm.com.br (Resultado de Exames)</strong>
                <br />
                <span>Código de verificação: {trans[0]?.CODPAC}</span>
                <hr></hr>
              </div>
              <div className={styles.signatureReport}>
                <i className="mr-auto">
                  Conferido e assinado eletronicamente por:{' '}
                  <strong className="mr-auto">
                    {getPrefix(trans?.CODREALIZOU)} {trans[0]?.Realizou}
                  </strong>
                </i>
                <br></br>
                <i className="mr-auto">
                  Assinatura Digital: {md5(JSON.stringify(trans[0]?.ECOTRANS))}
                </i>
              </div>
            </Card.Body>
            <Card.Footer id="removeMe" className="mt-3 text-center">
              <Button onClick={() => removeDiv('removeMe')}>Imprimir</Button>{' '}
            </Card.Footer>
          </Card>
        </Form>
      </Card.Body>
    </Card>
  );
}
