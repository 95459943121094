import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import md5 from 'md5';
import { Form, Button, Card, Spinner, Container, Alert } from 'react-bootstrap';
import api from '../../../services/api';
import header from '../../../assets/icm-header.jpg';
import styles from '../../../styles/styles.module.css';
import getPrefix from '../../../util/getPrefix';

export default function PublicCongTXT() {
  const { token } = useParams();
  const [eco, setEco] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEcoData = async () => {
      try {
        const response = await api.get(`/report/${token}`);
        setEco(response.data.laudo);
      } catch (error) {
        setError(error.response.data.error);
      }
    };

    fetchEcoData();
  }, [token]);

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  if (!eco) {
    return (
      <Container className="mt-5 text-center">
        <Spinner animation="border" role="status" />
      </Container>
    );
  }

  function removeDiv(divName) {
    document.getElementById(divName).style.visibility = 'hidden';
    window.scrollTo(0, 0); // Scroll to the top of the page
    window.print();
    document.getElementById(divName).style.visibility = 'visible';
  }

  function handleDescricao() {
    return <div className={styles.preContent}>{eco.OBSERVACOES}</div>;
  }

  return (
    <>
      <div>
        <div className={styles.imageReport}>
          <img width="550" height="100" src={header} alt="header" />
        </div>

        <Card.Header className="text-center">
          <Card.Title className={styles.titleReport}>
            LAUDO DE ECOCARDIOGRAMA CONGÊNITO
          </Card.Title>
        </Card.Header>

        <Card.Body>
          {eco && eco.TIPOLAUDO === 'Alterado' ? (
            <Form noValidate className={styles.contentReport}>
              <strong className="mr-auto">Nome do Paciente: </strong>
              {`${eco.CODPAC} - ${eco.NOMEPAC}`}
              <br></br>
              <strong className="mr-auto">Data de Nasc: </strong>
              {`${eco.dataNascFmt}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong className="mr-auto">Sexo: </strong>
              {`${eco.Sexo}`}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong className="mr-auto">Idade: </strong>
              {`${eco.idadePAC} anos`}
              <br></br>
              <strong className="mr-auto">Convênio: </strong>
              {eco.SIGLACONV}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong className="mr-auto">Cod Eco: </strong>
              {eco.CODECO}&nbsp;&nbsp;&nbsp;
              <strong className="mr-auto">Data do Exame: </strong>
              {eco.dataEcoFmt}
              <br></br>
              <strong className="mr-auto">
                Médico que Realizou: {getPrefix(eco?.CODREALIZOU)}{' '}
              </strong>
              {`${eco.Realizou}`}
              <br></br>
              <strong className="mr-auto">
                Médico que Solicitou: {getPrefix(eco?.CODSOLICITANTE)}{' '}
              </strong>
              {`${eco.Solicitou}`}
              <hr></hr>
              <strong className="mr-auto"></strong>
              <div className="row">
                <div id="ecoObs" style={{ fontSize: 17 }} className="col-12">
                  {handleDescricao()}
                </div>
              </div>
              <Card className="mt-3 text-center">
                <Card.Body>
                  <div className={styles.messageReport}>
                    Imagens disponíveis no site:{' '}
                    <strong>www.icm.com.br (Resultado de Exames)</strong>
                    {/* TEMPORÁRIO */}
                    <br />
                    <span>Código de verificação: {eco?.CODPAC}</span>
                    <hr></hr>
                  </div>
                  <div className={styles.signatureReport}>
                    <i className="mr-auto">
                      Conferido e assinado eletronicamente por:{' '}
                    </i>
                    <strong className="mr-auto">
                      {getPrefix(eco?.CODREALIZOU)} {eco?.Realizou}
                    </strong>
                    <br></br>
                    <i className="mr-auto">
                      Assinatura Digital: {md5(JSON.stringify(eco))}
                    </i>
                  </div>
                </Card.Body>
                <Card.Footer id="removeMe" className="text-muted">
                  <Button onClick={() => removeDiv('removeMe')}>
                    Imprimir
                  </Button>{' '}
                </Card.Footer>
              </Card>
            </Form>
          ) : (
            <Form noValidate className={styles.contentReport}>
              <strong className="mr-auto">Nome do Paciente: </strong>
              {`${eco.CODPAC} - ${eco.NOMEPAC}`}
              <br></br>
              <strong className="mr-auto">Data de Nasc: </strong>
              {`${eco.dataNascFmt}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong className="mr-auto">Sexo: </strong>
              {`${eco.Sexo}`}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong className="mr-auto">Idade: </strong>
              {`${eco.idadePAC} anos`}
              <br></br>
              <strong className="mr-auto">Convênio: </strong>
              {eco.SIGLACONV}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong className="mr-auto">Cod Laudo: </strong>
              {eco.CODECO}&nbsp;&nbsp;&nbsp;
              <strong className="mr-auto">Data do Exame: </strong>
              {eco.dataEcoFmt}
              <br></br>
              <strong className="mr-auto">
                Médico que Realizou: {getPrefix(eco?.CODREALIZOU)}{' '}
              </strong>
              {`${eco.Realizou}`}
              <br></br>
              <strong className="mr-auto">
                Médico que Solicitou: {getPrefix(eco?.CODSOLICITANTE)}{' '}
              </strong>
              {`${eco.Solicitou}`}
              <hr></hr>
              <hr></hr>
              <strong className="mr-auto"></strong>
              <div className="row">
                <div id="ecoObs" style={{ fontSize: 18 }} className="col-12">
                  {handleDescricao()}
                </div>
              </div>
              <Card className="mt-3 text-center">
                <Card.Body>
                  <div className={styles.messageReport}>
                    Imagens disponíveis no site:{' '}
                    <strong>www.icm.com.br (Resultado de Exames)</strong>
                    {/* TEMPORÁRIO */}
                    <br />
                    <span>Código de verificação: {eco?.CODPAC}</span>
                    <hr></hr>
                  </div>
                  <div className={styles.signatureReport}>
                    <i className="mr-auto">
                      Conferido e assinado eletronicamente por:{' '}
                    </i>
                    <strong className="mr-auto">
                      {getPrefix(eco?.CODREALIZOU)} {eco?.Realizou}
                    </strong>
                    <br></br>
                    <i className="mr-auto">
                      Assinatura Digital: {md5(JSON.stringify(eco))}
                    </i>
                  </div>
                </Card.Body>
                <Card.Footer id="removeMe" className="text-muted">
                  <Button onClick={() => removeDiv('removeMe')}>
                    Imprimir
                  </Button>{' '}
                </Card.Footer>
              </Card>
            </Form>
          )}
        </Card.Body>
      </div>
    </>
  );
}
