// TODO: stop using deprecated methods
// replace auth sagas with context api
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import createStore from './createStore';
import persistReducer from './persistReducers';
import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [
  sagaMiddleware,
  ...getDefaultMiddleware({ serializableCheck: false }),
];
const store = createStore(persistReducer(rootReducer), middlewares);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
