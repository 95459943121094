import { toast } from 'react-toastify';
import api from './api';

const updateEcoById = async payload => {
  try {
    const { CODECO } = payload;
    const { data } = await api.put(`/ecocardiograma/${CODECO}`, payload);
    return data;
  } catch (error) {
    toast.error(error.response.data.error);
  }
};

export default updateEcoById;
