import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import md5 from 'md5';
import {
  Form,
  Button,
  Card,
  Spinner,
  Container,
  Alert,
  Col,
} from 'react-bootstrap';
import { addDays, parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import api from '../../../services/api';
import header from '../../../assets/icm-header.jpg';
import styles from '../../../styles/styles.module.css';
import getPrefix from '../../../util/getPrefix';

export default function PublicStress() {
  const { token } = useParams();
  const [stress, setStress] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLaudoData = async () => {
      try {
        const response = await api.get(`/report/${token}`);
        const s = response.data.laudo.map(st => ({
          ...st,
          dataStress: format(
            addDays(parseISO(st.DATASTRESS), 1),
            'dd/MM/yyyy',
            {
              locale: pt,
            }
          ),
          dataNasc: format(addDays(parseISO(st.DTNPAC), 1), 'dd/MM/yyyy', {
            locale: pt,
          }),
          idadePAC: Math.floor(
            (parseISO(st.DATASTRESS) - parseISO(st.DTNPAC)) /
              (1000 * 3600 * 24) /
              365.25
          ),
        }));
        setStress(s);
      } catch (error) {
        setError(error.response.data.error);
      }
    };

    fetchLaudoData();
  }, [token]);

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  if (!stress) {
    return (
      <Container className="mt-5 text-center">
        <Spinner animation="border" role="status" />
      </Container>
    );
  }

  function removeDiv(divName) {
    document.getElementById(divName).style.visibility = 'hidden';
    window.scrollTo(0, 0);
    window.print();
    document.getElementById(divName).style.visibility = 'visible';
  }

  function handleDescricao() {
    return <div className={styles.preContent}>{stress[0]?.ISQUEMIA}</div>;
  }

  return (
    <Card id="cardHeader">
      <div id="printMe">
        <div className={styles.imageReport}>
          <img width="600" height="110" src={header} alt="header" />
        </div>

        <Card.Header className="text-center">
          <Card.Title className={styles.titleReport}>
            LAUDO DE ECO ESTRESSE
          </Card.Title>
        </Card.Header>

        <Card.Body>
          <Form noValidate className={styles.contentReport}>
            <strong className="mr-auto">Nome do Paciente: </strong>
            {`${stress[0]?.CODPAC} - ${stress[0]?.NOMEPAC}`}
            <br></br>
            <strong className="mr-auto">Data de Nasc: </strong>
            {`${stress[0]?.dataNasc}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Sexo: </strong>
            {`${stress[0]?.SEXOPAC}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Idade: </strong>
            {`${stress[0]?.idadePAC} anos`}
            <br></br>
            <strong className="mr-auto">Convênio: </strong>
            {stress[0]?.SIGLACONV}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Código: </strong>
            {stress[0]?.CODSTRESS}&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Data do Stress: </strong>
            {stress[0]?.dataStress}
            <br></br>
            <strong className="mr-auto">
              Médico que Realizou: {getPrefix(stress[0]?.CODREALIZOU)}{' '}
            </strong>
            {`${stress[0]?.Realizou}`}
            <br></br>
            <strong className="mr-auto">
              Médico que Solicitou: {getPrefix(stress[0]?.CODSOLICITANTE)}{' '}
            </strong>
            {`${stress[0]?.Solicitou}`}
            <hr></hr>
            <Form.Row>
              <Col sm>
                <Form.Group size="sm" controlId="editIsquemia">
                  <div className="row">
                    <div
                      id="isquemia"
                      style={{ fontSize: 17 }}
                      className="col-12"
                    >
                      {handleDescricao()}
                    </div>
                  </div>
                </Form.Group>
              </Col>
            </Form.Row>
            <Card className="text-center">
              <Card.Body>
                <div className={styles.messageReport}>
                  Imagens disponíveis no site:{' '}
                  <strong>www.icm.com.br (Resultado de Exames)</strong>
                  {/* TEMPORÁRIO */}
                  <br />
                  <span>Código de verificação: {stress[0]?.CODPAC}</span>
                  <hr></hr>
                </div>
                <div className={styles.signatureReport}>
                  <i className="mr-auto">
                    Conferido e assinado eletronicamente por:{' '}
                    <strong className="mr-auto">
                      {getPrefix(stress?.CODREALIZOU)} {stress[0]?.Realizou}
                    </strong>
                  </i>
                  <br></br>
                  <i className="mr-auto">
                    Assinatura Digital:{' '}
                    {md5(JSON.stringify(stress[0]?.ISQUEMIA) || '')}
                  </i>
                </div>
              </Card.Body>
            </Card>
          </Form>
        </Card.Body>
      </div>
      <Card.Footer id="removeMe" className="mt-3 text-center">
        <Button onClick={() => removeDiv('removeMe')}>Imprimir</Button>{' '}
      </Card.Footer>
    </Card>
  );
}
