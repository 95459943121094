import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { addDays, parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import api from '../../../services/api';

export const sliceHolter = createSlice({
  name: 'holter',
  initialState: {
    loading: false,
    holter: {},
  },
  reducers: {
    holterSuccess: (state, action) => {
      const { holter } = action.payload;
      state.loading = false;
      state.holter = holter;
    },
    holterRequest: (state, action) => {
      const { holter } = action.payload;
      state.loading = true;
      state.holter = holter;
    },
    holterReset: state => {
      state.loading = false;
      state.holter = {};
    },
    holterFailure: state => {
      state.loading = false;
      state.holter = {};
    },
  },
});

/** *************EXPORTED ACTIONS & REDUCERS************** */

export const {
  holterRequest,
  holterSuccess,
  holterFailure,
  holterReset,
} = sliceHolter.actions;

export default sliceHolter.reducer;

// API REQUEST ACTIONS HANDLED WITH REDUX-THUNK MIDDLEWARE BUILT INTO REDUX TOOLKIT -->

/** *************THUNKS************** */
export const selectHolterByID = payload => {
  return async dispatch => {
    try {
      const response = await api.get(`holter?id=${payload}`);
      let c = 0;
      const holter = response.data.map(m => ({
        ...m,
        dataHolter: format(addDays(parseISO(m.DATAGRAV), 1), 'dd/MM/yyyy', {
          locale: pt,
        }),
        dataNasc: format(addDays(parseISO(m.DTNPAC), 1), 'dd/MM/yyyy', {
          locale: pt,
        }),
        idadePAC: Math.floor(
          (parseISO(m.DATAGRAV) - parseISO(m.DTNPAC)) /
            (1000 * 3600 * 24) /
            365.25
        ),
        counter: (c += 1),
      }));

      await dispatch(holterSuccess({ holter }));
      return holter;
    } catch (error) {
      toast.error(`ERRO ao buscar holter.`);
      dispatch(holterFailure());
    }
  };
};
