import { toast } from 'react-toastify';
import { nanoid } from 'nanoid';
import api from '../services/api';
import { cleanPhoneNumber } from '../util/agendaHelpers';
import { liberarLaudoByPac } from '../services/prontuario';
import { selectEcoById } from '../redux/features/laudo/ecoSlice';
import { store } from '../store';

const dblaudo = {
  fisio: [
    {
      normalPositivo: `Paciente submetido a ecocardiografia com ESTRESSE FISIOLÓGICO EM ESTEIRA para investigação de Isquemia Miocárdica.
ECG-BASAL: segmento ST dentro dos limites da normalidade.
TE-ESTRESSE: paciente andou por --- minutos no protocolo Bruce, com VO2 máximo = --- ml/Kg/min e Duplo produto = ---. Atingiu FC = --- bpm (FCmáx = --- e FCsubmáx = ---), PArepouso = --- mmHg e PAmáxima = --- mmHg.
PREDITORES DE MORTALIDADE E EVENTOS CARDIOVASCULARES:
* Recuperação da FC no 1º minuto após o exercício = --- bpm (anormal se a queda for menor ou igual a 18 btms/min).
* Capacidade Funcional = --- METS. Anormal quando menor que --- METS ( 85% da prevista de acordo com a idade e sexo).
* Resposta Cronotrópica = ---% (anormal quando menor que 80%, quando em uso de B-bloq. menor que 62%).
* Duke escore = --- (risco ---).
Comportamento normal da pressão arterial.
Análise eletrocardiográfica apresentando resposta normal ao esforço realizado.
Ausência de arritmias. Paciente apresentou cansaço físico.

ECO-REPOUSO:
- Ventrículo Esquerdo com dimensões, funções sistólica e diastólica normais.
- Contratilidade miocárdica normal em repouso.
- Strain Longitudinal Global do VE: ---% (valor de referência é menor que -18%)
- Índice de volume do átrio esquerdo: --- ml/m² (normal < 34 ml/m²).
- Refluxo Tricúspide. Estimativa da pressão sistólica na artéria pulmonar de --- mmHg.
ESTRESSE MÁXIMO:
- Observado incremento progressivo da contratilidade miocárdica segmentar e da FE, sugerindo boa reserva coronariana e ausência de estenose significativa.
Após o término do estresse, observado em repouso a regressão para os padrões basais.
Índice de Contratilidade Regional do VE: --- (Risco de evento cardiovascular: Baixo < 1,1; Intermediário: 1,1 a 1,7; Alto > 1,7)
Em relação ao último exame realizado em ---, não observamos alterações.

CONCLUSÃO:
1 - Negativo para indução de Isquemia Miocárdica.
2 - Capacidade funcional ---.`,
    },
    {
      normalNegativo: `Paciente submetido a ecocardiografia com ESTRESSE FISIOLÓGICO EM ESTEIRA para investigação de Isquemia Miocárdica.
ECG-BASAL: segmento ST dentro dos limites da normalidade.
TE-ESTRESSE: paciente andou por --- minutos no protocolo Bruce, com VO2 máximo = --- ml/Kg/min e Duplo produto = ---. Atingiu FC = --- bpm (FCmáx = --- e FCsubmáx = ---), PArepouso = --- mmHg e PAmáxima = --- mmHg.
PREDITORES DE MORTALIDADE E EVENTOS CARDIOVASCULARES:
* Recuperação da FC no 1º minuto após o exercício = --- bpm (anormal se a queda for menor ou igual a 18 btms/min).
* Capacidade Funcional = --- METS. Anormal quando menor que --- METS ( 85% da prevista de acordo com a idade e sexo).
* Resposta Cronotrópica = ---% (anormal quando menor que 80%, quando em uso de B-bloq. menor que 62%).
* Duke escore = --- (risco ---).
Comportamento normal da pressão arterial.
Análise eletrocardiográfica apresentando resposta normal ao esforço realizado.
Ausência de arritmias. Paciente apresentou cansaço físico.

ECO-REPOUSO:
- Ventrículo Esquerdo com dimensões, funções sistólica e diastólica normais.
- Contratilidade miocárdica normal em repouso.
- Strain Longitudinal Global do VE: ---% (valor de referência é menor que -18%)
- Índice de volume do átrio esquerdo: --- ml/m² (normal < 34 ml/m²).
- Refluxo Tricúspide. Estimativa da pressão sistólica na artéria pulmonar de --- mmHg.
ESTRESSE MÁXIMO:
- Observado incremento progressivo da contratilidade miocárdica segmentar e da FE, sugerindo boa reserva coronariana e ausência de estenose significativa.
Após o término do estresse, observado em repouso a regressão para os padrões basais.
Índice de Contratilidade Regional do VE: --- (Risco de evento cardiovascular: Baixo < 1,1; Intermediário: 1,1 a 1,7; Alto > 1,7)
Em relação ao último exame realizado em ---, não observamos alterações.

CONCLUSÃO:
1 - Negativo para indução de Isquemia Miocárdica.
2 - Capacidade funcional ---.`,
    },
    {
      alteradoPositivo: `Paciente submetido a ecocardiografia com ESTRESSE FISIOLÓGICO EM ESTEIRA para investigação de Isquemia Miocárdica.
ECG-BASAL: segmento ST dentro dos limites da normalidade.
TE-ESTRESSE: paciente andou por --- minutos no protocolo Bruce, com VO2 máximo = --- ml/Kg/min e Duplo produto = ---. Atingiu FC = --- bpm (FCmáx = --- e FCsubmáx = ---), PArepouso = --- mmHg e PAmáxima = --- mmHg.
PREDITORES DE MORTALIDADE E EVENTOS CARDIOVASCULARES:
* Recuperação da FC no 1º minuto após o exercício = --- bpm (anormal se a queda for menor ou igual a 18 btms/min).
* Capacidade Funcional = --- METS. Anormal quando menor que --- METS ( 85% da prevista de acordo com a idade e sexo).
* Resposta Cronotrópica = ---% (anormal quando menor que 80%, quando em uso de B-bloq. menor que 62%).
* Duke escore = --- (risco ---).
Comportamento normal da pressão arterial.
Análise eletrocardiográfica apresentando resposta normal ao esforço realizado.
Ausência de arritmias. Paciente apresentou cansaço físico.

ECO-REPOUSO:
- Ventrículo esquerdo com função sistólica preservada.
- Disfunção diastólica do ventrículo esquerdo grau I.
- Hipertrofia ventricular esquerda tipo concêntrica de grau discreto.
- Raiz de Aorta (...) e Aorta Ascendente (...) com aumento discreto.
- Valva mitral exibe refluxo de grau discreto.
- Valva tricúspide exibe refluxo de grau discreto.
- Valva aórtica com folhetos espessados, abertura e mobilidades preservadas. Ao Doppler ausência de gradiente transvalvar significativo
- Índice de volume do átrio esquerdo: --- ml/m² (normal < 34 ml/m²).
- Refluxo Tricúspide. Estimativa da pressão sistólica na artéria pulmonar de --- mmHg.
ESTRESSE MÁXIMO:
- Observado incremento progressivo da contratilidade miocárdica segmentar e da FE, sugerindo boa reserva coronariana e ausência de estenose significativa.
Após o término do estresse, observado em repouso a regressão para os padrões basais.
Índice de Contratilidade Regional do VE: --- (Risco de evento cardiovascular: Baixo < 1,1; Intermediário: 1,1 a 1,7; Alto > 1,7)
Em relação ao último exame realizado em ---, não observamos alterações.

OBSERVAÇÃO:
Paciente submetido a exame de ecocardiografia com estresse fisiológico em uso de medicação para controle de freqüência cardíaca (...), fato que pode interferir na acurácia do exame em predizer Isquemia Miocárdica.
CONCLUSÃO:
1 - Negativo para indução de Isquemia Miocárdica.
2 - Capacidade funcional ---.`,
    },
    {
      alteradoNegativo: `Paciente submetido a ecocardiografia com ESTRESSE FISIOLÓGICO EM ESTEIRA para investigação de Isquemia Miocárdica.
ECG-BASAL: segmento ST dentro dos limites da normalidade.
TE-ESTRESSE: paciente andou por --- minutos no protocolo Bruce, com VO2 máximo = --- ml/Kg/min e Duplo produto = ---. Atingiu FC = --- bpm (FCmáx = --- e FCsubmáx = ---), PArepouso = --- mmHg e PAmáxima = --- mmHg.
PREDITORES DE MORTALIDADE E EVENTOS CARDIOVASCULARES:
* Recuperação da FC no 1º minuto após o exercício = --- bpm (anormal se a queda for menor ou igual a 18 btms/min).
* Capacidade Funcional = --- METS. Anormal quando menor que --- METS ( 85% da prevista de acordo com a idade e sexo).
* Resposta Cronotrópica = ---% (anormal quando menor que 80%, quando em uso de B-bloq. menor que 62%).
* Duke escore = --- (risco ---).
Comportamento normal da pressão arterial.
Análise eletrocardiográfica apresentando resposta normal ao esforço realizado.
Ausência de arritmias. Paciente apresentou cansaço físico.

ECO-REPOUSO:
- Ventrículo esquerdo com função sistólica preservada.
- Disfunção diastólica do ventrículo esquerdo grau I.
- Hipertrofia ventricular esquerda tipo concêntrica de grau discreto.
- Raiz de Aorta (...) e Aorta Ascendente (...) com aumento discreto.
- Valva mitral exibe refluxo de grau discreto.
- Valva tricúspide exibe refluxo de grau discreto.
- Valva aórtica com folhetos espessados, abertura e mobilidades preservadas. Ao Doppler ausência de gradiente transvalvar significativo
- Índice de volume do átrio esquerdo: --- ml/m² (normal < 34 ml/m²).
- Refluxo Tricúspide. Estimativa da pressão sistólica na artéria pulmonar de --- mmHg.
ESTRESSE MÁXIMO:
- Observado incremento progressivo da contratilidade miocárdica segmentar e da FE, sugerindo boa reserva coronariana e ausência de estenose significativa.
Após o término do estresse, observado em repouso a regressão para os padrões basais.
Índice de Contratilidade Regional do VE: --- (Risco de evento cardiovascular: Baixo < 1,1; Intermediário: 1,1 a 1,7; Alto > 1,7)
Em relação ao último exame realizado em ---, não observamos alterações.

OBSERVAÇÃO:
Paciente submetido a exame de ecocardiografia com estresse fisiológico em uso de medicação para controle de freqüência cardíaca (...), fato que pode interferir na acurácia do exame em predizer Isquemia Miocárdica.
CONCLUSÃO:
1 - Negativo para indução de Isquemia Miocárdica.
2 - Capacidade funcional ---.
`,
    },
  ],
  dobuta: [
    {
      ivPositivo: `Paciente submetido a ecocardiografia com ESTRESSE FARMACOLÓGICO (DOBUTAMINA) para investigação de Isquemia e Viabilidade Miocárdica nas doses de 5, 10, 20, 30 e 40 mcg/Kg, esforço isométrico e atropina 0,5 mg.
Atingiu FC de --- bpm (FCmáx = --- e FCsubmax = ---).
PArepouso = --- mmHg e PAmáxima = --- mmHg.
Ausência de arritmias.
Paciente não referiu sintomatologia significativa.
ECG-BASAL: dentro dos limites da normalidade.
ECG-ESTRESSE: comportamento normal do segmento ST.

ECO-BASAL:
- Ventrículo Esquerdo com dimensões, funções sistólica e diastólica normais.
- Contratilidade Miocárdica segmentar com acinesia---; movimento de hipocinesia---.
- Índice de volume do átrio esquerdo: --- ml/m² (normal < 34ml/m²).
- Refluxo Tricúspide. Estimativa da pressão sistólica na artéria pulmonar de --- mmHg.
DOBUTA DOSE BAIXA:
- Observado melhora da contratilidade miocárdica ---.
ESTRESSE MÁXIMO:
- Observado ---.
Após o término do estresse, observado em repouso a regressão para os padrões basais.
Índice de Contratilidade Regional do VE: --- (Risco de evento cardiovascular: Baixo < 1,1; Intermediário: 1,1 a 1,7; Alto > 1,7)
Em relação ao último exame realizado em ---, não observamos alterações.

CONCLUSÃO:
1 - Ausência de Viabilidade Miocárdica ---.
2 - Negativo para indução de Isquemia Miocárdica.`,
    },
    {
      ivNegativo: `Paciente submetido a ecocardiografia com ESTRESSE FARMACOLÓGICO (DOBUTAMINA) para investigação de Isquemia e Viabilidade Miocárdica nas doses de 5, 10, 20, 30 e 40 mcg/Kg, esforço isométrico e atropina 0,5 mg.
Atingiu FC de --- bpm (FCmáx = --- e FCsubmax = ---).
PArepouso = --- mmHg e PAmáxima = --- mmHg.
Ausência de arritmias.
Paciente não referiu sintomatologia significativa.
ECG-BASAL: dentro dos limites da normalidade.
ECG-ESTRESSE: comportamento normal do segmento ST.

ECO-BASAL:
- Ventrículo Esquerdo com dimensões, funções sistólica e diastólica normais.
- Contratilidade Miocárdica segmentar com acinesia---; movimento de hipocinesia---.
- Índice de volume do átrio esquerdo: --- ml/m² (normal < 34ml/m²).
- Refluxo Tricúspide. Estimativa da pressão sistólica na artéria pulmonar de --- mmHg.
DOBUTA DOSE BAIXA:
- Observado melhora da contratilidade miocárdica ---.
ESTRESSE MÁXIMO:
- Observado ---.
Após o término do estresse, observado em repouso a regressão para os padrões basais.
Índice de Contratilidade Regional do VE: --- (Risco de evento cardiovascular: Baixo < 1,1; Intermediário: 1,1 a 1,7; Alto > 1,7)
Em relação ao último exame realizado em ---, não observamos alterações.

CONCLUSÃO:
1 - Ausência de Viabilidade Miocárdica ---.
2 - Negativo para indução de Isquemia Miocárdica.
`,
    },
    {
      imPositivo: `Paciente submetido a ecocardiografia com ESTRESSE FARMACOLÓGICO (DOBUTAMINA) para investigação de Isquemia Miocárdica nas doses de 10, 20, 30 e 40 mcg/Kg, esforço isométrico e atropina 0,5 mg.
Atingiu FC de --- bpm (FCmáx = --- e FCsubmax = ---).
PArepouso = --- mmHg e PAmáxima = --- mmHg.
Ausência de arritmias.
Paciente não referiu sintomatologia significativa.
ECG-BASAL: dentro dos limites da normalidade.
ECG-ESTRESSE: comportamento normal do segmento ST.

ECO-BASAL:
- Ventrículo Esquerdo com dimensões, funções sistólica e diastólica normais.
- Contratilidade Miocárdica segmentar normal em repouso.
- Índice de volume do átrio esquerdo: --- ml/m² (normal < 34 ml/m²).
- Refluxo Tricúspide. Estimativa da pressão sistólica na artéria pulmonar de --- mmHg.
ESTRESSE MÁXIMO:
- Observado incremento progressivo da contratilidade miocárdica segmentar e da FE em cada fase, sugerindo boa reserva coronariana e ausência de estenose significativa.
Após o término do estresse, observado em repouso a regressão para os padrões basais.
Índice de Contratilidade Regional do VE: --- (Risco de evento cardiovascular: Baixo < 1,1; Intermediário: 1,1 a 1,7; Alto > 1,7)
Em relação ao último exame realizado em ---, não observamos alterações.

CONCLUSÃO:
1 - Negativo para indução de Isquemia Miocárdica.

`,
    },
    {
      imNegativo: `Paciente submetido a ecocardiografia com ESTRESSE FARMACOLÓGICO (DOBUTAMINA) para investigação de Isquemia Miocárdica nas doses de 10, 20, 30 e 40 mcg/Kg, esforço isométrico e atropina 0,5 mg.
Atingiu FC de --- bpm (FCmáx = --- e FCsubmax = ---).
PArepouso = --- mmHg e PAmáxima = --- mmHg.
Ausência de arritmias.
Paciente não referiu sintomatologia significativa.
ECG-BASAL: dentro dos limites da normalidade.
ECG-ESTRESSE: comportamento normal do segmento ST.

ECO-BASAL:
- Ventrículo Esquerdo com dimensões, funções sistólica e diastólica normais.
- Contratilidade Miocárdica segmentar normal em repouso.
- Índice de volume do átrio esquerdo: --- ml/m² (normal < 34 ml/m²).
- Refluxo Tricúspide. Estimativa da pressão sistólica na artéria pulmonar de --- mmHg.
ESTRESSE MÁXIMO:
- Observado incremento progressivo da contratilidade miocárdica segmentar e da FE em cada fase, sugerindo boa reserva coronariana e ausência de estenose significativa.
Após o término do estresse, observado em repouso a regressão para os padrões basais.
Índice de Contratilidade Regional do VE: --- (Risco de evento cardiovascular: Baixo < 1,1; Intermediário: 1,1 a 1,7; Alto > 1,7)
Em relação ao último exame realizado em ---, não observamos alterações.

CONCLUSÃO:
1 - Negativo para indução de Isquemia Miocárdica.

`,
    },
    {
      imaPositivo: `Paciente submetido a ecocardiografia com ESTRESSE FARMACOLÓGICO (DOBUTAMINA) para investigação de Isquemia Miocárdica nas doses de 10, 20, 30 e 40 mcg/Kg, esforço isométrico e atropina 0,5 mg.
Atingiu FC de --- bpm (FCmáx = --- e FCsubmax = ---).
PArepouso = --- mmHg e PAmáxima = --- mmHg.
Ausência de arritmias.
Paciente não referiu sintomatologia significativa.
ECO-BASAL:
- Ventrículo esquerdo com função sistólica preservada.
- Disfunção diastólica do ventrículo esquerdo grau I.
- Hipertrofia ventricular esquerda tipo concêntrica de grau discreto.
- Raiz de Aorta (...) e Aorta Ascendente (...) com aumento discreto.
- Valva mitral exibe refluxo de grau discreto.
- Valva tricúspide exibe refluxo de grau discreto.
- Valva aórtica com folhetos espessados, abertura e mobilidades preservadas. Ao Doppler ausência de gradiente transvalvar significativo.
- Índice de volume do átrio esquerdo:--- ml/m² (normal < 34ml/m²).
- Refluxo Tricúspide. Estimativa da pressão sistólica na artéria pulmonar de --- mmHg.
ESTRESSE MÁXIMO:
- Observado incremento progressivo da contratilidade miocárdica segmentar e da FE em cada fase, sugerindo boa reserva coronariana e ausência de estenose significativa.
Após o término do estresse, observado em repouso a regressão para os padrões basais.
Índice de Contratilidade Regional do VE: --- (Risco de evento cardiovascular: Baixo < 1,1; Intermediário: 1,1 a 1,7; Alto > 1,7)
Em relação ao último exame realizado em ---, não observamos alterações.

OBSERVAÇÃO:
- Paciente submetido a exame de Ecocardiografia com Estresse Farmacológico em uso de medicação para controle de freqüência cardíaca (.....), fato que pode interferir na acurácia do exame em predizer Isquemia Miocárdica.
CONCLUSÃO:
1 - Negativo para indução de Isquemia Miocárdica.

`,
    },
    {
      imaNegativo: `Paciente submetido a ecocardiografia com ESTRESSE FARMACOLÓGICO (DOBUTAMINA) para investigação de Isquemia Miocárdica nas doses de 10, 20, 30 e 40 mcg/Kg, esforço isométrico e atropina 0,5 mg.
Atingiu FC de --- bpm (FCmáx = --- e FCsubmax = ---).
PArepouso = --- mmHg e PAmáxima = --- mmHg.
Ausência de arritmias.
Paciente não referiu sintomatologia significativa.
ECO-BASAL:
- Ventrículo esquerdo com função sistólica preservada.
- Disfunção diastólica do ventrículo esquerdo grau I.
- Hipertrofia ventricular esquerda tipo concêntrica de grau discreto.
- Raiz de Aorta (...) e Aorta Ascendente (...) com aumento discreto.
- Valva mitral exibe refluxo de grau discreto.
- Valva tricúspide exibe refluxo de grau discreto.
- Valva aórtica com folhetos espessados, abertura e mobilidades preservadas. Ao Doppler ausência de gradiente transvalvar significativo.
- Índice de volume do átrio esquerdo:--- ml/m² (normal < 34ml/m²).
- Refluxo Tricúspide. Estimativa da pressão sistólica na artéria pulmonar de --- mmHg.
ESTRESSE MÁXIMO:
- Observado incremento progressivo da contratilidade miocárdica segmentar e da FE em cada fase, sugerindo boa reserva coronariana e ausência de estenose significativa.
Após o término do estresse, observado em repouso a regressão para os padrões basais.
Índice de Contratilidade Regional do VE: --- (Risco de evento cardiovascular: Baixo < 1,1; Intermediário: 1,1 a 1,7; Alto > 1,7)
Em relação ao último exame realizado em ---, não observamos alterações.

OBSERVAÇÃO:
- Paciente submetido a exame de Ecocardiografia com Estresse Farmacológico em uso de medicação para controle de freqüência cardíaca (.....), fato que pode interferir na acurácia do exame em predizer Isquemia Miocárdica.
CONCLUSÃO:
1 - Negativo para indução de Isquemia Miocárdica.

`,
    },
  ],
  trans: [
    {
      normal: `1-Tolerância ao exame:
         Após sedação tópica com lidocaina-spray a 10% e endovenosa com dormonid, a sonda multiplanar foi introduzida sem dificuldades e o exame foi bem tolerado.

2-Cavidades Cardíacas:
         Átrio direito com dimensões normais.
         Ventrículo direito com dimensões, espessura e funções normais.
         Átrio esquerdo com dimensões normais.
         Ventrículo esquerdo com dimensões, espessura e funções normais.
         Ausência de massas, trombos ou contraste espontâneo intracavitário.

3-Valvas Cardíacas:
         Valva mitral com abertura, mobilidade e espessura normais.
         Valva aórtica com abertura, mobilidade e espessura normais.
         Valva tricúspide com abertura, mobilidade e espessura normais.
         Valva pulmonar com abertura, mobilidade e espessura normais.
         Ausência de vegetações.
         Ao estudo doppler com mapeamento de fluxo a cores, fluxos normais.

4-Septos e veias:
         Septo interatrial íntegro sem shunt.
         Septo interventricular íntegro sem shunt.
         Veias cavas com drenagem e fluxos normais.
         Veias pulmonares com drenagem e fluxos normais.

5-Artérias:
         Artéria Aorta com dimensões e morfologia normais.
         Artéria Pulmonar com dimensões e morfologia normais.

6-Pericárdio:
         Com aspecto e espessura normais, sem derrame.


CONCLUSÃO:  Normal sob aspecto anatômico.
`,
    },
  ],
  fetal: [
    {
      normal: `Idade gestacional referida: --- semanas.
Idade gestacional estimada: --- semanas.

O exame foi realizado com ritmo cardíaco fetal regular, com FC mínima de --- bpm e máxima de --- bpm.
O "situs" visceroatrial é "solitus", com levocardia e levoposição do ápex.
A conexão venosa sistêmica é normal. Conexão venosa pulmonar: visualizado pelo menos duas veias drenando no átrio esquerdo.
A conexão atrioventricular é do tipo biventricular concordante. Há concordância ventrículo-arterial.
O forame oval está patente e permite a passagem livre de fluxo do átrio direito para o átrio esquerdo.

Não se observa defeito evidente do septo interventricular, não sendo possível excluir pequenos defeitos.
As cavidades cardíacas apresentam dimensões normais.
O miocárdio de ambos os ventrículos têm espessura e contratilidade normais, evidenciando boa função sistólica deles.
Não foram observadas alterações anatômicas e funcionais evidentes das valvas atrioventriculares, aórtica e pulmonar. A análise de fluxo por elas ao mapeamento de fluxo em cores e ao Doppler é normal.
Tronco pulmonar e artérias pulmonares direita e esquerda apresentam dimensões normais.
O arco aórtico é voltado para a esquerda, com calibre conservado, sem anormalidades evidentes ao longo do seu trajeto.
O canal arterial está patente e apresenta fluxo de características normais direcionado do tronco pulmonar para a aorta descendente.

Obs: As principais dificuldades diagnósticas do ecocardiograma fetal são coarctação de aorta, drenagem anômala de veias pulmonares, comunicação interventricular pequena e comunicação interatrial, devido às peculiaridades da circulação fetal. Estruturas de calibre reduzido como artérias coronárias são de limitada avaliação devido a limitação do método.
Segundo a Diretriz de atualização da ASE de 2023 sobre Ecocardiografia Fetal e a Diretriz Brasileira de Cardiologia Fetal de 2019, o período ideal para se obter imagens com melhor resolução é entre a 24ª e 28ª semana de idade gestacional.

Conclusão:
EXAME NORMAL PARA A IDADE GESTACIONAL.
Cálculos: Boston Children’s Hospital Z-score; Cardio Z (Z-Score App); Vigneswaran et al, 2018.
`,
    },
    {
      alterado: `Idade gestacional referida: --- semanas.
Idade gestacional estimada: --- semanas.

O exame foi realizado com ritmo cardíaco fetal regular, com FC mínima de --- bpm e máxima de --- bpm.
O "situs" visceroatrial é "solitus", com levocardia e levoposição do ápex.
A conexão venosa sistêmica é normal. Conexão venosa pulmonar: visualizado pelo menos duas veias drenando no átrio esquerdo.
A conexão atrioventricular é do tipo biventricular concordante. Há concordância ventrículo-arterial.
O forame oval está patente e permite a passagem livre de fluxo do átrio direito para o átrio esquerdo.

Não se observa defeito evidente do septo interventricular, não sendo possível excluir pequenos defeitos.
As cavidades cardíacas apresentam dimensões normais.
O miocárdio de ambos os ventrículos têm espessura e contratilidade normais, evidenciando boa função sistólica deles.
Não foram observadas alterações anatômicas e funcionais evidentes das valvas atrioventriculares, aórtica e pulmonar. A análise de fluxo por elas ao mapeamento de fluxo em cores e ao Doppler é normal.
Tronco pulmonar e artérias pulmonares direita e esquerda apresentam dimensões normais.
O arco aórtico é voltado para a esquerda, com calibre conservado, sem anormalidades evidentes ao longo do seu trajeto.
O canal arterial está patente e apresenta fluxo de características normais direcionado do tronco pulmonar para a aorta descendente.

Obs: As principais dificuldades diagnósticas do ecocardiograma fetal são coarctação de aorta, drenagem anômala de veias pulmonares, comunicação interventricular pequena e comunicação interatrial, devido às peculiaridades da circulação fetal. Estruturas de calibre reduzido como artérias coronárias são de limitada avaliação devido a limitação do método.
Segundo a Diretriz de atualização da ASE de 2023 sobre Ecocardiografia Fetal e a Diretriz Brasileira de Cardiologia Fetal de 2019, o período ideal para se obter imagens com melhor resolução é entre a 24ª e 28ª semana de idade gestacional.

Conclusão:
EXAME NORMAL PARA A IDADE GESTACIONAL.
Cálculos: Boston Children’s Hospital Z-score; Cardio Z (Z-Score App); Vigneswaran et al, 2018.
`,
    },
  ],
  congenito: [
    {
      normal: `ECODOPPLERCARDIOGRAMA CONGÊNITO(NORMAL)

Peso: --- Kg. Estatura: --- cm.
O exame foi realizado com qualidade técnica satisfatória e o paciente estava em ritmo cardíaco sinusal com frequência cardíaca de --- bpm.

ANÁLISE SEGMENTAR SEQUENCIAL:
O “situs” visceral é “sólitus” e o ápice cardíaco está voltado para a esquerda.
ÁTRIO DIREITO: Dimensões e morfologia normais, recebe drenagem da veia cava inferior e superior.
ÁTRIO ESQUERDO: Dimensões e morfologia normais, recebe drenagem das veias pulmonares.
SEPTO INTERATRIAL: Íntegro.
JUNÇÃO ATRIOVENTRICULAR: Concordante.
JUNÇÃO VENTRÍCULO-ARTERIAL: Concordante.
VALVA TRICÚSPIDE: Morfologia e função normais.
VALVA MITRAL: Morfologia e função normais.
VENTRÍCULO DIREITO: Localizado anteriormente e à direita com dimensão normal, via de saída sem obstrução e função global preservada.
VENTRÍCULO ESQUERDO: Localizado posteriormente e à esquerda com dimensão normal, via de saída sem obstrução e função global e segmentar preservadas.
SEPTO INTERVENTRICULAR: Íntegro.
VALVA PULMONAR: Trivalvulada, localizada anteriormente e à esquerda com morfologia e função normais.
VALVA AÓRTICA: Trivalvulada, localizada posteriormente e à direita com morfologia e função normais.
ARTÉRIAS CORONÁRIAS: Originam-se dos seios correspondentes e sem alterações.
ARTÉRIA PULMONAR: Dimensão normal e localizada anteriormente e à esquerda.
ARTÉRIA AORTA: Dimensão normal e localizada posteriormente e à direita.
ARCO AÓRTICO: À esquerda em relação à traqueia, sem obstruções e o canal arterial não foi visibilizado.

MEDIDAS:
AE: --- mm. Ao: --- mm. DdVD: --- mm. DdSIV: --- mm. DdPPVE: --- mm. DdVE: --- mm. DsVE: --- mm.
FE (Teich): --- %. (Simpson): --- %.
Massa do VE: --- g. Índice de massa: --- g/m2. RWT: ---.

CONCLUSÃO:
ECOCARDIOGRAMA NORMAL.

Cálculo de Z-score: Boston Children’s Hospital Z-score; Cardio Z (Z-Score App).

`,
    },
    {
      civ: `ECODOPPLERCARDIOGRAMA CONGÊNITO (CIV)

Peso: --- Kg. Estatura: --- cm.
O exame foi realizado com qualidade técnica satisfatória e o paciente estava em ritmo cardíaco sinusal com frequência cardíaca de --- bpm.

ANÁLISE SEGMENTAR SEQUENCIAL:
O “situs” visceral é “sólitus” e o ápice cardíaco está voltado para a esquerda.
ÁTRIO DIREITO: Dimensões e morfologia normais, recebe drenagem da veia cava inferior e superior.
ÁTRIO ESQUERDO: Dimensões e morfologia normais, recebe drenagem das veias pulmonares.
SEPTO INTERATRIAL: Íntegro.
JUNÇÃO ATRIOVENTRICULAR: Concordante.
JUNÇÃO VENTRÍCULO-ARTERIAL: Concordante.
VALVA TRICÚSPIDE: Morfologia e função normais.
VALVA MITRAL: Morfologia e função normais.
VENTRÍCULO DIREITO: Localizado anteriormente e à direita com dimensão normal, via de saída sem obstrução e função global preservada.
VENTRÍCULO ESQUERDO: Localizado posteriormente e à esquerda com dimensão normal, via de saída sem obstrução e função global e segmentar preservadas.
SEPTO INTERVENTRICULAR: Fluxo acelerado e turbulento na face direita do septo interventricular, compatível com comunicação interventricular medindo aproximadamente --- mm. Gradiente VE-VD de --- mmHg.
VALVA PULMONAR: Trivalvulada, localizada anteriormente e à esquerda com morfologia e função normais.
VALVA AÓRTICA: Trivalvulada, localizada posteriormente e à direita com morfologia e função normais.
ARTÉRIAS CORONÁRIAS: Originam-se dos seios correspondentes e sem alterações.
ARTÉRIA PULMONAR: Dimensão normal e localizada anteriormente e à esquerda.
ARTÉRIA AORTA: Dimensão normal e localizada posteriormente e à direita.
ARCO AÓRTICO: À esquerda em relação à traqueia, sem obstruções e o canal arterial não foi visibilizado.

MEDIDAS:
AE: --- mm. Ao: --- mm. DdVD: --- mm. DdSIV: --- mm. DdPPVE: --- mm. DdVE: --- mm. DsVE: --- mm.
FE (Teich): --- %. (Simpson): --- %.
Massa do VE: --- g. Índice de massa: --- g/m2. RWT: ---.
VP: --- mm (--- z). TP: --- mm (--- z). APD: --- mm (--- z). APE: --- mm (--- z).
Relação Qp/Qs estimada: --- (VR = <1,5).

CONCLUSÃO:
COMUNICAÇÃO INTERVENTRICULAR --- REPERCUSSÃO HEMODINÂMICA.

Cálculo de Z-score: Boston Children’s Hospital Z-score; Cardio Z (Z-Score App).
`,
    },
    {
      fop: `ECODOPPLERCARDIOGRAMA CONGÊNITO (FOP)

Peso: --- Kg. Estatura: --- cm.
O exame foi realizado com qualidade técnica satisfatória e o paciente estava em ritmo cardíaco sinusal com frequência cardíaca de --- bpm.

ANÁLISE SEGMENTAR SEQUENCIAL:
O “situs” visceral é “sólitus” e o ápice cardíaco está voltado para a esquerda.
ÁTRIO DIREITO: Dimensões e morfologia normais, recebe drenagem da veia cava inferior e superior.
ÁTRIO ESQUERDO: Dimensões e morfologia normais, recebe drenagem das veias pulmonares.
SEPTO INTERATRIAL: Discreto fluxo na face direita do septo interatrial compatível com forame oval pérvio.
JUNÇÃO ATRIOVENTRICULAR: Concordante.
JUNÇÃO VENTRÍCULO-ARTERIAL: Concordante.
VALVA TRICÚSPIDE: Morfologia e função normais.
VALVA MITRAL: Morfologia e função normais.
VENTRÍCULO DIREITO: Localizado anteriormente e à direita com dimensão normal, via de saída sem obstrução e função global preservada.
VENTRÍCULO ESQUERDO: Localizado posteriormente e à esquerda com dimensão normal, via de saída sem obstrução e função global e segmentar preservadas.
SEPTO INTERVENTRICULAR: Íntegro.
VALVA PULMONAR: Trivalvulada, localizada anteriormente e à esquerda com morfologia e função normais.
VALVA AÓRTICA: Trivalvulada, localizada posteriormente e à direita com morfologia e função normais.
ARTÉRIAS CORONÁRIAS: Originam-se dos seios correspondentes e sem alterações.
ARTÉRIA PULMONAR: Dimensão normal e localizada anteriormente e à esquerda.
ARTÉRIA AORTA: Dimensão normal e localizada posteriormente e à direita.
ARCO AÓRTICO: À esquerda em relação à traqueia, sem obstruções e o canal arterial não foi visibilizado.

MEDIDAS:
AE: --- mm. Ao: --- mm. DdVD: --- mm. DdSIV: --- mm. DdPPVE: --- mm. DdVE: --- mm. DsVE: --- mm.
FE (Teich): --- %. (Simpson): --- %.
Massa do VE: --- g. Índice de massa: --- g/m2. RWT: ---.

CONCLUSÃO:
FORAME OVAL PÉRVIO.

Cálculo de Z-score: Boston Children’s Hospital Z-score; Cardio Z (Z-Score App).
`,
    },
    {
      t4f: `ECODOPPLERCARDIOGRAMA CONGÊNITO(T4F)

Peso: --- Kg. Estatura: --- cm.
O exame foi realizado com qualidade técnica satisfatória e o paciente estava em ritmo cardíaco sinusal com frequência cardíaca de --- bpm.

ANÁLISE SEGMENTAR SEQUENCIAL:
O “situs” visceral é “sólitus” e o ápice cardíaco está voltado para a esquerda.
ÁTRIO DIREITO: Dimensões e morfologia normais, recebe drenagem da veia cava inferior e superior.
ÁTRIO ESQUERDO: Dimensões e morfologia normais, recebe drenagem das veias pulmonares.
SEPTO INTERATRIAL: Íntegro.
JUNÇÃO ATRIOVENTRICULAR: Concordante.
JUNÇÃO VENTRÍCULO-ARTERIAL: Concordante.
VALVA TRICÚSPIDE: Morfologia e função normais.
VALVA MITRAL: Morfologia e função normais.
VENTRÍCULO DIREITO: Localizado anteriormente e à direita, dilatado e hipertrófico, via de saída com estenose infundíbulo-valvar por desvio anterior do septo. Função global preservada.
VENTRÍCULO ESQUERDO: Localizado posteriormente e à esquerda com dimensão normal, via de saída sem obstrução e função global e segmentar preservadas.
SEPTO INTERVENTRICULAR: Fluxo turbulento na face direita do septo interventricular compatível com comunicação interventricular de mau-alinhamento anterior do septo, medindo aproximadamente --- mm.
VALVA PULMONAR: Trivalvulada, localizada anteriormente e à esquerda com folhetos espessados. Visibilizado fluxo sistólico acelerado e turbulento em tronco pulmonar devido estenose infundíbulo-valvar. Gradiente instantâneo máximo VD-TP de --- mmHg.
VALVA AÓRTICA: Trivalvulada, localizada posteriormente e à direita com dextroposição e cavalgamento menor que 50% em relação ao septo interventricular. Morfologia e função normais.
ARTÉRIAS CORONÁRIAS: Originam-se dos seios correspondentes e sem alterações.
ARTÉRIA PULMONAR: Dimensão normal e localizada anteriormente e à esquerda.
ARTÉRIA AORTA: Dimensão normal e localizada posteriormente e à direita.
ARCO AÓRTICO: À esquerda em relação à traqueia, sem obstruções e o canal arterial não foi visibilizado.

MEDIDAS:
AE: --- mm. Ao: --- mm. DdVD: --- mm. DdSIV: --- mm. DdPPVE: --- mm. DdVE: --- mm. DsVE: --- mm.
FE (Teich): --- %. (Simpson): --- %.
Massa do VE: --- g. Índice de massa: --- g/m2. RWT: ---.
VP: --- mm (--- z). TP: --- mm (--- z). APD: --- mm (--- z). APE: --- mm (--- z).

CONCLUSÃO:
TETRALOGIA DE FALLOT.

Cálculo de Z-score: Boston Children’s Hospital Z-score; Cardio Z (Z-Score App).
`,
    },
    {
      pca: `ECODOPPLERCARDIOGRAMA CONGÊNITO(PCA)

Peso: --- Kg. Estatura: --- cm.
O exame foi realizado com qualidade técnica satisfatória e o paciente estava em ritmo cardíaco sinusal com frequência cardíaca de --- bpm.

ANÁLISE SEGMENTAR SEQUENCIAL:
O “situs” visceral é “sólitus” e o ápice cardíaco está voltado para a esquerda.
ÁTRIO DIREITO: Dimensões e morfologia normais, recebe drenagem da veia cava inferior e superior.
ÁTRIO ESQUERDO: Dimensões e morfologia normais, recebe drenagem das veias pulmonares.
SEPTO INTERATRIAL: Íntegro.
JUNÇÃO ATRIOVENTRICULAR: Concordante.
JUNÇÃO VENTRÍCULO-ARTERIAL: Concordante.
VALVA TRICÚSPIDE: Morfologia e função normais.
VALVA MITRAL: Morfologia e função normais.
VENTRÍCULO DIREITO: Localizado anteriormente e à direita com dimensão normal, via de saída sem obstrução e função global preservada.
VENTRÍCULO ESQUERDO: Localizado posteriormente e à esquerda com dimensão normal, via de saída sem obstrução e função global e segmentar preservadas.
SEPTO INTERVENTRICULAR: Íntegro.
VALVA PULMONAR: Trivalvulada, localizada anteriormente e à esquerda com morfologia e função normais.
VALVA AÓRTICA: Trivalvulada, localizada posteriormente e à direita com morfologia e função normais.
ARTÉRIAS CORONÁRIAS: Originam-se dos seios correspondentes e sem alterações.
ARTÉRIA PULMONAR: Dimensão normal e localizada anteriormente e à esquerda.
ARTÉRIA AORTA: Dimensão normal e localizada posteriormente e à direita.
ARCO AÓRTICO: À esquerda em relação à traqueia, sem obstruções. Fluxo acelerado e contínuo no interior da artéria pulmonar compatível com persistência do canal arterial que mede aproximadamente --- mm. Gradiente sistólico máximo Ao-TP de --- mmHg.

MEDIDAS:
AE: --- mm. Ao: --- mm. DdVD: --- mm. DdSIV: --- mm. DdPPVE: --- mm. DdVE: --- mm. DsVE: --- mm.
FE (Teich): --- %. (Simpson): --- %.
Massa do VE: --- g. Índice de massa: --- g/m2. RWT: ---.
VP: --- mm (--- z). TP: --- mm (--- z). APD: --- mm (--- z). APE: --- mm (--- z).

CONCLUSÃO:
CANAL ARTERIAL PÉRVIO DE --- TAMANHO, --- REPERCUSSÃO HEMODINÂMICA.

Cálculo de Z-score: Boston Children’s Hospital Z-score; Cardio Z (Z-Score App).
`,
    },
    {
      cia: `ECODOPPLERCARDIOGRAMA CONGÊNITO (CIA)

Peso: --- Kg. Estatura: --- cm.
O exame foi realizado com qualidade técnica satisfatória e o paciente estava em ritmo cardíaco sinusal com frequência cardíaca de --- bpm.

ANÁLISE SEGMENTAR SEQUENCIAL:
O “situs” visceral é “sólitus” e o ápice cardíaco está voltado para a esquerda.
ÁTRIO DIREITO: Dimensões e morfologia normais, recebe drenagem da veia cava inferior e superior.
ÁTRIO ESQUERDO: Dimensões e morfologia normais, recebe drenagem das veias pulmonares.
SEPTO INTERATRIAL: Fluxo acelerado na face direita do septo interatrial compatível com comunicação interatrial tipo “ostium secundum” medindo aproximadamente --- mm.
JUNÇÃO ATRIOVENTRICULAR: Concordante.
JUNÇÃO VENTRÍCULO-ARTERIAL: Concordante.
VALVA TRICÚSPIDE: Morfologia e função normais.
VALVA MITRAL: Morfologia e função normais.
VENTRÍCULO DIREITO: Localizado anteriormente e à direita com dimensão normal, via de saída sem obstrução e função global preservada.
VENTRÍCULO ESQUERDO: Localizado posteriormente e à esquerda com dimensão normal, via de saída sem obstrução e função global e segmentar preservadas.
SEPTO INTERVENTRICULAR: Íntegro.
VALVA PULMONAR: Trivalvulada, localizada anteriormente e à esquerda com morfologia e função normais.
VALVA AÓRTICA: Trivalvulada, localizada posteriormente e à direita com morfologia e função normais.
ARTÉRIAS CORONÁRIAS: Originam-se dos seios correspondentes e sem alterações.
ARTÉRIA PULMONAR: Dimensão normal e localizada anteriormente e à esquerda.
ARTÉRIA AORTA: Dimensão normal e localizada posteriormente e à direita.
ARCO AÓRTICO: À esquerda em relação à traqueia, sem obstruções e o canal arterial não foi visibilizado.

MEDIDAS:
AE: --- mm. Ao: --- mm. DdVD: --- mm. DdSIV: --- mm. DdPPVE: --- mm. DdVE: --- mm. DsVE: --- mm.
FE (Teich): --- %. (Simpson): --- %.
Massa do VE: --- g. Índice de massa: --- g/m2. RWT: ---.
VP: --- mm (--- z). TP: --- mm (--- z). APD: --- mm (--- z). APE: --- mm (--- z).
Relação Qp/Qs estimada: --- (VR = <1,5).

CONCLUSÃO:
COMUNICAÇÃO INTERATRIAL TIPO “OSTIUM SECUNDUM” --- REPERCUSSÃO HEMODINÂMICA.

Cálculo de Z-score: Boston Children’s Hospital Z-score; Cardio Z (Z-Score App).
`,
    },
    {
      tga: `ECODOPPLERCARDIOGRAMA CONGÊNITO (TGA)

Exame realizado com qualidade técnica satisfatória com o paciente em ritmo cardíaco sinusal e frequência cardíaca de --- bpm.

ANÁLISE SEGMENTAR SEQUENCIAL:

O “situs” visceral é “sólitus” e o ápice cardíaco está voltado para a esquerda.
Átrio direito de tamanho e morfologia normal e recebe drenagem da veia cava inferior e superior.
Átrio esquerdo de tamanho e morfologia normal e recebe drenagem das veias pulmonares.
Foi visibilizado fluxo bidirecional pela comunicação interatrial não restritiva.
Junção atrioventricular concordante com discordância ventrículo arterial.
Valva tricúspide e mitral com morfologia e função normais.
Ventrículo direito localizado anteriormente e à direita com tamanho normal, via de saída sem obstrução e função global preservada.
Ventrículo esquerdo localizado posteriormente e à esquerda com tamanho normal, via de saída sem obstrução e função global e segmentar preservadas. Fração de ejeção (Teich): --- %.
Foi visibilizado o septo interventricular íntegro.
Valva pulmonar trivalvulada e localizada posterior e à direita com morfologia e função normais.
Valva aórtica trivalvulada e localizada anterior e à esquerda com morfologia e função normais com artérias coronárias direita e esquerda originando-se dos seios correspondentes e sem alterações.
Artéria pulmonar de tamanho normal e está localizada posteriormente.
Artéria aorta de tamanho normal e está localizada anteriormente.
O arco aórtico está voltado para à esquerda em relação à traqueia e não possui obstruções. Canal arterial não foi visibilizado.

CONCLUSÃO:
TRANSPOSIÇÃO DAS GRANDES ARTÉRIAS.



Foi utilizado o site https://zscore.chboston.org para o cálculo de Z-score deste exame.
`,
    },
    {
      dsavt: `ECODOPPLERCARDIOGRAMA CONGÊNITO (DSAVT)

Peso: --- Kg. Estatura: --- cm.
O exame foi realizado com qualidade técnica satisfatória e o paciente estava em ritmo cardíaco sinusal com frequência cardíaca de --- bpm.

ANÁLISE SEGMENTAR SEQUENCIAL:
O “situs” visceral é “sólitus” e o ápice cardíaco está voltado para a esquerda.
ÁTRIO DIREITO: Dimensões e morfologia normais, recebe drenagem da veia cava inferior e superior.
ÁTRIO ESQUERDO: Dimensões e morfologia normais, recebe drenagem das veias pulmonares.
SEPTO INTERATRIAL: Fluxo bidirecional no septo interagirão compatível com comunicação interatrial do tipo “ostium primum” medindo aproximadamente --- mm.
JUNÇÃO ATRIOVENTRICULAR: Concordante.
JUNÇÃO VENTRÍCULO-ARTERIAL: Concordante.
VALVA ATRIOVENTRICULAR: Única, com insersão do folheto ponte anterior na ---.
VENTRÍCULO DIREITO: Localizado anteriormente e à direita com dimensão normal, via de saída sem obstrução e função global preservada.
VENTRÍCULO ESQUERDO: Localizado posteriormente e à esquerda com dimensão normal, via de saída alongada “Goose neck” sem obstrução e função global e segmentar preservadas.
SEPTO INTERVENTRICULAR: Fluxo acelerado bidirecional no septo interventricular compatível com comunicação interventricular por ausência do septo atrioventricular medindo aproximadamente --- mm.
VALVA PULMONAR: Trivalvulada, localizada anteriormente e à esquerda com morfologia e função normais.
VALVA AÓRTICA: Trivalvulada, localizada posteriormente e à direita com morfologia e função normais.
ARTÉRIAS CORONÁRIAS: Originam-se dos seios correspondentes e sem alterações.
ARTÉRIA PULMONAR: Dimensão normal e localizada anteriormente e à esquerda.
ARTÉRIA AORTA: Dimensão normal e localizada posteriormente e à direita.
ARCO AÓRTICO: À esquerda em relação à traqueia, sem obstruções e o canal arterial não foi visibilizado.

MEDIDAS:
AE: --- mm. Ao: --- mm. DdVD: --- mm. DdSIV: --- mm. DdPPVE: --- mm. DdVE: --- mm. DsVE: --- mm.
FE (Teich): --- %. (Simpson): --- %.
Massa do VE: --- g. Índice de massa: --- g/m2. RWT: ---.
VP: --- mm (--- z). TP: --- mm (--- z). APD: --- mm (--- z). APE: --- mm (--- z).
Relação Qp/Qs estimada: --- (VR = <1,5).

CONCLUSÃO:
DEFEITO DO SEPTO ATRIOVENTRICULAR TOTAL TIPO “---” DE RASTELLI.

Cálculo de Z-score: Boston Children’s Hospital Z-score; Cardio Z (Z-Score App).
`,
    },
    {
      coao: `ECODOPPLERCARDIOGRAMA CONGÊNITO (COAO)

Peso: --- Kg. Estatura: --- cm.
O exame foi realizado com qualidade técnica satisfatória e o paciente estava em ritmo cardíaco sinusal com frequência cardíaca de --- bpm.

ANÁLISE SEGMENTAR SEQUENCIAL:
O “situs” visceral é “sólitus” e o ápice cardíaco está voltado para a esquerda.
ÁTRIO DIREITO: Dimensões e morfologia normais, recebe drenagem da veia cava inferior e superior.
ÁTRIO ESQUERDO: Dimensões e morfologia normais, recebe drenagem das veias pulmonares.
SEPTO INTERATRIAL: Íntegro.
JUNÇÃO ATRIOVENTRICULAR: Concordante.
JUNÇÃO VENTRÍCULO-ARTERIAL: Concordante.
VALVA TRICÚSPIDE: Morfologia e função normais.
VALVA MITRAL: Morfologia e função normais.
VENTRÍCULO DIREITO: Localizado anteriormente e à direita com dimensão normal, via de saída sem obstrução e função global preservada.
VENTRÍCULO ESQUERDO: Localizado posteriormente e à esquerda com dimensão normal, via de saída sem obstrução e função global e segmentar preservadas.
SEPTO INTERVENTRICULAR: Íntegro.
VALVA PULMONAR: Trivalvulada, localizada anteriormente e à esquerda com morfologia e função normais.
VALVA AÓRTICA: Trivalvulada, localizada posteriormente e à direita com morfologia e função normais.
ARTÉRIAS CORONÁRIAS: Originam-se dos seios correspondentes e sem alterações.
ARTÉRIA PULMONAR: Dimensão normal e localizada anteriormente e à esquerda.
ARTÉRIA AORTA: Dimensão normal e localizada posteriormente e à direita.
ARCO AÓRTICO: À esquerda em relação à traqueia, Visibilizado fluxo acelerado com estreitamento em região do istmo. Gradiente sistólico máximo de --- mmHg. Canal arterial não foi visibilizado.
Visibilizado fluxo sisto-diastólico, “reforço diastólico” em aorta abdominal.

MEDIDAS:
AE: --- mm. Ao: --- mm. DdVD: --- mm. DdSIV: --- mm. DdPPVE: --- mm. DdVE: --- mm. DsVE: --- mm.
FE (Teich): --- %. (Simpson): --- %.
Massa do VE: --- g. Índice de massa: --- g/m2. RWT: ---.
VAo: --- mm (--- z). Raiz Ao: --- mm (--- z). JST: --- mm (--- z).
Ao ascendente: --- mm (--- z). A. transverso: --- mm (--- z). Ístmo Ao: --- mm (--- z). Ao descendente: --- mm (--- z).

CONCLUSÃO:
COARCTAÇÃO DE AORTA.

Cálculo de Z-score: Boston Children’s Hospital Z-score; Cardio Z (Z-Score App).
`,
    },
  ],
  motivo: [
    {
      CODMOTIVO: 1,
      DESCRICAO: 'Não especificado',
    },
    {
      CODMOTIVO: 2,
      DESCRICAO: 'Não codificado',
    },
    {
      CODMOTIVO: 3,
      DESCRICAO: 'Quadro sincopal',
    },
    {
      CODMOTIVO: 4,
      DESCRICAO: 'Hipofluxo cerebral',
    },
    {
      CODMOTIVO: 5,
      DESCRICAO: 'Bradicardia',
    },
    {
      CODMOTIVO: 6,
      DESCRICAO: 'Taquicardia',
    },
    {
      CODMOTIVO: 7,
      DESCRICAO: 'Profilático',
    },
    {
      CODMOTIVO: 8,
      DESCRICAO: 'Insuficiência Cardíaca',
    },
    {
      CODMOTIVO: 9,
      DESCRICAO: 'Doença do Nó Sinusal',
    },
    {
      CODMOTIVO: 10,
      DESCRICAO: 'BAV 2:1',
    },
    {
      CODMOTIVO: 11,
      DESCRICAO: 'BAV 2o Grau Tipo II',
    },
    {
      CODMOTIVO: 12,
      DESCRICAO: 'BAVT',
    },
    {
      CODMOTIVO: 13,
      DESCRICAO: 'BAV Avançado',
    },
  ],
  etiologia: [
    {
      CODETIOLOGIA: 1,
      DESCRICAO: 'Não especificada',
    },
    {
      CODETIOLOGIA: 2,
      DESCRICAO: 'Causa desconhecida',
    },
    {
      CODETIOLOGIA: 3,
      DESCRICAO: 'Doença de Chagas',
    },
    {
      CODETIOLOGIA: 4,
      DESCRICAO: 'Miocardioesclerose',
    },
    {
      CODETIOLOGIA: 5,
      DESCRICAO: 'Insuficiência Coronariana',
    },
    {
      CODETIOLOGIA: 6,
      DESCRICAO: 'Infarto do Miocárdio',
    },
    {
      CODETIOLOGIA: 7,
      DESCRICAO: 'Sequela pós cirúrgica',
    },
    {
      CODETIOLOGIA: 8,
      DESCRICAO: 'Cardiopatia Congênita',
    },
    {
      CODETIOLOGIA: 9,
      DESCRICAO: 'Miocardiopatia Dilatada',
    },
    {
      CODETIOLOGIA: 10,
      DESCRICAO: 'Miocardite',
    },
    {
      CODETIOLOGIA: 11,
      DESCRICAO: 'Valvopatia',
    },
    {
      CODETIOLOGIA: 12,
      DESCRICAO: 'Hipersensibilidade do Seio Carotídeo',
    },
    {
      CODETIOLOGIA: 13,
      DESCRICAO: 'Pós ablação de via anômala',
    },
    {
      CODETIOLOGIA: 14,
      DESCRICAO: 'Uso de drogas bradicardizantes',
    },
  ],
  fabricante: [
    {
      CODFABRICANTE: 1,
      FABRICANTE: 'Medtronic',
    },
    {
      CODFABRICANTE: 2,
      FABRICANTE: 'Telectronics',
    },
    {
      CODFABRICANTE: 3,
      FABRICANTE: 'Biotronik',
    },
    {
      CODFABRICANTE: 4,
      FABRICANTE: 'CPI',
    },
    {
      CODFABRICANTE: 5,
      FABRICANTE: 'Biomedics',
    },
    {
      CODFABRICANTE: 6,
      FABRICANTE: 'Vigomed',
    },
    {
      CODFABRICANTE: 7,
      FABRICANTE: 'Sorin',
    },
    {
      CODFABRICANTE: 8,
      FABRICANTE: 'Brascor',
    },
    {
      CODFABRICANTE: 9,
      FABRICANTE: 'Pacesetter',
    },
    {
      CODFABRICANTE: 10,
      FABRICANTE: 'Oscor Medical',
    },
    {
      CODFABRICANTE: 11,
      FABRICANTE: 'BEA',
    },
    {
      CODFABRICANTE: 12,
      FABRICANTE: 'Vitatron',
    },
    {
      CODFABRICANTE: 13,
      FABRICANTE: 'St Jude',
    },
    {
      CODFABRICANTE: 14,
      FABRICANTE: 'Boston',
    },
    {
      CODFABRICANTE: 15,
      FABRICANTE: 'ABBOTT',
    },
  ],
  modelo: [
    {
      CODMODELO: 1,
      MODELO: 'Prevail 8086',
    },
    {
      CODMODELO: 2,
      MODELO: 'Prodigy 7862',
    },
    {
      CODMODELO: 3,
      MODELO: 'Prevail 8085',
    },
    {
      CODMODELO: 4,
      MODELO: 'Capsurefix 4068',
    },
    {
      CODMODELO: 5,
      MODELO: 'Capsurefix 5068',
    },
    {
      CODMODELO: 6,
      MODELO: 'Capsure 5024',
    },
    {
      CODMODELO: 7,
      MODELO: 'Prodigy SR 8161',
    },
    {
      CODMODELO: 8,
      MODELO: 'Sigma DR 303',
    },
    {
      CODMODELO: 9,
      MODELO: 'Capsure 5034',
    },
    {
      CODMODELO: 10,
      MODELO: 'Prodigy SR 8160',
    },
    {
      CODMODELO: 11,
      MODELO: 'Thera SR 8962 i',
    },
    {
      CODMODELO: 12,
      MODELO: 'PS 60 UP',
    },
    {
      CODMODELO: 13,
      MODELO: 'YP 53 BP',
    },
    {
      CODMODELO: 14,
      MODELO: 'Prodigy SR 8162',
    },
    {
      CODMODELO: 15,
      MODELO: 'Sigma SR 303',
    },
    {
      CODMODELO: 16,
      MODELO: '5026-58',
    },
    {
      CODMODELO: 17,
      MODELO: 'TIR 60 BP',
    },
    {
      CODMODELO: 18,
      MODELO: 'TIJ 53 BP',
    },
    {
      CODMODELO: 19,
      MODELO: '4012-58',
    },
    {
      CODMODELO: 20,
      MODELO: 'TIR 60 UP',
    },
    {
      CODMODELO: 21,
      MODELO: 'YP 60 BP',
    },
    {
      CODMODELO: 22,
      MODELO: 'Sigma SR 306',
    },
    {
      CODMODELO: 23,
      MODELO: '4011-58',
    },
    {
      CODMODELO: 24,
      MODELO: '4003-58',
    },
    {
      CODMODELO: 25,
      MODELO: '030-400',
    },
    {
      CODMODELO: 26,
      MODELO: 'NP 60 BP',
    },
    {
      CODMODELO: 27,
      MODELO: 'PE 60/4 DN',
    },
    {
      CODMODELO: 28,
      MODELO: 'Capsure novus 5054',
    },
    {
      CODMODELO: 29,
      MODELO: 'Tempo DR 2102',
    },
    {
      CODMODELO: 30,
      MODELO: '1148 T',
    },
    {
      CODMODELO: 31,
      MODELO: '033-301',
    },
    {
      CODMODELO: 32,
      MODELO: '033-462',
    },
    {
      CODMODELO: 33,
      MODELO: '033-448',
    },
    {
      CODMODELO: 34,
      MODELO: 'DNP 60 UP',
    },
    {
      CODMODELO: 35,
      MODELO: '4512 13',
    },
    {
      CODMODELO: 36,
      MODELO: '1346 T',
    },
    {
      CODMODELO: 37,
      MODELO: '6972-58',
    },
    {
      CODMODELO: 38,
      MODELO: 'DJP 60 UP',
    },
    {
      CODMODELO: 39,
      MODELO: 'Sigma DR 306',
    },
    {
      CODMODELO: 40,
      MODELO: 'KY 611',
    },
    {
      CODMODELO: 41,
      MODELO: '6957 J 53',
    },
    {
      CODMODELO: 42,
      MODELO: '4004-58',
    },
    {
      CODMODELO: 43,
      MODELO: '4503-53',
    },
    {
      CODMODELO: 44,
      MODELO: '6957-58',
    },
    {
      CODMODELO: 45,
      MODELO: '5071-53 epi / uni',
    },
    {
      CODMODELO: 46,
      MODELO: 'PS  53  UP',
    },
    {
      CODMODELO: 47,
      MODELO: 'TIR 53 BP',
    },
    {
      CODMODELO: 48,
      MODELO: '4261- 59',
    },
    {
      CODMODELO: 49,
      MODELO: 'DFH 60 A',
    },
    {
      CODMODELO: 50,
      MODELO: '5025-58',
    },
    {
      CODMODELO: 51,
      MODELO: 'Kappa KDR 403',
    },
    {
      CODMODELO: 52,
      MODELO: '033-484 F',
    },
    {
      CODMODELO: 53,
      MODELO: '5058',
    },
    {
      CODMODELO: 54,
      MODELO: 'Thera DR 7962 i',
    },
    {
      CODMODELO: 55,
      MODELO: '033-572',
    },
    {
      CODMODELO: 56,
      MODELO: '4067',
    },
    {
      CODMODELO: 57,
      MODELO: '4003-58',
    },
    {
      CODMODELO: 58,
      MODELO: 'QT 611 T',
    },
    {
      CODMODELO: 59,
      MODELO: 'PY 67',
    },
    {
      CODMODELO: 60,
      MODELO: 'Kappa KSR 403',
    },
    {
      CODMODELO: 61,
      MODELO: 'S 100 uni',
    },
    {
      CODMODELO: 62,
      MODELO: '5064-58 bifurcado 5m',
    },
    {
      CODMODELO: 63,
      MODELO: 'Capsure Novus 5054',
    },
    {
      CODMODELO: 64,
      MODELO: 'Capsurefix 5076-58',
    },
    {
      CODMODELO: 65,
      MODELO: 'Maxim 033-301',
    },
    {
      CODMODELO: 66,
      MODELO: '493-03',
    },
    {
      CODMODELO: 67,
      MODELO: 'PEB 60/4K10',
    },
    {
      CODMODELO: 68,
      MODELO: '6962-58',
    },
    {
      CODMODELO: 69,
      MODELO: '4058M',
    },
    {
      CODMODELO: 70,
      MODELO: 'Kappa KDR 901',
    },
    {
      CODMODELO: 71,
      MODELO: 'Capsurefix 4568 - 53',
    },
    {
      CODMODELO: 73,
      MODELO: 'Capsure VDD-2 5038S-',
    },
    {
      CODMODELO: 74,
      MODELO: 'SL 60/13 BP',
    },
    {
      CODMODELO: 75,
      MODELO: 'Kappa KSR 901',
    },
    {
      CODMODELO: 76,
      MODELO: '1188T',
    },
    {
      CODMODELO: 77,
      MODELO: '5984BLP',
    },
    {
      CODMODELO: 78,
      MODELO: 'Kappa KDR 903',
    },
    {
      CODMODELO: 79,
      MODELO: 'Kappa KSR 903',
    },
    {
      CODMODELO: 80,
      MODELO: 'Kappa KSR 906',
    },
    {
      CODMODELO: 81,
      MODELO: '4269',
    },
    {
      CODMODELO: 82,
      MODELO: 'S-80',
    },
    {
      CODMODELO: 84,
      MODELO: 'Actfix ICF09',
    },
    {
      CODMODELO: 85,
      MODELO: 'C20SR',
    },
    {
      CODMODELO: 86,
      MODELO: 'ICM09B',
    },
    {
      CODMODELO: 87,
      MODELO: 'T70DR',
    },
    {
      CODMODELO: 88,
      MODELO: '4069',
    },
    {
      CODMODELO: 89,
      MODELO: 'Sentra',
    },
    {
      CODMODELO: 90,
      MODELO: '1388T',
    },
    {
      CODMODELO: 91,
      MODELO: 'Enpulse E2SR03',
    },
  ],
  hospital: [
    {
      value: 'Santa Casa de Misericórdia de Marília',
      label: 'Santa Casa de Misericórdia de Marília',
    },
    {
      value: 'Santa Casa de Misericórdia de Tupã',
      label: 'Santa Casa de Misericórdia de Tupã',
    },
    {
      value: 'UNIMAR',
      label: 'UNIMAR',
    },
    {
      value: 'FAMEMA',
      label: 'FAMEMA',
    },
    {
      value: 'HCOR SAO PAULO',
      label: 'HCOR SAO PAULO',
    },
    {
      value: 'IMC SAO JOSE RIO PRETO',
      label: 'IMC SAO JOSE RIO PRETO',
    },
    {
      value: 'PRESIDENTE PRUDENTE',
      label: 'PRESIDENTE PRUDENTE',
    },
    {
      value: 'BAURU',
      label: 'BAURU',
    },
    {
      value: 'BOTUCATU',
      label: 'BOTUCATU',
    },
    {
      value: 'SALTO',
      label: 'SALTO',
    },
    {
      value: 'HC MARILIA',
      label: 'HC MARILIA',
    },
    {
      value: 'OURINHOS',
      label: 'OURINHOS',
    },
    {
      value: 'LINS',
      label: 'LINS',
    },
  ],
  scan: {
    velocidade: [
      {
        CODVELOCIDADE: 1,
        VELOCIDADE: 'sem causar alteração do fluxo',
      },
      {
        CODVELOCIDADE: 2,
        VELOCIDADE: 'sem causar alteração significativa do fluxo',
      },
      {
        CODVELOCIDADE: 3,
        VELOCIDADE: 'causando discreto aumento da velocidade do fluxo',
      },
      {
        CODVELOCIDADE: 4,
        VELOCIDADE:
          'causando discreto aumento da velocidade do fluxo, estenose não significativa.',
      },
      {
        CODVELOCIDADE: 5,
        VELOCIDADE:
          'causando aumento da velocidade do fluxo, correspondendo a estenose',
      },
      {
        CODVELOCIDADE: 6,
        VELOCIDADE: 'com fluxo apresentando baixa velocidade.',
      },
      {
        CODVELOCIDADE: 7,
        VELOCIDADE: 'Vide observações.',
      },
      {
        CODVELOCIDADE: 8,
        VELOCIDADE: 'levando a obstrução total e ausência de fluxo',
      },
      {
        CODVELOCIDADE: 9,
        VELOCIDADE: 'com fluxo filamentar compatível com suboclusão',
      },
      {
        CODVELOCIDADE: 10,
        VELOCIDADE:
          'sendo a maior no terço proximal, levando a aumento da velocidade do fluxo e correspondendo a estenose',
      },
      {
        CODVELOCIDADE: 11,
        VELOCIDADE:
          'sendo a maior no terço médio, levando a aumento da velocidade do fluxo e correspondendo a estenose',
      },
      {
        CODVELOCIDADE: 12,
        VELOCIDADE:
          'sendo a maior no terço distal, levando a aumento da velocidade do fluxo e correspondendo a estenose',
      },
      {
        CODVELOCIDADE: 13,
        VELOCIDADE: 'nota-se fluxo retrógrado distal ao ponto de oclusão',
      },
      {
        CODVELOCIDADE: 14,
        VELOCIDADE: 'presença de fluxo anterógrado',
      },
      {
        CODVELOCIDADE: 15,
        VELOCIDADE:
          'sem causar alteração significativa do fluxo, correspondendo a estenose',
      },
    ],
    intensidade: [
      {
        CODINTENSIDADE: 1,
        INTENSIDADE: 'discreta.',
      },
      {
        CODINTENSIDADE: 2,
        INTENSIDADE: 'moderada.',
      },
      {
        CODINTENSIDADE: 3,
        INTENSIDADE: 'importante.',
      },
      {
        CODINTENSIDADE: 4,
        INTENSIDADE: 'discreta menor que 40%.',
      },
      {
        CODINTENSIDADE: 5,
        INTENSIDADE: 'discreta de aproximadamente 40%.',
      },
      {
        CODINTENSIDADE: 6,
        INTENSIDADE: 'de aproximadamente 50%.',
      },
      {
        CODINTENSIDADE: 7,
        INTENSIDADE: 'discreta de aproximadamente 60%.',
      },
      {
        CODINTENSIDADE: 8,
        INTENSIDADE: 'moderada de aproximadamente 60%.',
      },
      {
        CODINTENSIDADE: 9,
        INTENSIDADE: 'moderada de aproximadamente 70%.',
      },
      {
        CODINTENSIDADE: 10,
        INTENSIDADE: 'moderada de aproximadamente 80%.',
      },
      {
        CODINTENSIDADE: 11,
        INTENSIDADE: 'moderada a importante de aproximadamente 80%.',
      },
      {
        CODINTENSIDADE: 12,
        INTENSIDADE: 'importante de aproximadamente 90%.',
      },
      {
        CODINTENSIDADE: 13,
        INTENSIDADE: 'importante de aproximadamente 95%.',
      },
      {
        CODINTENSIDADE: 14,
        INTENSIDADE: 'severa de aproximadamente 95%.',
      },
      {
        CODINTENSIDADE: 15,
        INTENSIDADE: 'severa de aproximadamente 99%.',
      },
      {
        CODINTENSIDADE: 16,
        INTENSIDADE: 'entre 1% e 15%',
      },
      {
        CODINTENSIDADE: 17,
        INTENSIDADE: 'entre 16% e 49%',
      },
      {
        CODINTENSIDADE: 18,
        INTENSIDADE: 'entre 50% e 69%',
      },
      {
        CODINTENSIDADE: 19,
        INTENSIDADE: 'entre 70% e 90%',
      },
      {
        CODINTENSIDADE: 20,
        INTENSIDADE: 'maior que 70%',
      },
      {
        CODINTENSIDADE: 21,
        INTENSIDADE: 'maior que 90%',
      },
      {
        CODINTENSIDADE: 22,
        INTENSIDADE: 'maior que 90% (suboclusão)',
      },
      {
        CODINTENSIDADE: 23,
        INTENSIDADE: 'menor que 50%',
      },
      {
        CODINTENSIDADE: 24,
        INTENSIDADE: 'maior que 50%',
      },
      {
        CODINTENSIDADE: 25,
        INTENSIDADE:
          'De acordo com o Estudo ELSA-BRASIL, a medida da espessura encontra-se Normal para sexo, idade e etnia.',
      },
      {
        CODINTENSIDADE: 26,
        INTENSIDADE:
          'De acordo com o Estudo ELSA-BRASIL, a medida da espessura encontra-se Aumentada para sexo, idade e etnia.',
      },
    ],
    anatomia: [
      {
        CODANATOMIA: 1,
        ANATOMIA: 'Anatomia e fluxos normais.',
      },
      {
        CODANATOMIA: 2,
        ANATOMIA: 'Espessamento da camada íntima',
      },
      {
        CODANATOMIA: 3,
        ANATOMIA:
          'Espessamento da camada íntima e discretas irregularidades difusas',
      },
      {
        CODANATOMIA: 4,
        ANATOMIA: 'Irregularidades difusas',
      },
      {
        CODANATOMIA: 5,
        ANATOMIA: 'Pequenas placas e irregularidades difusas',
      },
      {
        CODANATOMIA: 6,
        ANATOMIA: 'Irregularidades difusas e placa fixa no terço proximal',
      },
      {
        CODANATOMIA: 7,
        ANATOMIA: 'Irregularidades difusas e placa fixa no terço médio',
      },
      {
        CODANATOMIA: 8,
        ANATOMIA: 'Irregularidades difusas e placa fixa no terço distal',
      },
      {
        CODANATOMIA: 9,
        ANATOMIA: 'Irregularidades difusas e placa ulcerada no terço proximal',
      },
      {
        CODANATOMIA: 10,
        ANATOMIA: 'Irregularidades difusas e placa ulcerada no terço médio',
      },
      {
        CODANATOMIA: 11,
        ANATOMIA: 'Irregularidades difusas e placa ulcerada no terço distal',
      },
      {
        CODANATOMIA: 12,
        ANATOMIA: 'Presença de placa fixa no terço proximal',
      },
      {
        CODANATOMIA: 13,
        ANATOMIA: 'Presença de placa fixa no terço médio',
      },
      {
        CODANATOMIA: 14,
        ANATOMIA: 'Presença de placa fixa no terço distal',
      },
      {
        CODANATOMIA: 15,
        ANATOMIA: 'Presença de placa ulcerada no terço proximal',
      },
      {
        CODANATOMIA: 16,
        ANATOMIA: 'Presença de placa ulcerada no terço médio',
      },
      {
        CODANATOMIA: 17,
        ANATOMIA: 'Presença de placa ulcerada no terço distal',
      },
      {
        CODANATOMIA: 18,
        ANATOMIA: 'Presença de placa fixa na região do bulbo',
      },
      {
        CODANATOMIA: 19,
        ANATOMIA: 'Presença de placa ulcerada na região do bulbo',
      },
      {
        CODANATOMIA: 20,
        ANATOMIA: 'Presença de KINKING (dobra) no terço proximal',
      },
      {
        CODANATOMIA: 21,
        ANATOMIA: 'Presença de KINKING (dobra) no terço médio',
      },
      {
        CODANATOMIA: 22,
        ANATOMIA: 'Presença de KINKING (dobra) no terço distal',
      },
      {
        CODANATOMIA: 23,
        ANATOMIA: 'Presença de tortuosidades (KINKING) difusas',
      },
      {
        CODANATOMIA: 24,
        ANATOMIA: 'Ausência de fluxo, com obstrução total no terço proximal',
      },
      {
        CODANATOMIA: 25,
        ANATOMIA: 'Ausência de fluxo, com obstrução total no terço médio.',
      },
      {
        CODANATOMIA: 26,
        ANATOMIA: 'Ausência de fluxo, com obstrução total no terço distal',
      },
      {
        CODANATOMIA: 27,
        ANATOMIA: 'Presença de STENT bem posicionado',
      },
      {
        CODANATOMIA: 28,
        ANATOMIA: 'Presença de STENT com estenose residual',
      },
      {
        CODANATOMIA: 29,
        ANATOMIA: 'Não visualizada neste exame.',
      },
      {
        CODANATOMIA: 30,
        ANATOMIA: 'Vide observações.',
      },
      {
        CODANATOMIA: 31,
        ANATOMIA: 'Presença de placas e irregularidades difusas',
      },
      {
        CODANATOMIA: 32,
        ANATOMIA: 'Presença de placas difusas com superfícies irregulares',
      },
      {
        CODANATOMIA: 33,
        ANATOMIA: 'Presença de placa homogênea no terço proximal',
      },
      {
        CODANATOMIA: 34,
        ANATOMIA: 'Presença de placa homogênea no terço médio',
      },
      {
        CODANATOMIA: 35,
        ANATOMIA: 'Presença de placa homogênea no terço distal',
      },
      {
        CODANATOMIA: 36,
        ANATOMIA: 'Presença de placa heterogênea no terço proximal',
      },
      {
        CODANATOMIA: 37,
        ANATOMIA: 'Presença de placa heterogênea no terço médio',
      },
      {
        CODANATOMIA: 38,
        ANATOMIA: 'Presença de placa heterogênea no terço distal',
      },
      {
        CODANATOMIA: 39,
        ANATOMIA:
          'Presença de placa extensa que se prolonga em direção aos ramos',
      },
      {
        CODANATOMIA: 40,
        ANATOMIA:
          'Presença de placa extensa que se prolonga em direção a carótida interna',
      },
      {
        CODANATOMIA: 41,
        ANATOMIA:
          'Presença de placa extensa que se prolonga em direção a carótida externa',
      },
      {
        CODANATOMIA: 42,
        ANATOMIA: 'Não visualizado todos os segmentos e trajeto desta artéria',
      },
      {
        CODANATOMIA: 43,
        ANATOMIA:
          'Presença de pequenas placas sem causar obstrução significativa',
      },
      {
        CODANATOMIA: 44,
        ANATOMIA:
          'Espessura média da camada médio-intimal na parede posterior da artéria carótida comum mede=',
      },
      {
        CODANATOMIA: 45,
        ANATOMIA: 'Placa extensa e homogênea',
      },
    ],
  },
};

const enviarLaudo = async atividade => {
  try {
    if (atividade.GUIA1 !== 'SIM') {
      return toast.warn('Laudo não liberado para envio!');
    }

    const mapaOrHolter = [6, 7].includes(atividade.CODPROCEDI);
    if (mapaOrHolter) return toast.warn('Laudo não disponível para envio!'); // TODO: remover quando mapa e holter estiverem disponíveis

    let phoneNumber = atividade.CELULARPAC;
    if (!phoneNumber) return toast.warn('Paciente sem celular cadastrado!');

    /* eslint-disable no-useless-escape */
    phoneNumber = phoneNumber.replace(/[\(\)-]/g, ''); // Remove special characters from the phone number
    phoneNumber = cleanPhoneNumber(phoneNumber);
    if (!phoneNumber) return toast.warn('Número de celular inválido!');

    const laudoUrl = await generateReportUrl({
      laudoId: atividade.CODLAUDO,
      tipoLaudo: atividade.CODPROCEDI,
    });

    if (atividade.GUIA1 === 'NAO') {
      const fatura = {
        codLaudo: atividade.CODLAUDO,
        codPac: atividade.CODPAC,
      };
      await liberarLaudoByPac({ fatura });
    }

    const shortUrl = nanoid(8);
    const msg = `[ICM Marília]: Olá ${atividade.NOMEPAC}!

Seu laudo está disponível! Clique no link abaixo para acessá-lo.

Se tiver algum problema, não se preocupe! Adicione este número aos seus contatos e poderá acessar facilmente todos os nossos serviços sempre que precisar.

Seu laudo:  ${window.location.origin}/url/${shortUrl}`;

    const response = await api.post('/store-mensagem', {
      recipient: phoneNumber,
      text: msg,
      originator: 'Laudo',
      idRequest: atividade.CODFATURA,
      idPaciente: atividade.CODPAC,
      nomePaciente: atividade.NOMEPAC,
      originalUrl: laudoUrl,
      shortUrl,
    });

    // const response = await api.post('/send/report', { to: phoneNumber, msg });
    toast.success(response.data.message);
  } catch (error) {
    toast.error(error.response.data.error);
  }
};

const enviarAgendamento = async (agenda, msg, cel) => {
  try {
    await api.post('/store-mensagem', {
      recipient: cel,
      text: msg,
      originator: 'Agenda',
      idRequest: agenda.CODAGENDA,
      idPaciente: agenda.codPac,
      nomePaciente: 'Online',
      message: JSON.stringify(agenda),
    });

    // const response = await api.post('/send/report', { to: phoneNumber, msg });
    // toast.success(response.data.message);
  } catch (error) {
    toast.error(error.response.data.error);
  }
};

const listaDeEnvioLaudos = async () => {
  const response = await api.get('/list-mensagem');
  // TODO: ACHO QUE QDO TIVER MUITAS MSG TEREMOS PROBLEMAS NO ENVIO
  // ACREDITO QUE TEREMOS DE USAR Promisse.all() para enviar as mensagens
  // no backend e/ou no frontend acredito

  const mensagensEngajamento = [
    "Olá! Gostaria de receber atualizações sobre nossos serviços via WhatsApp - ICM Marília? 📲 Responda 'SIM' para ativar.",
    "Oi! Podemos te enviar laudos e informações importantes diretamente no WhatsApp - ICM Marília? 📄✅ Responda 'SIM' para ativar.",
    "E aí! Você gostaria de receber lembretes de agendamentos - ICM Marília - e novidades pelo WhatsApp? 📅🔔 Responda 'SIM' para ativar.",
    "Olá! Podemos te enviar informações sobre consultas, exames e novidades - ICM Marília? 🏥📢 Responda 'SIM' para ativar.",
    "Quer receber seu laudo ou agendamento - ICM Marília - de forma rápida e segura pelo WhatsApp? Basta confirmar aqui! 🔍📃 Responda 'SIM'.",
    "Oi! Podemos te avisar sobre novos serviços, horários disponíveis e outras novidades - ICM Marília? 📆🛎️ Responda 'SIM' para ativar.",
    "Gostaria de receber lembretes de agendamentos e exames - ICM Marília, pelo WhatsApp? Responda 'SIM' para ativar! ✅",
    "Podemos enviar seus exames/agendamentos - ICM Marília - diretamente no WhatsApp? 🤝📩 Responda 'SIM' para ativar.",
    "Oi! Você prefere receber agendamentos e laudos médicos por aqui - ICM Marília? Só responder 'SIM'! 💬🩺",
    "Gostaria de ser avisado sobre laudos prontos e consultas agendadas - ICM Marília - pelo WhatsApp? 📍📜 Responda 'SIM' para ativar.",
    "Olá! Podemos te manter informado sobre seus exames e agendamentos pelo WhatsApp - ICM Marília? 🔔 Responda 'SIM' para ativar.",
    "Oi! Para continuar recebendo nossas atualizações - ICM Marília, responda: ✅ 'SIM' para ativar.",
    "📢 Você quer receber lembretes automáticos dos seus agendamentos e laudos - ICM Marília? Digite 'SIM' para ativar.",
    "🔍 Podemos enviar notificações sobre seus exames/agendamentos - ICM Marília - pelo WhatsApp? Digite 'SIM' para confirmar.",
  ];

  const mensagensAgendamento = [
    '[ICM Marília]: Olá {NOME},\n\nSeu atendimento está confirmado!\n📌 **Protocolo:** {PROTOCOLO}\n👨‍⚕️ **Médico:** {MEDICO}\n🏥 **Convênio:** {CONVENIO}\n📅 **Data:** {DATA} às {HORA}\n📍 **Local:** {LOCAL}\n📌 **Endereço:** {ENDERECO}\n🕒 Chegue com **15 minutos de antecedência**.\n\n📆 Para novos agendamentos: {LINK_AGENDAMENTO}\nℹ️ Mais informações: {LINK_INFO}',

    '[ICM Marília]: Olá {NOME},\n\nLembrete de consulta:\n✅ **Protocolo:** {PROTOCOLO}\n👨‍⚕️ **Médico:** {MEDICO}\n🏥 **Convênio:** {CONVENIO}\n📅 **Data:** {DATA} às {HORA}\n📌 **Endereço:** {ENDERECO}\n⏳ **Chegue 15 minutos antes.**\n\n🔗 Novos agendamentos: {LINK_AGENDAMENTO}\n🔍 Saiba mais: {LINK_INFO}',

    '[ICM Marília]: Olá {NOME},\n\nSua consulta está marcada!\n\n📍 **Local:** {LOCAL}\n📅 **Data:** {DATA} às {HORA}\n👨‍⚕️ **Médico:** {MEDICO}\n🏥 **Convênio:** {CONVENIO}\n📌 **Protocolo:** {PROTOCOLO}\n📌 **Endereço:** {ENDERECO}\n🕒 **Chegue com 15 minutos de antecedência.**\n\n📆 Agende novas consultas: {LINK_AGENDAMENTO}\nℹ️ Detalhes: {LINK_INFO}',

    '[ICM Marília]: Oi {NOME},\n\n📢 **Lembrete de consulta:**\n📅 **Data:** {DATA} às {HORA}\n👨‍⚕️ **Médico:** {MEDICO}\n🏥 **Convênio:** {CONVENIO}\n📍 **Endereço:** {ENDERECO}\n🔹 **Protocolo:** {PROTOCOLO}\n🕒 Chegue com **15 minutos de antecedência**.\n\n📆 Agende novas consultas: {LINK_AGENDAMENTO}\nℹ️ Mais informações: {LINK_INFO}',

    '[ICM Marília]: Olá {NOME},\n\n📆 Seu agendamento está confirmado:\n✅ **Médico:** {MEDICO}\n✅ **Data:** {DATA} às {HORA}\n✅ **Local:** {LOCAL}\n✅ **Endereço:** {ENDERECO}\n✅ **Protocolo:** {PROTOCOLO}\n\n⏳ Chegue **15 minutos antes**.\n\n📌 Novos agendamentos: {LINK_AGENDAMENTO}\n🔗 Saiba mais: {LINK_INFO}',

    '[ICM Marília]: Oi {NOME}, sua consulta está confirmada! 🏥\n📅 **Quando:** {DATA} às {HORA}\n👨‍⚕️ **Com:** {MEDICO}\n📍 **Onde:** {LOCAL}\n📌 **Endereço:** {ENDERECO}\n🕒 **Chegue com 15 min de antecedência**.\n\n📆 Marque outra consulta: {LINK_AGENDAMENTO}\nℹ️ Detalhes: {LINK_INFO}',

    '[ICM Marília]: Olá {NOME},\n\nSeu atendimento foi confirmado! 🩺\n📅 **Data:** {DATA} às {HORA}\n👨‍⚕️ **Médico:** {MEDICO}\n🏥 **Convênio:** {CONVENIO}\n📍 **Local:** {LOCAL}\n📌 **Endereço:** {ENDERECO}\n🔹 **Protocolo:** {PROTOCOLO}\n⏳ Chegue com **15 minutos de antecedência**.\n\n🔗 **Novos agendamentos:** {LINK_AGENDAMENTO}\nℹ️ **Mais informações:** {LINK_INFO}',
  ];

  /*
    [ICM Marília]: Olá JOAO LUIS CARDOSO DE MORAES,
📌 Protocolo: ICM-1743171204963
👨‍⚕️ Médico: Dr João
🏥 Convênio: UNIMED MARÍLIA
📅 Data: 22/04/2025 às ⏰ 15:00.
📍 Local: Instituto do Coração de Marília - ICM.
📍 Endereço: Av. Vicente Ferreira 780 – ao lado do P.S. da Santa Casa.
⚠️ Chegar com 15 minutos de antecedência.
🔗 Mais informações: www.icm.com.br
    const encoded = msg.text
      .replace(/📌/g, '%F0%9F%93%8C')
      .replace(/👨‍⚕️/g, '%F0%9F%91%A8%E2%80%8D%F0%9F%92%95')
      .replace(/🏥/g, '%F0%9F%8F%A5')
      .replace(/📅/g, '%F0%9F%93%85')
      .replace(/⏰/g, '%F0%9F%95%B0')
      .replace(/📍/g, '%F0%9F%93%8D')
      .replace(/⚠️/g, '%E2%9A%A0%EF%B8%8F')
      .replace(/🔗/g, '%F0%9F%94%97');

  let link = `https://wa.me/${msg.recipient}?text=${encoded}`;
  console.log(`Abrindo WhatsApp para enviar mensagem: ${encoded}`);
  */
  for (let msg of response.data) {
    const mensagemAleatoria =
      mensagensEngajamento[
        Math.floor(Math.random() * mensagensEngajamento.length)
      ];

    console.log('Mensagem aleatória:', mensagemAleatoria);
    /* let link = `https://wa.me/${msg.recipient}?text=${encodeURIComponent(
      mensagemAleatoria
    )}`;
    window.open(link, '_blank');
    console.log(`Abrindo WhatsApp para enviar mensagem: ${mensagemAleatoria}`);
    await new Promise(resolve =>
      setTimeout(
        resolve,
        Math.floor(Math.random() * (90000 - 45000 + 1)) + 45000
      )
    );    console.log(
      JSON.parse(
        '{"CODAGENDA":1715677,"CODMED":2796,"CODPAC":1,"CODCONV":1000,"CODPROCEDI":1000,"DATAAGENDA":"2025-04-29T03:00:00.000Z","HORARIO":1500,"OBSERVACAO":"","SITUACAO":"Aberta","HORAAGENDA":"2025-02-19T18:52:00.000Z","ATIVIDADE":"CONSULTA","STATUSCONFIRMA":null,"NOME":"NULO","codPac":495026,"situacao":"Agendada","codConv":14,"codProcedi":1,"atividade":"CONSULTA","protocolo":"ICM-1743609996975"}'
      )
    ); */
    console.log('Mensagem:', msg);

    let link = `https://wa.me/${msg.recipient}?text=${encodeURIComponent(
      msg.text
    )}`;
    window.open(link, '_blank');
  }

  toast.success('Total de mensagens enviadas: ' + response?.data?.length);
};

const generateReportUrl = async ({ laudoId, tipoLaudo }) => {
  try {
    const baseUrl = window.location.origin;
    let urlLaudo;

    switch (tipoLaudo) {
      case 1:
      case 58:
      case 800:
        return 'prontuario';
      case 3: // ECG
        const res = await api.post('/report/token', { laudoId, tipoLaudo });
        urlLaudo = `${baseUrl}/laudo/ecg/${res?.data?.token}`;
        break;
      case 13: // STRESS
        const responseStress = await api.post('/report/token', {
          laudoId,
          tipoLaudo,
        });
        urlLaudo = `${baseUrl}/laudo/stress/${responseStress?.data?.token}`;
        break;
      case 12: // TRANS
        const responseTrans = await api.post('/report/token', {
          laudoId,
          tipoLaudo,
        });
        urlLaudo = `${baseUrl}/laudo/trans/${responseTrans?.data?.token}`;
        break;
      case 4: // ERGO
        const responseErgo = await api.post('/report/token', {
          laudoId,
          tipoLaudo,
        });
        urlLaudo = `${baseUrl}/laudo/ergo/${responseErgo?.data?.token}`;
        break;
      case 11:
      case 712:
      case 713:
      case 714: // DOPPLER
        const eco = await store.dispatch(selectEcoById(laudoId));
        if (eco.TIPOCONGENITO !== null) {
          const responseEco = await api.post('/report/token', {
            laudoId,
            tipoLaudo,
          });
          urlLaudo = `${baseUrl}/laudo/congenitotxt/${responseEco?.data?.token}`;
        } else if (eco.TIPOECO === 'EcoC') {
          const responseEco = await api.post('/report/token', {
            laudoId,
            tipoLaudo,
          });
          urlLaudo = `${baseUrl}/laudo/congenito/${responseEco?.data?.token}`;
        } else {
          const responseEco = await api.post('/report/token', {
            laudoId,
            tipoLaudo,
          });
          urlLaudo = `${baseUrl}/laudo/doppler/${responseEco?.data?.token}`;
        }
        break;
      case 14:
      case 15: // CAROTIDA
        const responseCarotidas = await api.post('/report/token', {
          laudoId,
          tipoLaudo,
        });
        urlLaudo = `${baseUrl}/laudo/carotida/${responseCarotidas?.data?.token}`;
        break;
      case 17: // FETAL
        const responseFetal = await api.post('/report/token', {
          laudoId,
          tipoLaudo,
        });
        urlLaudo = `${baseUrl}/laudo/fetal/${responseFetal?.data?.token}`;
        break;
      default:
        return toast.warn('Laudo não disponível!');
    }

    return urlLaudo;
  } catch (error) {
    return error;
  }
};

const TELEGRAM_BOT_TOKEN = '7676441315:AAER1YLWOUASJ1qMXh59V-T39pwE_Qcfpho';

const CHAT_ID = '-4729648546'; // id grupo Moraestech

const sendTelegramMessage = async message => {
  const url = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`;

  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      chat_id: CHAT_ID,
      text: message,
    }),
  });

  return response.json();
};

export {
  enviarLaudo,
  enviarAgendamento,
  generateReportUrl,
  listaDeEnvioLaudos,
  sendTelegramMessage,
  dblaudo,
};
