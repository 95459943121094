import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Button, Card } from 'react-bootstrap';
import esptrans from '../../../assets/esp-trans.png';
import styles from '../../../styles/styles.module.css';

export default function EspelhoTrans() {
  const { trans } = useSelector(state => state.trans);

  return (
    <Card>
      <Card.Header className="text-center">
        <Card.Title className={styles.titleReport}>
          LAUDO DE ECO TRANSESOFÁGICO
        </Card.Title>
      </Card.Header>

      <Card.Body>
        <Form noValidate className={styles.contentReport}>
          <strong className="mr-auto">Cod Trans: </strong>
          {trans[0]?.CODTRANS}&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Nome do Paciente: </strong>
          {`${trans[0]?.CODPAC} - ${trans[0]?.NOMEPAC}`}
          <br></br>
          <strong className="mr-auto">Data de Nasc: </strong>
          {`${trans[0]?.dataNasc}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Sexo: </strong>
          {`${trans[0]?.SEXOPAC}`}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Idade: </strong>
          {`${trans[0]?.idadePAC} anos`}
          <br></br>
          <strong className="mr-auto">Convênio: </strong>
          {trans[0]?.SIGLACONV}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Data do Exame: </strong>
          {trans[0]?.dataTrans}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Fone: </strong>
          {trans[0]?.CELULARPAC}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <br></br>
          <strong className="mr-auto">Médico que Realizou: Dr. </strong>
          {`${trans[0]?.Realizou}`}
          <br></br>
          <strong className="mr-auto">Médico que Solicitou: Dr. </strong>
          {`${trans[0]?.Solicitou}`}
          <hr></hr>
          <div className={styles.imageReport}>
            <img
              width="100%"
              height="130%"
              loading="lazy"
              src={esptrans}
              alt="espelho trans"
            />{' '}
          </div>
          <div id="removeMe" className="text-right">
            <Button variant="outline-primary" onClick={() => window.print()}>
              Imprimir
            </Button>{' '}
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
}
