import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Card, Button } from 'react-bootstrap';
import { signInRequest } from '../../../store/modules/auth/patient/actions';
import history from '../../../services/history';
import logo from '../../../assets/logo.png';

export default function SignInPac() {
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    const cpf = event.currentTarget.elements.formBasicCPF.value;
    const senha = event.currentTarget.elements.formBasicSenha.value;
    dispatch(signInRequest(cpf, senha));
    setValidated(true);
  }

  return (
    <div className="row justify-content-md-center p-5">
      <Card>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="text-center">
              <img src={logo} alt="logo" width={50} height={50} />
            </div>
            <Form.Group controlId="formBasicCPF">
              <Form.Label>CPF do Paciente</Form.Label>
              <Form.Control required type="text" placeholder="Seu CPF" />
              <Form.Control.Feedback type="invalid">
                Digite seu CPF.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formBasicSenha">
              <Form.Label>Senha</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Sua senha secreta"
              />
              <Form.Control.Feedback type="invalid">
                Digite sua senha.
              </Form.Control.Feedback>
            </Form.Group>
            <Button block type="submit">
              Entrar
            </Button>
            <Button
              variant="warning"
              block
              onClick={() => history.push('/perfilpaciente')}
            >
              Primeiro acesso/Esqueci minha senha
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}
