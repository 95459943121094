import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import md5 from 'md5';
import {
  Form,
  Button,
  Card,
  Spinner,
  Container,
  Alert,
  Col,
  Table,
} from 'react-bootstrap';
import { addDays, parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import api from '../../../services/api';
import header from '../../../assets/icm-header.jpg';
import styles from '../../../styles/styles.module.css';
import getPrefix from '../../../util/getPrefix';

export default function PublicErgo() {
  const { token } = useParams();
  const [ergo, setErgo] = useState(null);
  const [error, setError] = useState(null);
  const pageBreakStyles = { break: { pageBreakAfter: 'always' } };
  let contador = 0;

  useEffect(() => {
    const fetchLaudoData = async () => {
      try {
        const response = await api.get(`/report/${token}`);
        const ergo = {
          ...response.data.laudo,
          dataErgo:
            response.data.laudo.DATAERGO &&
            format(
              addDays(parseISO(response.data.laudo.DATAERGO), 1),
              'dd/MM/yyyy',
              {
                locale: pt,
              }
            ),
          dataNasc: format(
            addDays(parseISO(response.data.laudo.DTNPAC), 1),
            'dd/MM/yyyy',
            {
              locale: pt,
            }
          ),
          idadePAC: Math.floor(
            (parseISO(response.data.laudo.DATAERGO) -
              parseISO(response.data.laudo.DTNPAC)) /
              (1000 * 3600 * 24) /
              365.25
          ),
        };
        setErgo(ergo);
      } catch (error) {
        setError(error.response.data.error);
      }
    };

    fetchLaudoData();
  }, [token]);

  function removeDiv(divName) {
    document.getElementById(divName).style.visibility = 'hidden';
    window.scrollTo(0, 0); // Scroll to the top of the page
    window.print();
    document.getElementById(divName).style.visibility = 'visible';
  }

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  if (!ergo) {
    return (
      <Container className="mt-5 text-center">
        <Spinner animation="border" role="status" />
      </Container>
    );
  }

  return (
    <div>
      <div className={styles.imageReport}>
        <img width="550" height="100" src={header} alt="header" />
      </div>

      <Card.Header className="text-center">
        <Card.Title className={styles.titleReport}>
          LAUDO DE TESTE ERGOMÉTRICO
        </Card.Title>
      </Card.Header>

      <Card.Body>
        <Form noValidate className={styles.contentReport}>
          <strong className="mr-auto">Nome do Paciente: </strong>
          {`${ergo?.CODPAC} - ${ergo?.NOMEPAC}`}
          <br></br>
          <strong className="mr-auto">Data de Nasc: </strong>
          {`${ergo?.dataNasc}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Sexo: </strong>
          {`${ergo?.SEXOPAC}`}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Idade: </strong>
          {`${ergo?.idadePAC} anos`}
          <br></br>
          <strong className="mr-auto">Convênio: </strong>
          {ergo?.SIGLACONV}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Cod Ergo: </strong>
          {ergo?.CODEXAME}&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Data do Exame: </strong>
          {ergo?.dataErgo}
          <br></br>
          <strong className="mr-auto">
            Médico que Realizou: {getPrefix(ergo?.CODREALIZOU)}{' '}
          </strong>
          {`${ergo?.Realizou}`}
          <br></br>
          <strong className="mr-auto">
            Médico que Solicitou: {getPrefix(ergo?.CODSOLICITANTE)}{' '}
          </strong>
          {`${ergo?.Solicitou}`}
          <hr></hr>
          <Form.Row>
            <Form.Group as={Col} sm controlId="editNOMEMED">
              <div className="row">
                <div className="col-12">
                  <strong className="mr-auto">Indicações do Teste</strong>
                </div>
              </div>
              {ergo?.indicacoes?.map(indica => (
                <Form.Text key={indica.DESCRICAO} className="text-muted">
                  <div key={contador++} className="row">
                    <div className="col-12"> {indica.DESCRICAO}</div>
                  </div>
                </Form.Text>
              ))}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm controlId="editNOMEMED">
              <div className="row">
                <div className="col-12">
                  <strong className="mr-auto">Queixas</strong>
                </div>
              </div>
              {ergo?.queixas?.map(queixa => (
                <Form.Text key={queixa.FRASE} className="text-muted">
                  <div key={contador++} className="row">
                    <div className="col-12"> {queixa.FRASE}</div>
                  </div>
                </Form.Text>
              ))}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm controlId="editNOMEMED">
              <div className="row">
                <div className="col-12">
                  <strong className="mr-auto">Medicamentos</strong>
                </div>
              </div>
              {ergo?.medicamentos?.map(medicto => (
                <Form.Text key={medicto.FRASE} className="text-muted">
                  <div key={contador++} className="row">
                    <div className="col-12"> {medicto.FRASE}</div>
                  </div>
                </Form.Text>
              ))}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm controlId="editNOMEMED">
              <div className="row">
                <div className="col-12">
                  <strong className="mr-auto">Fatores de Risco</strong>
                </div>
              </div>
              {ergo?.fatoresRisco?.map(fator => (
                <Form.Text key={fator.DESCRICAO} className="text-muted">
                  <div key={contador++} className="row">
                    <div className="col-12"> {fator.DESCRICAO}</div>
                  </div>
                </Form.Text>
              ))}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm controlId="editNOMEMED">
              <div className="row">
                <div className="col-12">
                  <strong className="mr-auto">Atividade Física</strong>
                </div>
              </div>
              {ergo?.atividadesFisica?.map(atv => (
                <Form.Text key={atv.DESCRICAO} className="text-muted">
                  <div key={contador++} className="row">
                    <div className="col-12"> {atv.DESCRICAO}</div>
                  </div>
                </Form.Text>
              ))}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm controlId="editNOMEMED">
              <div className="row">
                <div className="col-12">
                  <strong className="mr-auto">Exame Físico</strong>
                </div>
              </div>
              <Form.Text className="text-muted">
                <div key={contador++} className="row">
                  <div className="col-12"> {ergo?.EXAMEFISICO}</div>
                </div>
              </Form.Text>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm controlId="editNOMEMED">
              <div className="row">
                <div className="col-12">
                  <strong className="mr-auto">Eletrocardiograma Basal</strong>
                </div>
              </div>
              {ergo?.ecgBasal?.map(ecg => (
                <Form.Text key={ecg.FRASE} className="text-muted">
                  <div key={contador++} className="row">
                    <div className="col-12"> {ecg.FRASE}</div>
                  </div>
                </Form.Text>
              ))}
            </Form.Group>
          </Form.Row>
          <section style={pageBreakStyles.break}></section>
          <div className={styles.imageReport}>
            <img width="550" height="100" src={header} alt="header" />
          </div>
          <div>
            <div className="row">
              <div className="col-12">
                <strong className="mr-auto">
                  Frequência Cardíaca Prevista
                </strong>
              </div>
            </div>
            <Table bordered hover size="sm">
              <tbody>
                <tr>
                  <td>Máxima:</td>
                  <td>{ergo?.FREQMAX} bpm</td>
                  <td>Submáxima:</td>
                  <td>{ergo?.FREQSUB} bpm</td>
                </tr>
                <tr>
                  <td>Dietado (FC):</td>
                  <td>{ergo?.FCDEITADO} bpm</td>
                  <td>PA:</td>
                  <td>{ergo?.PADEITADO} mmHg</td>
                </tr>
                <tr>
                  <td>Em Pé (FC):</td>
                  <td>{ergo?.FCPE} bpm</td>
                  <td>PA:</td>
                  <td>{ergo?.PAPE} mmHg</td>
                </tr>
              </tbody>
            </Table>
          </div>
          <br />
          <Form.Row>
            <Col sm>
              <Form.Group size="sm" controlId="obs">
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">
                      Protocolo de {ergo?.PROTOCOLO}
                    </strong>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">Estágio</strong>
                  </div>
                </div>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>Estágio</th>
                      <th>Tempo(min)</th>
                      <th>Vel(mph)</th>
                      <th>Inc(%)</th>
                      <th>FC(bpm)</th>
                      <th>PA(mmHg)</th>
                      <th>Sinais/Sintomas</th>
                      <th>Arritmias</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ergo?.estagios?.map(estagio => (
                      <tr key={estagio.CODESTAGIO}>
                        <td>
                          <div key={contador++} className="row">
                            <div className="col-12"> {estagio.CODESTAGIO}</div>
                          </div>
                        </td>
                        <td>{estagio.TEMPO}</td>
                        <td>{estagio.VELOCIDADE}</td>
                        <td>{estagio.INCLINACAO}</td>
                        <td>{estagio.FC}</td>
                        <td>{estagio.PA}</td>
                        <td>{estagio.Sintomas}</td>
                        <td>{estagio.Arritmias}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <br />
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">Recuperação</strong>
                  </div>
                </div>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>Recuperação</th>
                      <th>Tempo(min)</th>
                      <th>FC(bpm)</th>
                      <th>PA(mmHg)</th>
                      <th>Sinais/Sintomas</th>
                      <th>Arritmias</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ergo?.recuperacoes?.map(recup => (
                      <tr key={recup.CODRECUPERACAO}>
                        <td>
                          <div key={contador++} className="row">
                            <div className="col-12">
                              {' '}
                              {recup.CODRECUPERACAO}
                            </div>
                          </div>
                        </td>
                        <td>{recup.TEMPO}</td>
                        <td>{recup.FC}</td>
                        <td>{recup.PA}</td>
                        <td>{recup.Sintomas}</td>
                        <td>{recup.Arritmias}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Form.Group>
            </Col>
          </Form.Row>
          <section style={pageBreakStyles.break}></section>
          <div className={styles.imageReport}>
            <img width="550" height="100" src={header} alt="header" />
          </div>
          {ergo?.altECG && (
            <Form.Row>
              <Form.Group as={Col} sm controlId="editNOMEMED">
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">
                      Alterações Eletrocardiográficas
                    </strong>
                  </div>
                </div>
                {ergo?.altECG?.map(segST => (
                  <Form.Text key={segST.DESCRICAO} className="text-muted">
                    <div key={contador++} className="row">
                      <div className="col-12"> {segST.DESCRICAO}</div>
                    </div>
                  </Form.Text>
                ))}
              </Form.Group>
            </Form.Row>
          )}
          <Form.Row>
            <Form.Group as={Col} sm controlId="editNOMEMED">
              <div className="row">
                <div className="col-12">
                  <strong className="mr-auto">Arritmias</strong>
                </div>
              </div>
              {ergo?.arritmias?.map(arr => (
                <Form.Text key={arr.DESCRICAO} className="text-muted">
                  <div key={contador++} className="row">
                    <div className="col-12"> {arr.DESCRICAO}</div>
                  </div>
                </Form.Text>
              ))}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm controlId="editNOMEMED">
              <div className="row">
                <div className="col-12">
                  <strong className="mr-auto">Comportamento da PA</strong>
                </div>
              </div>
              {ergo?.comportamentoPA?.map(compPA => (
                <Form.Text key={compPA.DESCRICAO} className="text-muted">
                  <div key={contador++} className="row">
                    <div className="col-12"> {compPA.DESCRICAO}</div>
                  </div>
                </Form.Text>
              ))}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm controlId="editNOMEMED">
              <div className="row">
                <div className="col-12">
                  <strong className="mr-auto">Comentários</strong>
                </div>
              </div>
              {ergo?.comentarios?.map(comentario => (
                <Form.Text key={comentario.DESCRICAO} className="text-muted">
                  <div key={contador++} className="row">
                    <div className="col-12"> {comentario.DESCRICAO}</div>
                  </div>
                </Form.Text>
              ))}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm controlId="editNOMEMED">
              <div className="row">
                <div className="col-12">
                  <strong className="mr-auto">Conclusões</strong>
                </div>
              </div>
              {ergo?.conclusoes?.map(conclusao => (
                <Form.Text key={conclusao.DESCRICAO} className="text-muted">
                  <div key={contador++} className="row">
                    <div className="col-12"> {conclusao.DESCRICAO}</div>
                  </div>
                </Form.Text>
              ))}
            </Form.Group>
          </Form.Row>
          <div>
            <Card className="mt-2 text-center">
              <Card.Header>
                <Card.Text>
                  "O Resultado do presente laudo não configura necessariamente a
                  presença ou ausência de doença, devendo obrigatoriamente ser
                  correlacionado com demais dados clínicos e exames
                  complementares pertinentes ao caso."
                </Card.Text>
              </Card.Header>
              <Card.Body>
                <div className={styles.messageReport}>
                  Imagens disponíveis no site:{' '}
                  <strong>www.icm.com.br (Resultado de Exames)</strong>
                  {/* TEMPORÁRIO */}
                  <br />
                  <span>Código de verificação: {ergo?.CODPAC}</span>
                  <hr></hr>
                </div>{' '}
                <div className={styles.signatureReport}>
                  <i className="mr-auto">
                    Conferido e assinado eletronicamente por:{' '}
                  </i>
                  <strong className="mr-auto">
                    {' '}
                    {getPrefix(ergo?.CODREALIZOU)} {ergo?.Realizou}
                  </strong>
                  <br></br>
                  <i className="mr-auto">
                    CRM: {ergo?.CRMMED} - RQE: {ergo?.RQE}
                  </i>
                  <br></br>
                  <i className="mr-auto">
                    Assinatura Digital: {md5(JSON.stringify(ergo))}
                  </i>
                </div>
              </Card.Body>

              <Card.Footer id="removeMe">
                <Button onClick={() => removeDiv('removeMe')}>Imprimir</Button>
              </Card.Footer>
            </Card>
          </div>
        </Form>
      </Card.Body>
    </div>
  );
}
