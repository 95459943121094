import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import md5 from 'md5';
import {
  Form,
  Button,
  Card,
  Spinner,
  Container,
  Alert,
  Col,
} from 'react-bootstrap';
import api from '../../../services/api';
import header from '../../../assets/icm-header.jpg';
import styles from '../../../styles/styles.module.css';
import getPrefix from '../../../util/getPrefix';

export default function PublicCarotida() {
  const { token } = useParams();
  const [carotida, setCarotida] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLaudoData = async () => {
      try {
        const response = await api.get(`/report/${token}`);
        setCarotida(response.data.laudo);
      } catch (error) {
        setError(error.response.data.error);
      }
    };

    fetchLaudoData();
  }, [token]);

  function removeDiv(divName) {
    document.getElementById(divName).style.visibility = 'hidden';
    window.scrollTo(0, 0); // Scroll to the top of the page
    window.print();
    document.getElementById(divName).style.visibility = 'visible';
  }

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  if (!carotida) {
    return (
      <Container className="mt-5 text-center">
        <Spinner animation="border" role="status" />
      </Container>
    );
  }

  return (
    <Card style={{ marginTop: 10 }}>
      <div className={styles.imageReport}>
        <img width="600" height="110" src={header} alt="header" />
      </div>

      <Card.Header className="text-center">
        <h3 style={{ fontWeight: 'bold' }}>LAUDO DE SCAN DE CARÓTIDAS</h3>
      </Card.Header>

      <Card.Body>
        <Form noValidate className={styles.contentReport}>
          <strong className="mr-auto">Nome do Paciente: </strong>
          {`${carotida?.carotida[0]?.CODPAC} - ${carotida?.carotida[0]?.NOMEPAC}`}
          <br></br>
          <strong className="mr-auto">Data de Nasc: </strong>
          {`${carotida?.carotida[0]?.dataNasc}`}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Sexo: </strong>
          {`${carotida?.carotida[0]?.SEXOPAC}`}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Idade: </strong>
          {`${carotida?.carotida[0]?.idadePAC} anos`}
          <br></br>
          <strong className="mr-auto">Convênio: </strong>
          {carotida?.carotida[0]?.SIGLACONV}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Código: </strong>
          {carotida?.carotida[0]?.CODSCAN}&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Data do Laudo: </strong>
          {carotida?.carotida[0]?.dataScanFmt}
          <br></br>
          <strong className="mr-auto">
            Médico que Realizou: {getPrefix(carotida?.CODREALIZOU)}{' '}
          </strong>
          {`${carotida?.carotida[0]?.Realizou}`}
          <br></br>
          <strong className="mr-auto">
            Médico que Solicitou: {getPrefix(carotida?.CODSOLICITANTE)}{' '}
          </strong>
          {`${carotida?.carotida[0]?.Solicitante}`}
          <hr></hr>
          <Card.Header className="text-center">
            <Card.Title>
              <strong>
                <u>SISTEMA CAROTÍDEO DIREITO</u>
              </strong>
            </Card.Title>
          </Card.Header>
          <Form.Row>
            <Col sm>
              <Form.Group size="sm">
                {carotida?.ccd?.length > 0 && (
                  <>
                    <u className="mr-auto">Carótida Comum Direita: </u>
                    {carotida?.ccd?.map(ccdir =>
                      ccdir.CODANATOMIA === 44 ? (
                        <div className="row">
                          <div className="col-12">
                            {ccdir.ANATOMIA} {ccdir.MEDIDA} mm
                            {ccdir.VELOCIDADE} {ccdir.INTENSIDADE}
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-12">
                            {ccdir.ANATOMIA} {ccdir.VELOCIDADE}{' '}
                            {ccdir.INTENSIDADE}
                          </div>
                        </div>
                      )
                    )}
                  </>
                )}
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col sm>
              <Form.Group size="sm">
                {carotida?.cid?.length > 0 && (
                  <>
                    <u className="mr-auto">Carótida Interna Direita: </u>
                    {carotida?.cid.map(cidir => (
                      <div className="row">
                        <div className="col-12">
                          {cidir.ANATOMIA} {cidir.VELOCIDADE}{' '}
                          {cidir.INTENSIDADE}
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col sm>
              <Form.Group size="sm">
                {carotida?.ced?.length > 0 ? (
                  <>
                    <u className="mr-auto">Carótida Externa Direita: </u>
                    {carotida?.ced.map(cedir => (
                      <div className="row">
                        <div className="col-12">
                          {cedir.ANATOMIA} {cedir.VELOCIDADE}{' '}
                          {cedir.INTENSIDADE}
                        </div>
                      </div>
                    ))}
                  </>
                ) : null}
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col sm>
              <Form.Group size="sm">
                {carotida?.avd?.length > 0 && (
                  <>
                    <u className="mr-auto">Artéria Vertebral Direita: </u>
                    {carotida?.avd.map(avdir => (
                      <div className="row">
                        <div className="col-12">
                          {avdir.ANATOMIA} {avdir.VELOCIDADE}{' '}
                          {avdir.INTENSIDADE}
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </Form.Group>
            </Col>
          </Form.Row>
          <Card.Header className="text-center">
            <Card.Title>
              <strong>
                <u>SISTEMA CAROTÍDEO ESQUERDO</u>
              </strong>
            </Card.Title>
          </Card.Header>
          <Form.Row>
            <Col sm>
              <Form.Group size="sm">
                {carotida?.cce?.length > 0 ? (
                  <>
                    <u className="mr-auto">Carótida Comum Esquerda: </u>
                    {carotida?.cce.map(ccesq =>
                      ccesq.CODANATOMIA === 44 ? (
                        <div className="row">
                          <div className="col-12">
                            {ccesq.ANATOMIA} {ccesq.MEDIDA} mm
                            {ccesq.VELOCIDADE} {ccesq.INTENSIDADE}
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-12">
                            {ccesq.ANATOMIA} {ccesq.VELOCIDADE}{' '}
                            {ccesq.INTENSIDADE}
                          </div>
                        </div>
                      )
                    )}
                  </>
                ) : null}
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col sm>
              <Form.Group size="sm">
                {carotida?.cie?.length > 0 ? (
                  <>
                    <u className="mr-auto">Carótida Interna Esquerda: </u>
                    {carotida?.cie.map(ciesq => (
                      <div className="row">
                        <div className="col-12">
                          {ciesq.ANATOMIA} {ciesq.VELOCIDADE}{' '}
                          {ciesq.INTENSIDADE}
                        </div>
                      </div>
                    ))}
                  </>
                ) : null}
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col sm>
              <Form.Group size="sm">
                {carotida?.cee?.length > 0 ? (
                  <>
                    <u className="mr-auto">Carótida Externa Esquerda: </u>
                    {carotida?.cee.map(ceesq => (
                      <div className="row">
                        <div className="col-12">
                          {ceesq.ANATOMIA} {ceesq.VELOCIDADE}{' '}
                          {ceesq.INTENSIDADE}
                        </div>
                      </div>
                    ))}
                  </>
                ) : null}
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col sm>
              <Form.Group size="sm">
                {carotida?.ave?.length > 0 ? (
                  <>
                    <u className="mr-auto">Artéria Vertebral Esquerda: </u>
                    {carotida?.ave.map(avesq => (
                      <div className="row">
                        <div className="col-12">
                          {avesq.ANATOMIA} {avesq.VELOCIDADE}{' '}
                          {avesq.INTENSIDADE}
                        </div>
                      </div>
                    ))}
                  </>
                ) : null}
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col sm>
              <Form.Group size="sm">
                <strong className="mr-auto">Observações</strong>
                <div className="row">
                  <div className="col-12">
                    {carotida?.carotida[0]?.OBSERVACOES &&
                      carotida?.carotida[0]?.OBSERVACOES.split('\n').map(
                        (item, i) => {
                          return <div key={i}>{item}</div>;
                        }
                      )}
                  </div>
                </div>
              </Form.Group>
            </Col>
          </Form.Row>
          <Card className="mt-3 text-center">
            <Card.Body>
              <div className={styles.messageReport}>
                Imagens disponíveis no site:{' '}
                <strong>www.icm.com.br (Resultado de Exames)</strong>
                {/* TEMPORÁRIO */}
                <br />
                <span>
                  Código de verificação: {carotida?.carotida[0]?.CODPAC}
                </span>
                <hr></hr>
              </div>

              <div className={styles.signatureReport}>
                <i className="mr-auto">
                  Conferido e assinado eletronicamente por:{' '}
                </i>
                <strong className="mr-auto">
                  {getPrefix(carotida?.CODREALIZOU)}{' '}
                  {carotida?.carotida[0]?.Realizou}
                </strong>
                <br></br>
                <i className="mr-auto">
                  Assinatura Digital:{' '}
                  {md5(
                    JSON.stringify(carotida?.carotida[0]?.OBSERVACOES || '')
                  )}
                </i>
              </div>
            </Card.Body>
          </Card>
        </Form>
      </Card.Body>

      <Card.Footer id="removeFooter" className="mt-3 text-center">
        <Button onClick={() => removeDiv('removeFooter')}>Imprimir</Button>{' '}
      </Card.Footer>
    </Card>
  );
}
