import { toast } from 'react-toastify';

const cleanPhoneNumber = phoneNumber => {
  // Remove all non-digit characters
  let cleaned = phoneNumber.replace(/\D/g, '');

  if (cleaned.startsWith('0')) cleaned = cleaned.substring(1);

  if (cleaned.length === 10 || cleaned.length === 11) {
    return `+55${cleaned}`;
  } else {
    return null;
  }
};

const getFolderName = a => {
  switch (a.CODPROCEDI) {
    case 1:
    case 58:
    case 800:
      return 'prontuario';
    case 3:
      return 'ecg';
    case 13:
      return 'stress';
    case 12:
      return 'trans';
    case 4:
      return 'ergo';
    case 11:
    case 712:
    case 713:
    case 714:
      return 'eco';
    case 14:
    case 15:
      return 'carotida';
    case 17:
      return 'fetal';
    default:
      return toast.warn('Laudo não disponível para Visualização!');
  }
};

const changeColor = agenda => {
  switch (agenda.SITUACAO) {
    case 'Solicitado':
      return { fontWeight: 'bold', backgroundColor: '#00FF00' };
    case 'Autorizado':
      return { fontWeight: 'bold', backgroundColor: '#FFFF00' };
    case 'Marcada':
      return { color: 'black', fontWeight: 'bold', backgroundColor: 'white' };
    case 'Aberta':
      return { color: 'black', backgroundColor: 'white' };
    case 'Atendido':
      return { color: 'blue', fontWeight: 'bold', backgroundColor: 'white' };
    case 'Faltou':
      return {
        textDecoration: 'line-through',
        color: 'green',
        fontWeight: 'bold',
        backgroundColor: 'white',
      };
    case 'Cancelado':
      return {
        textDecoration: 'line-through',
        color: 'red',
        fontWeight: 'bold',
        backgroundColor: 'white',
      };
    case 'Em Atendimento':
      return {
        color: 'white',
        fontWeight: 'bold',
        backgroundColor: '#FFA500',
      };
    case 'Feriado':
      return { color: 'red', fontWeight: 'bold', backgroundColor: 'white' };
    case 'Reservada':
      return { color: 'brown', fontWeight: 'bold', backgroundColor: 'white' };
    case 'Fechada':
      return {
        color: 'yellow',
        fontWeight: 'bold',
        backgroundColor: 'white',
      };
    case 'Agendada':
      return {
        color: 'black',
        fontWeight: 'bold',
        backgroundColor: '#00ffff',
      };
    default:
      return {
        color: 'black',
        fontWeight: 'bold',
        backgroundColor: 'white',
      };
  }
};

export { cleanPhoneNumber, getFolderName, changeColor };
