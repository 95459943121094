/* eslint-disable consistent-return */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from '../../../services/api';

// createSlice makes all action creators and reducers in the same file so no separation of logic is necessary

/** *************STATE SLICE************** */
export const sliceEco = createSlice({
  name: 'eco',
  initialState: {
    loading: false,
    eco: {},
  },
  reducers: {
    ecoSuccess: (state, action) => {
      const { eco } = action.payload;
      state.loading = false;
      state.eco = eco;
    },
    ecoRequest: (state, action) => {
      const { eco } = action.payload;
      state.loading = true;
      state.eco = eco;
    },
    ecoFailure: state => {
      state.loading = false;
      state.eco = {};
    },
    ecoReset: state => {
      state.loading = false;
      state.eco = {};
    },
  },
});

/** *************EXPORTED ACTIONS & REDUCERS************** */

export const {
  ecoRequest,
  ecoSuccess,
  ecoFailure,
  ecoReset,
} = sliceEco.actions;

export default sliceEco.reducer;

// API REQUEST ACTIONS HANDLED WITH REDUX-THUNK MIDDLEWARE BUILT INTO REDUX TOOLKIT -->

/** *************THUNKS************** */

export const selectEcoById = payload => {
  return async dispatch => {
    try {
      const id = payload;
      const response = await api.get(`ecocardiograma/${id}`);
      const eco = response.data;
      await dispatch(ecoSuccess({ eco }));
      return response.data;
    } catch (error) {
      toast.error(`ERRO ao buscar Ecocardiograma.`);
      dispatch(ecoFailure());
    }
  };
};
