import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import md5 from 'md5';
import {
  Form,
  Button,
  Card,
  Spinner,
  Container,
  Alert,
  Col,
} from 'react-bootstrap';
import { addDays, parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import api from '../../../services/api';
import header from '../../../assets/icm-header.jpg';
import styles from '../../../styles/styles.module.css';
import getPrefix from '../../../util/getPrefix';

export default function PublicFetal() {
  const { token } = useParams();
  const [fetal, setFetal] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLaudoData = async () => {
      try {
        const response = await api.get(`/report/${token}`);
        const f = response.data.laudo.map(st => ({
          ...st,
          dataFetal: format(addDays(parseISO(st.DATAFETAL), 1), 'dd/MM/yyyy', {
            locale: pt,
          }),
          dataNasc: format(addDays(parseISO(st.DTNPAC), 1), 'dd/MM/yyyy', {
            locale: pt,
          }),
          idadePAC: Math.floor(
            (parseISO(st.DATAFETAL) - parseISO(st.DTNPAC)) /
              (1000 * 3600 * 24) /
              365.25
          ),
        }));
        setFetal(f[0]);
      } catch (error) {
        setError(error.response.data.error);
      }
    };

    fetchLaudoData();
  }, [token]);

  function removeDiv(divName) {
    document.getElementById(divName).style.visibility = 'hidden';
    window.scrollTo(0, 0); // Scroll to the top of the page
    window.print();
    document.getElementById(divName).style.visibility = 'visible';
  }

  function handleDescricao() {
    return <div className={styles.preContent}>{fetal.DESCRICAO}</div>;
  }

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  if (!fetal) {
    return (
      <Container className="mt-5 text-center">
        <Spinner animation="border" role="status" />
      </Container>
    );
  }

  return (
    <div>
      <div className={styles.imageReport}>
        <img width="550" height="100" src={header} alt="header" />
      </div>

      <Card.Header className="text-center">
        <Card.Title className={styles.titleReport}>
          LAUDO DE ECO FETAL
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Form noValidate className={styles.contentReport}>
          <strong className="mr-auto">Nome do Paciente: </strong>
          {`${fetal.CODPAC} - ${fetal.NOMEPAC}`}
          <br></br>
          <strong className="mr-auto">Data de Nasc: </strong>
          {`${fetal.dataNasc}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Sexo: </strong>
          {`${fetal.SEXOPAC}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Idade: </strong>
          {`${fetal.idadePAC} anos`}
          <br></br>
          <strong className="mr-auto">Convênio: </strong>
          {fetal.SIGLACONV}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Código: </strong>
          {fetal.CODFETAL}&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Data do Laudo: </strong>
          {fetal.dataFetal}
          <br></br>
          <strong className="mr-auto">
            Médico que Realizou: {getPrefix(fetal?.CODREALIZOU)}{' '}
          </strong>
          {`${fetal.Realizou}`}
          <br></br>
          <strong className="mr-auto">
            Médico que Solicitou: {getPrefix(fetal?.CODSOLICITANTE)}{' '}
          </strong>
          {`${fetal.Solicitou}`}
          <hr></hr>
          <Form.Row>
            <Col sm>
              <Form.Group size="sm" controlId="obs">
                <div className="row">
                  <div
                    id="fetalObs"
                    style={{ fontSize: 17 }}
                    className="col-12"
                  >
                    {handleDescricao()}
                  </div>
                </div>
              </Form.Group>
            </Col>
          </Form.Row>
          <hr></hr>
          <div className="mt-0 text-center">
            <Card.Body>
              <div className={styles.messageReport}>
                Imagens disponíveis no site:{' '}
                <strong>www.icm.com.br (Resultado de Exames)</strong>
                {/* TEMPORÁRIO */}
                <br />
                <span>Código de verificação: {fetal.CODPAC}</span>
                <hr></hr>
              </div>
              <div className={styles.signatureReport}>
                <i className="mr-auto">
                  Conferido e assinado eletronicamente por:{' '}
                </i>
                <br></br>
                <strong className="mr-auto">
                  {' '}
                  {getPrefix(fetal?.CODREALIZOU)} {fetal.Realizou}
                </strong>
                <br></br>
                <i className="mr-auto">
                  Assinatura Digital:{' '}
                  {md5(JSON.stringify(fetal.DESCRICAO || ''))}
                </i>
              </div>
            </Card.Body>
            <div id="removeMe" className="text-muted">
              <Button onClick={() => removeDiv('removeMe')}>Imprimir</Button>{' '}
            </div>
          </div>
        </Form>
      </Card.Body>
    </div>
  );
}
