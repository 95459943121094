import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import md5 from 'md5';
import { useSelector } from 'react-redux';
import {
  Form,
  Col,
  Button,
  Card,
  Table,
  Modal,
  Collapse,
  Alert,
} from 'react-bootstrap';
import header from '../../../assets/icm-header.jpg';
import blank from '../../../assets/icm-blank.jpg';
import styles from '../../../styles/styles.module.css';
import api from '../../../services/api';
import s3 from '../../../util/s3';
import ReportStyles from '../../../styles/reportStyle';
import getPrefix from '../../../util/getPrefix';

export default function ReportErgo() {
  const { ergo } = useSelector(state => state.ergo);
  const medico = useSelector(state => state.doctor.profile);
  const { printing } = useSelector(state => state.contexto);
  const { funcionario } = useSelector(state => state.funcionario);
  const [open, setOpen] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [urls, setURLs] = useState([]);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(null);
  const [files, setFiles] = useState([]);
  const isAllowedToDeleteFiles =
    medico ||
    funcionario?.codFunc === '90035' ||
    funcionario?.codFunc === '90026' ||
    funcionario?.codFunc === '90001';
  const pageBreakStyles = { break: { pageBreakAfter: 'always' } };
  let contador = 0;

  useEffect(() => {
    s3.listObjectsV2(
      { Bucket: 'prevencor', Prefix: `ecg/${ergo?.CODECG}` },
      (err, data) => {
        if (err) toast.error('Erro ao buscar arquivos. Entre em contato.');
        else
          setFiles(
            data.Contents.filter(
              item =>
                item.Key.includes('.webm') ||
                item.Key.includes('.pdf') ||
                item.Key.includes('.mp4')
            )
          );
      }
    );
  }, [ergo]);

  function removeDiv(divName) {
    document.getElementById(divName).style.visibility = 'hidden';
    window.scrollTo(0, 0);
    window.print();
    document.getElementById(divName).style.visibility = 'visible';
  }

  async function getFiles(f) {
    f = f.Key.split('/');

    return await api.get(
      `https://j87jesh814.execute-api.us-east-1.amazonaws.com/dev/image-upload/folder/ergo/laudo/${ergo?.CODPAC}/key/${f[2]}`
    );
  }

  async function showFiles() {
    setShowModal(true);
    const array = [];

    for (let file of files) {
      const url = await getFiles(file);
      const obj = { url, ...file }; // coloco num obj só a presigned URL e o file, pq preciso da Key para deletar depois
      array.push(obj);
    }

    setURLs(array);
  }

  function handleClick(id) {
    setOpen(prevState => ({ ...prevState, [id]: !prevState[id] }));
  }

  async function deleteS3Object(file) {
    try {
      await s3.deleteObject({ Bucket: 'prevencor', Key: file }).promise();
      toast.success('O arquivo foi deletado com sucesso!');
      setOpenDeleteAlert(null);
      setShowModal(false);
    } catch (error) {
      toast.error('Erro ao deletar arquivo. Entre em contato.');
    }
  }

  return (
    <>
      <div>
        {printing ? (
          <div className={styles.imageReport}>
            <img width="550" height="100" src={header} alt="header" />
          </div>
        ) : (
          <>
            <ReportStyles />
            <div className={styles.imageReport}>
              <img width="550" height="0" src={blank} alt="header" />
            </div>
          </>
        )}
        <Card.Header className="text-center">
          <Card.Title className={styles.titleReport}>
            LAUDO DE TESTE ERGOMÉTRICO
          </Card.Title>
        </Card.Header>

        <Card.Body>
          <Form noValidate className={styles.contentReport}>
            <strong className="mr-auto">Nome do Paciente: </strong>
            {`${ergo?.CODPAC} - ${ergo?.NOMEPAC}`}
            <br></br>
            <strong className="mr-auto">Data de Nasc: </strong>
            {`${ergo?.dataNasc}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Sexo: </strong>
            {`${ergo?.SEXOPAC}`}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Idade: </strong>
            {`${ergo?.idadePAC} anos`}
            <br></br>
            <strong className="mr-auto">Convênio: </strong>
            {ergo?.SIGLACONV}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Cod Ergo: </strong>
            {ergo?.CODEXAME}&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Data do Exame: </strong>
            {ergo?.dataErgo}
            <br></br>
            <strong className="mr-auto">
              Médico que Realizou: {getPrefix(ergo?.CODREALIZOU)}{' '}
            </strong>
            {`${ergo?.Realizou}`}
            <br></br>
            <strong className="mr-auto">
              Médico que Solicitou: {getPrefix(ergo?.CODSOLICITANTE)}{' '}
            </strong>
            {`${ergo?.Solicitou}`}
            <hr></hr>
            <Form.Row>
              <Form.Group as={Col} sm controlId="editNOMEMED">
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">Indicações do Teste</strong>
                  </div>
                </div>
                {ergo?.indicacoes?.map(indica => (
                  <Form.Text key={indica.DESCRICAO} className="text-muted">
                    <div key={contador++} className="row">
                      <div className="col-12"> {indica.DESCRICAO}</div>
                    </div>
                  </Form.Text>
                ))}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} sm controlId="editNOMEMED">
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">Queixas</strong>
                  </div>
                </div>
                {ergo?.queixas?.map(queixa => (
                  <Form.Text key={queixa.FRASE} className="text-muted">
                    <div key={contador++} className="row">
                      <div className="col-12"> {queixa.FRASE}</div>
                    </div>
                  </Form.Text>
                ))}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} sm controlId="editNOMEMED">
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">Medicamentos</strong>
                  </div>
                </div>
                {ergo?.medicamentos?.map(medicto => (
                  <Form.Text key={medicto.FRASE} className="text-muted">
                    <div key={contador++} className="row">
                      <div className="col-12"> {medicto.FRASE}</div>
                    </div>
                  </Form.Text>
                ))}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} sm controlId="editNOMEMED">
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">Fatores de Risco</strong>
                  </div>
                </div>
                {ergo?.fatoresRisco?.map(fator => (
                  <Form.Text key={fator.DESCRICAO} className="text-muted">
                    <div key={contador++} className="row">
                      <div className="col-12"> {fator.DESCRICAO}</div>
                    </div>
                  </Form.Text>
                ))}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} sm controlId="editNOMEMED">
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">Atividade Física</strong>
                  </div>
                </div>
                {ergo?.atividadesFisica?.map(atv => (
                  <Form.Text key={atv.DESCRICAO} className="text-muted">
                    <div key={contador++} className="row">
                      <div className="col-12"> {atv.DESCRICAO}</div>
                    </div>
                  </Form.Text>
                ))}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} sm controlId="editNOMEMED">
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">Exame Físico</strong>
                  </div>
                </div>
                <Form.Text className="text-muted">
                  <div key={contador++} className="row">
                    <div className="col-12"> {ergo?.EXAMEFISICO}</div>
                  </div>
                </Form.Text>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} sm controlId="editNOMEMED">
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">Eletrocardiograma Basal</strong>
                  </div>
                </div>
                {ergo?.ecgBasal?.map(ecg => (
                  <Form.Text key={ecg.FRASE} className="text-muted">
                    <div key={contador++} className="row">
                      <div className="col-12"> {ecg.FRASE}</div>
                    </div>
                  </Form.Text>
                ))}
              </Form.Group>
            </Form.Row>
            <section style={pageBreakStyles.break}></section>
            {printing ? (
              <div className={styles.imageReport}>
                <img width="550" height="100" src={header} alt="header" />
              </div>
            ) : (
              <div className={styles.imageReport}>
                <img width="550" height="0" src={blank} alt="header" />
              </div>
            )}
            <div>
              <div className="row">
                <div className="col-12">
                  <strong className="mr-auto">
                    Frequência Cardíaca Prevista
                  </strong>
                </div>
              </div>
              <Table bordered hover size="sm">
                <tbody>
                  <tr>
                    <td>Máxima:</td>
                    <td>{ergo?.FREQMAX} bpm</td>
                    <td>Submáxima:</td>
                    <td>{ergo?.FREQSUB} bpm</td>
                  </tr>
                  <tr>
                    <td>Dietado (FC):</td>
                    <td>{ergo?.FCDEITADO} bpm</td>
                    <td>PA:</td>
                    <td>{ergo?.PADEITADO} mmHg</td>
                  </tr>
                  <tr>
                    <td>Em Pé (FC):</td>
                    <td>{ergo?.FCPE} bpm</td>
                    <td>PA:</td>
                    <td>{ergo?.PAPE} mmHg</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <br />
            <Form.Row>
              <Col sm>
                <Form.Group size="sm" controlId="obs">
                  <div className="row">
                    <div className="col-12">
                      <strong className="mr-auto">
                        Protocolo de {ergo?.PROTOCOLO}
                      </strong>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-12">
                      <strong className="mr-auto">Estágio</strong>
                    </div>
                  </div>
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>Estágio</th>
                        <th>Tempo(min)</th>
                        <th>Vel(mph)</th>
                        <th>Inc(%)</th>
                        <th>FC(bpm)</th>
                        <th>PA(mmHg)</th>
                        <th>Sinais/Sintomas</th>
                        <th>Arritmias</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ergo?.estagios?.map(estagio => (
                        <tr key={estagio.CODESTAGIO}>
                          <td>
                            <div key={contador++} className="row">
                              <div className="col-12">
                                {' '}
                                {estagio.CODESTAGIO}
                              </div>
                            </div>
                          </td>
                          <td>{estagio.TEMPO}</td>
                          <td>{estagio.VELOCIDADE}</td>
                          <td>{estagio.INCLINACAO}</td>
                          <td>{estagio.FC}</td>
                          <td>{estagio.PA}</td>
                          <td>{estagio.Sintomas}</td>
                          <td>{estagio.Arritmias}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <br />
                  <div className="row">
                    <div className="col-12">
                      <strong className="mr-auto">Recuperação</strong>
                    </div>
                  </div>
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>Recuperação</th>
                        <th>Tempo(min)</th>
                        <th>FC(bpm)</th>
                        <th>PA(mmHg)</th>
                        <th>Sinais/Sintomas</th>
                        <th>Arritmias</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ergo?.recuperacoes?.map(recup => (
                        <tr key={recup.CODRECUPERACAO}>
                          <td>
                            <div key={contador++} className="row">
                              <div className="col-12">
                                {' '}
                                {recup.CODRECUPERACAO}
                              </div>
                            </div>
                          </td>
                          <td>{recup.TEMPO}</td>
                          <td>{recup.FC}</td>
                          <td>{recup.PA}</td>
                          <td>{recup.Sintomas}</td>
                          <td>{recup.Arritmias}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Form.Group>
              </Col>
            </Form.Row>
            <section style={pageBreakStyles.break}></section>
            {printing ? (
              <div className={styles.imageReport}>
                <img width="550" height="100" src={header} alt="header" />
              </div>
            ) : (
              <div className={styles.imageReport}>
                <img width="550" height="0" src={blank} alt="header" />
              </div>
            )}
            {ergo?.altECG && (
              <Form.Row>
                <Form.Group as={Col} sm controlId="editNOMEMED">
                  <div className="row">
                    <div className="col-12">
                      <strong className="mr-auto">
                        Alterações Eletrocardiográficas
                      </strong>
                    </div>
                  </div>
                  {ergo?.altECG?.map(segST => (
                    <Form.Text key={segST.DESCRICAO} className="text-muted">
                      <div key={contador++} className="row">
                        <div className="col-12"> {segST.DESCRICAO}</div>
                      </div>
                    </Form.Text>
                  ))}
                </Form.Group>
              </Form.Row>
            )}
            <Form.Row>
              <Form.Group as={Col} sm controlId="editNOMEMED">
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">Arritmias</strong>
                  </div>
                </div>
                {ergo?.arritmias?.map(arr => (
                  <Form.Text key={arr.DESCRICAO} className="text-muted">
                    <div key={contador++} className="row">
                      <div className="col-12"> {arr.DESCRICAO}</div>
                    </div>
                  </Form.Text>
                ))}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} sm controlId="editNOMEMED">
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">Comportamento da PA</strong>
                  </div>
                </div>
                {ergo?.comportamentoPA?.map(compPA => (
                  <Form.Text key={compPA.DESCRICAO} className="text-muted">
                    <div key={contador++} className="row">
                      <div className="col-12"> {compPA.DESCRICAO}</div>
                    </div>
                  </Form.Text>
                ))}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} sm controlId="editNOMEMED">
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">Comentários</strong>
                  </div>
                </div>
                {ergo?.comentarios?.map(comentario => (
                  <Form.Text key={comentario.DESCRICAO} className="text-muted">
                    <div key={contador++} className="row">
                      <div className="col-12"> {comentario.DESCRICAO}</div>
                    </div>
                  </Form.Text>
                ))}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} sm controlId="editNOMEMED">
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">Conclusões</strong>
                  </div>
                </div>
                {ergo?.conclusoes?.map(conclusao => (
                  <Form.Text key={conclusao.DESCRICAO} className="text-muted">
                    <div key={contador++} className="row">
                      <div className="col-12"> {conclusao.DESCRICAO}</div>
                    </div>
                  </Form.Text>
                ))}
              </Form.Group>
            </Form.Row>
            <div>
              <Card className="mt-2 text-center">
                <Card.Header>
                  <Card.Text>
                    "O Resultado do presente laudo não configura necessariamente
                    a presença ou ausência de doença, devendo obrigatoriamente
                    ser correlacionado com demais dados clínicos e exames
                    complementares pertinentes ao caso."
                  </Card.Text>
                </Card.Header>
                <Card.Body>
                  {files.length > 0 && (
                    <div className={styles.messageReport}>
                      Imagens disponíveis no site:{' '}
                      <strong>www.icm.com.br (Resultado de Exames)</strong>
                      {/* TEMPORÁRIO */}
                      <br />
                      <span>Código de verificação: {ergo?.CODPAC}</span>
                      <hr></hr>
                    </div>
                  )}
                  <div className={styles.signatureReport}>
                    <i className="mr-auto">
                      Conferido e assinado eletronicamente por:{' '}
                    </i>
                    <strong className="mr-auto">
                      {' '}
                      {getPrefix(ergo?.CODREALIZOU)} {ergo?.Realizou}
                    </strong>
                    <br></br>
                    <i className="mr-auto">
                      CRM: {ergo?.CRMMED} - RQE: {ergo?.RQE}
                    </i>
                    <br></br>
                    <i className="mr-auto">
                      Assinatura Digital: {md5(JSON.stringify(ergo))}
                    </i>
                  </div>
                </Card.Body>

                <Card.Footer id="removeMe">
                  <Button onClick={() => removeDiv('removeMe')}>
                    Imprimir
                  </Button>{' '}
                  <Button variant="success" onClick={() => showFiles()}>
                    Ver Imagem
                  </Button>
                </Card.Footer>
              </Card>
            </div>
          </Form>
        </Card.Body>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Visualização</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {urls.map((url, idx) => {
            if (isAllowedToDeleteFiles) {
              return (
                <div>
                  <Card>
                    <Alert
                      show={openDeleteAlert === url.Key}
                      variant="danger"
                      onClose={() => setOpenDeleteAlert(null)}
                      dismissible
                    >
                      <Alert.Heading>
                        Você tem certeza que quer deletar a imagem?
                      </Alert.Heading>
                      <hr />
                      <div className="d-flex justify-content-">
                        <Button
                          onClick={() => deleteS3Object(url.Key)}
                          variant="danger"
                        >
                          Confirmar
                        </Button>
                      </div>
                    </Alert>
                    <div class="btn-group">
                      <button
                        class="btn btn-primary"
                        onClick={() => handleClick(url.url.data.preSignedUrl)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        style={{ color: 'white' }}
                      >
                        Imagem {idx + 1}
                      </button>
                      <button
                        class="btn btn-danger"
                        onClick={() => setOpenDeleteAlert(url.Key)}
                        style={{ color: 'white' }}
                      >
                        Deletar
                      </button>
                    </div>
                  </Card>
                  <Collapse in={open[url.url.data.preSignedUrl]}>
                    <div
                      key={url.url.data.preSignedUrl}
                      id="collapse-div"
                      className="collapsedText"
                    >
                      {url.url.data.preSignedUrl.includes('.webm') ||
                      url.url.data.preSignedUrl.includes('.mp4') ? (
                        <div style={{ textAlign: 'center', display: 'block' }}>
                          <video
                            id="video-laudo"
                            width="500"
                            height="500"
                            controls
                          >
                            <source
                              src={url.url.data.preSignedUrl}
                              type={
                                url.url.data.preSignedUrl.endsWith('.mp4')
                                  ? 'video/mp4'
                                  : 'video/webm'
                              }
                            />
                          </video>
                        </div>
                      ) : (
                        <div style={{ textAlign: 'center', display: 'block' }}>
                          <embed
                            src={url.url.data.preSignedUrl}
                            width="600px"
                            height="900px"
                          />
                        </div>
                      )}
                    </div>
                  </Collapse>
                </div>
              );
            } else {
              return (
                <div>
                  <Card>
                    <div class="btn-group">
                      <button
                        class="btn btn-primary"
                        onClick={() => handleClick(url.url.data.preSignedUrl)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        style={{ color: 'white' }}
                      >
                        Imagem {idx + 1}
                      </button>
                    </div>
                  </Card>
                  <Collapse in={open[url.url.data.preSignedUrl]}>
                    <div
                      key={url.url.data.preSignedUrl}
                      id="collapse-div"
                      className="collapsedText"
                    >
                      {url.url.data.preSignedUrl.includes('.webm') ||
                      url.url.data.preSignedUrl.includes('.mp4') ? (
                        <div style={{ textAlign: 'center', display: 'block' }}>
                          <video
                            id="video-laudo"
                            width="500"
                            height="500"
                            controls
                          >
                            <source
                              src={url.url.data.preSignedUrl}
                              type={
                                url.url.data.preSignedUrl.endsWith('.mp4')
                                  ? 'video/mp4'
                                  : 'video/webm'
                              }
                            />
                          </video>
                        </div>
                      ) : (
                        <div style={{ textAlign: 'center', display: 'block' }}>
                          <embed
                            src={url.url.data.preSignedUrl}
                            width="600px"
                            height="900px"
                          />
                        </div>
                      )}
                    </div>
                  </Collapse>
                </div>
              );
            }
          })}
        </Modal.Body>
      </Modal>
    </>
  );
}
