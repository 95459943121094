import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { addDays, parseISO, format } from 'date-fns';
import { FaTrashAlt, FaArrowLeft } from 'react-icons/fa';
import pt from 'date-fns/locale/pt';
import {
  Table,
  Form,
  Card,
  Button,
  Col,
  Dropdown,
  Modal,
  Spinner,
} from 'react-bootstrap';
import {
  loadingOpen,
  loadingClose,
  printOpen,
  printClose,
} from '../../../redux/features/context/contextSlice';
import { selectProntuarioById } from '../../../redux/features/prontuario/prontuarioSlice';
import { pacienteSuccess } from '../../../redux/features/prontuario/pacienteSlice';
import {
  selectStressById,
  selectStressByIdOLD,
} from '../../../redux/features/laudo/stressSlice';
import { selectEcgById } from '../../../redux/features/laudo/ecgSlice';
import { selectTransById } from '../../../redux/features/laudo/transSlice';
import { selectErgoById } from '../../../redux/features/laudo/ergoSlice';
import { selectEcoById } from '../../../redux/features/laudo/ecoSlice';
import { selectFetalById } from '../../../redux/features/laudo/fetalSlice';
import api from '../../../services/api';
import history from '../../../services/history';
import Loading from '../../../pages/Index/Loading';
import dbatividade from '../../../util/atividade';
import funcionario from '../../../store/modules/auth/funcionario/reducer';
import FormEcg from '../../../pages/laudo/ecg/FormEcg';
import s3 from '../../../util/s3';
import CustomFileInput from '../../../components/CustomFileInput';

export default function Atividade() {
  const dispatch = useDispatch();
  const location = useLocation();
  const medico = useSelector(state => state.doctor.doctor);
  const { loading } = useSelector(state => state.contexto);
  const { patient } = useSelector(state => state.paciente);
  const { ecg } = useSelector(state => state.ecg);
  const searchParams = new URLSearchParams(location.search);
  const [pacientes, setPacientes] = useState([]);
  const [loadingPac, setLoadingPac] = useState(false);
  const [count, setCount] = useState(0);
  const [atividades, setAtividades] = useState([]);
  const [search, setSearch] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [total, setTotal] = useState(0);
  const [uploadingFilesLoading, setUploadingFilesLoading] = useState(false);
  const [nomePac, setNomePac] = useState(patient?.nome || '');
  const [dtnNasc, setDtnNasc] = useState(patient?.dtNasc || '');
  const [showEletroModal, setShowEletroModal] = useState(false);
  const [codigoLaudo, setCodigoLaudo] = useState();
  const [atividade, setAtividade] = useState();
  const [files, setFiles] = useState();
  const [laudo, setLaudo] = useState();

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    const userId = searchParams.get('userId');
    const patientName = searchParams.get('pacName');
    const paciente = { ...patient, CODPAC: userId, NOMEPAC: patientName };
    if (patientName) handleAtividades(paciente);
  }, [history.location.search]);

  function handleImprimir(atividade) {
    switch (atividade.CODPROCEDI) {
      case 1:
      case 58:
      case 800:
        handleProntuario(atividade);
        break;
      case 3:
        handleEcg(atividade);
        break;
      case 13:
        handleStress(atividade);
        break;
      case 12:
        handleTrans(atividade);
        break;
      case 4:
        handleErgo(atividade);
        break;
      case 11:
      case 712:
      case 713:
      case 714:
        handleEco(atividade);
        break;
      case 14:
      case 15:
        handleCarotida(atividade);
        break;
      case 17:
        handleFetal(atividade);
        break;
      default:
        return toast.warn('Laudo não disponível para Visualização!');
    }
  }

  function handleImprimirTXT(atividade) {
    switch (atividade.CODPROCEDI) {
      case 11:
      case 712:
      case 713:
      case 714:
        handleEcoTXT(atividade);
        break;
      default:
        return toast.warn('Laudo não disponível para Visualização!');
    }
  }

  async function handleLaudar(atividade) {
    dispatch(loadingOpen());
    if (medico?.tipo === 'Agenda' || funcionario) {
      switch (atividade.CODPROCEDI) {
        case 1:
        case 58:
        case 800:
          await dispatch(selectProntuarioById(atividade.CODLAUDO));
          history.push('/formProntuario');
          break;
        case 3:
          handleEcgModal(atividade);
          break;
        case 13: // STRESS
          await dispatch(selectStressById(atividade.CODLAUDO));
          history.push('/formStress');
          break;
        case 12: // TRANS
          await dispatch(selectTransById(atividade.CODLAUDO));
          history.push('/formTrans');
          break;
        // case 4: //ERGO
        //   break;
        case 11:
        case 712:
        case 713:
        case 714:
          const eco = await dispatch(selectEcoById(atividade.CODLAUDO));
          if (eco.TIPOECO === 'EcoC') history.push('/formCongenito');
          else history.push('/formDoppler');
          break;
        case 14:
        case 15:
          history.push({
            pathname: '/formCarotida',
            state: { codLaudo: atividade.CODLAUDO },
          });
          break;
        case 17: // FETAL
          await dispatch(selectFetalById(atividade.CODLAUDO));
          history.push('/formFetal');
          break;
        default:
          dispatch(loadingClose());
          return toast.warn('Laudo não disponível para registro!');
      }
    }
    dispatch(loadingClose());
  }

  function handleAtividades(paciente) {
    dispatch(loadingOpen());

    searchParams.set('userId', paciente.CODPAC);
    searchParams.set('pacName', paciente.NOMEPAC);

    setNomePac(paciente.NOMEPAC);
    if (medico?.tipo !== 'Externo') {
      setSearch(false);
      let c = 0;
      if (paciente.CODPAC !== 0) {
        api
          .get(`paciente/atividades?id=${paciente.CODPAC}`)
          .then(result => {
            const data = result.data.map(atividade => ({
              ...atividade,
              dataFormatada: format(
                addDays(parseISO(atividade.DATALAUDO), 1),
                'dd/MM/yyyy',
                { locale: pt }
              ),
              counter: (c += 1),
            }));
            dispatch(pacienteSuccess({ paciente }));
            setAtividades(data);
            setTotal(c);
            setLoadingPac(false);
            setSearch(true);
            history.push(`/frmatv?${searchParams.toString()}`);
          })
          .catch(err => toast.error(err.response.data.error));
      } else {
        toast.warn('Paciente não identificado!');
      }
    } else {
      setSearch(false);
      let c = 0;
      if (paciente.CODPAC !== 0) {
        api
          .get(`paciente/atividades?id=${paciente.CODPAC}`)
          .then(result => {
            const data = result.data.map(atividade => ({
              ...atividade,
              dataFormatada: format(
                addDays(parseISO(atividade.DATALAUDO), 1),
                'dd/MM/yyyy',
                { locale: pt }
              ),
              counter: (c += 1),
            }));
            dispatch(pacienteSuccess({ paciente }));
            setAtividades(data);
            setTotal(c);
            setLoadingPac(false);
            setSearch(true);
            history.push(`/frmatv?${searchParams.toString()}`);
          })
          .catch(err => toast.error(err.response.data.error));
      } else {
        toast.warn('Paciente não identificado!');
      }
    }
    dispatch(loadingClose());
  }

  async function handleProntuario(atividade) {
    dispatch(loadingOpen());
    if (atividade.GUIA1 === 'SIM') {
      // SANTA CASA
      if (atividade.CODLAUDO !== 0) {
        await dispatch(selectProntuarioById(atividade.CODLAUDO));
        history.push('/reportProntuario');
      } else {
        toast.warn('Prontuario não identificado!');
      }
    } else {
      toast.warn('Prontuário não Autorizado!');
    }
    dispatch(loadingClose());
  }

  function handleEcg(atividade) {
    dispatch(loadingOpen());
    // LAUDO LIBERADO (SIM ou NAO)
    if (atividade.GUIA1 === 'SIM') {
      dispatch(selectEcgById(atividade.CODLAUDO));
      history.push('/reportEcg');
    } else {
      toast.warn('Laudo não Liberado para Internet!');
    }
    dispatch(loadingClose());
  }

  async function handleEcgModal(atividade) {
    dispatch(loadingOpen());
    dispatch(selectEcgById(atividade.CODLAUDO));
    setLaudo(ecg);
    setShowEletroModal(true);
    dispatch(loadingClose());
  }

  function handlePaciente() {
    setLoadingPac(false);
    if (nomePac.length > 2) {
      let c = 0;
      if (nomePac) {
        dispatch(loadingOpen());
        api
          .get(`/pacientebynome`, { params: { nome: nomePac.trim() } })
          .then(result => {
            const newPacientes = result.data.map(paciente => ({
              ...paciente,
              dataFormatada:
                paciente.DTNPAC &&
                format(addDays(parseISO(paciente.DTNPAC), 1), 'dd/MM/yyyy', {
                  locale: pt,
                }),
              counter: (c += 1),
            }));
            setPacientes(newPacientes);
            setCount(c);
            setLoadingPac(true);
            setSearch(false);
          })
          .catch(error => toast.error(error.response.data.error))
          .finally(() => dispatch(loadingClose()));
      } else {
        toast.warn('Médico não identificado!');
      }
    } else {
      toast.warn('Digite ao menos 3 caracteres!');
    }
  }

  async function handlePacienteDtNasc() {
    setLoadingPac(false);
    if (dtnNasc.length > 2) {
      let c = 0;
      if (dtnNasc) {
        dispatch(loadingOpen());
        api
          .get(`/pacientebydtnasc`, { params: { dt: dtnNasc } })
          .then(result => {
            const p = result.data.map(paciente => ({
              ...paciente,
              dataFormatada:
                paciente.DTNPAC &&
                format(addDays(parseISO(paciente.DTNPAC), 1), 'dd/MM/yyyy', {
                  locale: pt,
                }),
              counter: (c += 1),
            }));
            setPacientes(p);
            setCount(c);
            setLoadingPac(true);
            setSearch(false);
          })
          .catch(error => toast.error(error.response.data.error))
          .finally(() => dispatch(loadingClose()));
      } else {
        toast.warn('Médico não identificado!');
      }
    } else {
      toast.warn('Digite ao menos 3 caracteres!');
    }
  }

  async function handleStress(atividade) {
    dispatch(loadingOpen());
    if (atividade.GUIA1 === 'SIM') {
      // SANTA CASA
      if (atividade.CODLAUDO > 81610) {
        await dispatch(selectStressById(atividade.CODLAUDO));
        history.push('/reportStress');
      } else {
        await dispatch(selectStressByIdOLD(atividade.CODLAUDO));
        history.push('/reportStress');
      }
    } else {
      toast.warn('Laudo não Autorizado!');
    }
    dispatch(loadingClose());
  }

  async function handleTrans(atividade) {
    dispatch(loadingOpen());
    // EXTERNO OU SANTA CASA
    if (atividade.GUIA1 === 'SIM') {
      await dispatch(selectTransById(atividade.CODLAUDO));
      history.push('/reportTrans');
    } else {
      toast.warn('Laudo não Autorizado!');
    }
    dispatch(loadingClose());
  }

  async function handleErgo(atividade) {
    dispatch(loadingOpen());
    if (atividade.GUIA1 === 'SIM') {
      await dispatch(selectErgoById(atividade.CODLAUDO));
      history.push('/reportErgo');
    } else {
      toast.warn('Laudo não Autorizado!');
    }
    dispatch(loadingClose());
  }

  async function handleEco(atividade) {
    dispatch(loadingOpen());
    if (medico?.tipo === 'Externo') {
      // SANTA CASA
      if (dbatividade.convenios.some(c => c.codConv === atividade.CODCONV)) {
        let c = 0;
        // LAUDO LIBERADO (SIM ou NAO)
        if (atividade.GUIA1 === 'SIM') {
          await dispatch(selectEcoById(atividade.CODLAUDO)).then(eco => {
            const listaEco = { ...eco, counter: (c += 1) };
            setCount(c);
            if (listaEco.TIPOECO === 'EcoC') {
              history.push('/reportEcoCongenito');
            } else {
              history.push('/reportDoppler');
            }
          });
        } else {
          toast.warn('Laudo não Liberado para Internet!');
        }
      } else {
        toast.warn('Convênio não Autorizado!');
      }
    } else if (medico?.tipo === 'Agenda') {
      // if (medico.codMed NAO FOR DA SANTA CASA (SE FOR DA EQUIPE))
      let c = 0;
      if (atividade.GUIA1 === 'SIM') {
        dispatch(selectEcoById(atividade.CODLAUDO)).then(eco => {
          const listaEco = { ...eco, counter: (c += 1) };
          setCount(c);
          if (listaEco.TIPOECO === 'EcoC') {
            history.push('/reportEcoCongenito');
          } else {
            history.push('/reportDoppler');
          }
        });
      } else {
        toast.warn('Laudo não Liberado para Internet!');
      }
    } else {
      toast.warn('Visualização não permitida. Consulte a administração!');
    }
    dispatch(loadingClose());
  }

  async function handleEcoTXT(atividade) {
    dispatch(loadingOpen());
    if (medico?.tipo === 'Externo') {
      // SANTA CASA
      if (dbatividade.convenios.some(c => c.codConv === atividade.CODCONV)) {
        let c = 0;
        // LAUDO LIBERADO (SIM ou NAO)
        if (atividade.GUIA1 === 'SIM') {
          dispatch(selectEcoById(atividade.CODLAUDO)).then(eco => {
            const listaEco = { ...eco, counter: (c += 1) };
            setCount(c);
            if (listaEco.TIPOECO === 'EcoC') {
              history.push('/reportEcoCongenito');
            } else {
              history.push('/reportDoppler');
            }
          });
        } else {
          toast.warn('Laudo não Liberado para Internet!');
        }
      } else {
        toast.warn('Convênio não Autorizado!');
      }
    } else if (medico?.tipo === 'Agenda') {
      // if (medico.codMed NAO FOR DA SANTA CASA (SE FOR DA EQUIPE))
      let c = 0;
      if (atividade.GUIA1 === 'SIM') {
        dispatch(selectEcoById(atividade.CODLAUDO)).then(eco => {
          const listaEco = { ...eco, counter: (c += 1) };
          setCount(c);
          if (listaEco.TIPOECO === 'EcoC') {
            history.push('/reportEcoCongenitoTXT');
          } else {
            toast.warn('Disponível para Laudo de Eco Congênito!');
          }
        });
      } else {
        toast.warn('Laudo não Liberado para Internet!');
      }
    } else {
      toast.warn('Visualização não permitida. Consulte a administração!');
    }
    dispatch(loadingClose());
  }

  function handleCarotida(atividade) {
    if (atividade.GUIA1 === 'SIM') {
      // SANTA CASA
      history.push({
        pathname: '/reportCarotida',
        state: { codLaudo: atividade.CODLAUDO },
      });
    } else {
      toast.warn('Laudo não Autorizado!');
    }
  }

  async function handleFetal(atividade) {
    dispatch(loadingOpen());
    if (atividade.GUIA1 === 'SIM') {
      // SANTA CASA
      await dispatch(selectFetalById(atividade.CODLAUDO));
      history.push('/reportFetal');
    } else {
      toast.warn('Laudo não Autorizado!');
    }
    dispatch(loadingClose());
  }

  function checkEnter(e) {
    if (e.key === 'Enter') handlePaciente();
  }

  function checkEnterDtNasc(e) {
    if (e.key === 'Enter') handlePacienteDtNasc();
  }

  function getFolderName(a) {
    switch (a.CODPROCEDI) {
      case 1:
      case 58:
      case 800:
        return 'prontuario';
      case 3:
        return 'ecg';
      case 13:
        return 'stress';
      case 12:
        return 'trans';
      case 4:
        return 'ergo';
      case 11:
      case 712:
      case 713:
      case 714:
        return 'eco';
      case 14:
      case 15:
        return 'carotida';
      case 17:
        return 'fetal';
      default:
        return toast.warn('Laudo não disponível para Visualização!');
    }
  }

  async function handleUploadFile(file) {
    const folder = getFolderName(atividade);
    const name = file.name
      .replaceAll(' ', '')
      .replaceAll('%20', '')
      .replaceAll('%2520', '');

    try {
      const { data } = await api.put(
        `https://j87jesh814.execute-api.us-east-1.amazonaws.com/dev/image-upload/folder/${folder}/laudo/${codigoLaudo}/key/${name}`
      );

      await fetch(data.preSignedUrl, { method: 'PUT', body: file });

      // por enquanto só ECG
      if (atividade.CODPROCEDI === 3) {
        await api.put(`/ecg/${codigoLaudo}/update-url`, {
          codEcg: codigoLaudo,
          url: `https://prevencor.s3.amazonaws.com/ecg/${codigoLaudo}/${file.name}`,
        });
      }
    } catch (error) {
      toast.error('Erro ao enviar arquivo!');
    }
  }

  async function confirmUpload() {
    if (!files) return toast.warn('Selecione pelo menos um arquivo!');

    if (atividade.CODPROCEDI === 3) {
      const listS3ObjectsPromise = new Promise((resolve, reject) => {
        s3.listObjectsV2(
          { Bucket: 'prevencor', Prefix: `ecg/${codigoLaudo}` },
          (err, data) => {
            if (err) {
              toast.error('Erro ao buscar arquivos. Entre em contato.');
              reject(err);
            } else {
              if (data.Contents.length > 0) resolve(true);
              else resolve(false);
            }
          }
        );
      });

      const hasFiles = await listS3ObjectsPromise;
      if (hasFiles) return toast.warn('Este ECG já tem um arquivo.');
    }

    try {
      setUploadingFilesLoading(true);
      await Promise.all(files.map(file => handleUploadFile(file)));
      toast.success('Upload finalizado com sucesso!');
    } catch (error) {
      toast.error('Erro ao enviar arquivos!');
    } finally {
      setUploadingFilesLoading(false);
    }
  }

  function handleAttachFile(atividade) {
    setShowUpload(true);
    setCodigoLaudo(atividade.CODLAUDO);
    setAtividade(atividade);
  }

  function closeFileModal() {
    setShowUpload(false);
    setFiles([]);
  }

  return (
    <>
      <Card style={{ marginTop: 10 }}>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <Button onClick={() => history.push('/agenda')}>
            <FaArrowLeft />
          </Button>
          <h5 className="m-0 text-center text-success">EXAMES DO PACIENTE</h5>
        </Card.Header>
        <Card.Body>
          <Form.Row>
            <Col sm={1}>
              <strong className="m-0">Nome:</strong>
            </Col>
            <Col sm={6}>
              <Form.Control
                style={{ textTransform: 'uppercase' }}
                value={nomePac}
                required
                type="text"
                placeholder="Nome do Paciente"
                onChange={e => setNomePac(e.target.value)}
                onKeyUp={e => checkEnter(e)}
              />
            </Col>
            <Col sm={1}>
              <strong className="m-0">Dt Nasc:</strong>
            </Col>
            <Col sm={2}>
              <Form.Control
                style={{ textTransform: 'uppercase' }}
                value={dtnNasc}
                type="date"
                onChange={e => setDtnNasc(e.target.value)}
                onKeyUp={e => checkEnterDtNasc(e)}
              />
            </Col>
            <Col sm={2}>
              <Button
                type="button"
                onClick={() => {
                  if (nomePac !== '') handlePaciente();
                  else {
                    if (dtnNasc !== '') handlePacienteDtNasc();
                  }
                }}
              >
                Localizar
              </Button>
            </Col>
          </Form.Row>
        </Card.Body>
      </Card>

      {loadingPac && (
        <Table
          responsive="sm"
          striped
          bordered
          hover
          size="sm"
          variant="success"
          style={{ marginTop: 10 }}
        >
          <thead>
            <tr>
              <th>#</th>
              <th>Nome</th>
              <th>Dt Nasc</th>
              {medico?.tipo === 'Agenda' && <th>Celular</th>}
              <th>Código</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody>
            {pacientes.map(pac => (
              <tr key={pac.counter}>
                <td>{pac.counter}</td>
                <td>
                  <Button variant="link" onClick={() => handleAtividades(pac)}>
                    {pac.NOMEPAC}
                  </Button>
                </td>
                <td>{pac.dataFormatada}</td>
                {medico?.tipo === 'Agenda' && <td>{pac.CELULARPAC}</td>}
                <td>{pac.CODPAC}</td>
                <td style={{ textAlign: 'center' }}>
                  <Button
                    variant="success"
                    onClick={() => handleAtividades(pac)}
                  >
                    Exames
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td style={{ textAlign: 'right' }} colSpan="4">
                TOTAL DE PACIENTES
              </td>
              <td style={{ textAlign: 'left' }} colSpan="2">
                {count}
              </td>
            </tr>
          </tfoot>
        </Table>
      )}

      {search && (
        <Table
          responsive="sm"
          striped
          bordered
          hover
          size="sm"
          variant="success"
          style={{ marginTop: 10 }}
        >
          <thead>
            {medico?.tipo !== 'Externo' ? (
              <tr>
                <th>#</th>
                <th>Proced</th>
                <th>Convênio</th>
                <th>Data</th>
                <th>Laudo</th>
                <th>#</th>
              </tr>
            ) : (
              <tr>
                <th>#</th>
                <th>Proced</th>
                <th>Convênio</th>
                <th>Data</th>
                <th>Laudo</th>
              </tr>
            )}
          </thead>
          <tbody>
            {medico?.tipo !== 'Externo'
              ? // MEDICO AGENDA
                atividades.map(a => (
                  <tr key={a.counter}>
                    <td>{a.counter}</td>
                    {a.CODPROCEDI === 1 ||
                    a.CODPROCEDI === 58 ||
                    a.CODPROCEDI === 800 ? (
                      <td>{a.DESCPROCEDI}</td>
                    ) : a.CODPROCEDI === 13 ? (
                      <td>{a.DESCPROCEDI}</td>
                    ) : a.CODPROCEDI === 14 || a.CODPROCEDI === 15 ? (
                      <td>{a.DESCPROCEDI}</td>
                    ) : a.CODPROCEDI === 17 ? (
                      <td>{a.DESCPROCEDI}</td>
                    ) : a.CODPROCEDI === 3 ? (
                      <td>{a.DESCPROCEDI}</td>
                    ) : a.CODPROCEDI === 4 ? (
                      <td>{a.DESCPROCEDI}</td>
                    ) : a.CODPROCEDI === 12 ? (
                      <td>{a.DESCPROCEDI}</td>
                    ) : a.CODPROCEDI === 11 ||
                      a.CODPROCEDI === 712 ||
                      a.CODPROCEDI === 713 ||
                      a.CODPROCEDI === 714 ? (
                      <td>{a.DESCPROCEDI}</td>
                    ) : a.CODPROCEDI === 7 ? (
                      <td>{a.DESCPROCEDI}</td>
                    ) : a.CODPROCEDI === 6 ? (
                      <td>{a.DESCPROCEDI}</td>
                    ) : (
                      <td>{a.DESCPROCEDI}</td>
                    )}
                    <td>{a.SIGLACONV}</td>
                    <td>{a.dataFormatada}</td>
                    <td>{a.CODLAUDO}</td>
                    <td style={{ textAlign: 'center' }}>
                      <Dropdown>
                        <Dropdown.Toggle variant="success">
                          Opções
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => handleLaudar(a)}>
                            Laudar
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleAttachFile(a)}>
                            Anexar Arquivo
                          </Dropdown.Item>
                          {a.CODPROCEDI === 11 ||
                          a.CODPROCEDI === 712 ||
                          a.CODPROCEDI === 713 ||
                          a.CODPROCEDI === 714 ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  dispatch(printClose());
                                  handleImprimir(a);
                                }}
                              >
                                Imprimir
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  dispatch(printOpen());
                                  handleImprimir(a);
                                }}
                              >
                                Imprimir Timbrado
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => handleImprimirTXT(a)}
                              >
                                ImprimirTXT
                              </Dropdown.Item>
                            </>
                          ) : (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  dispatch(printClose());
                                  handleImprimir(a);
                                }}
                              >
                                Imprimir
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  dispatch(printOpen());
                                  handleImprimir(a);
                                }}
                              >
                                Imprimir Timbrado
                              </Dropdown.Item>
                            </>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))
              : // MEDICO EXTERNO
                atividades.map(a =>
                  dbatividade.convenios.some(c => c.codConv === a.CODCONV) ? (
                    dbatividade.procedimentos.some(
                      procedi => procedi.codProcedi === a.CODPROCEDI
                    ) ? (
                      <tr key={a.counter}>
                        <td>{a.counter}</td>
                        {a.CODPROCEDI === 11 ||
                        a.CODPROCEDI === 712 ||
                        a.CODPROCEDI === 714 ? (
                          <td>
                            <Button variant="link" onClick={() => handleEco(a)}>
                              {a.DESCPROCEDI}
                            </Button>
                          </td>
                        ) : a.CODPROCEDI === 3 ? (
                          <td>
                            <Button variant="link" onClick={() => handleEcg(a)}>
                              {a.DESCPROCEDI}
                            </Button>
                          </td>
                        ) : a.CODPROCEDI === 13 ? (
                          <td>
                            <Button
                              variant="link"
                              onClick={() => handleStress(a)}
                            >
                              {a.DESCPROCEDI}
                            </Button>
                          </td>
                        ) : a.CODPROCEDI === 12 ? (
                          <td>
                            <Button
                              variant="link"
                              onClick={() => handleTrans(a)}
                            >
                              {a.DESCPROCEDI}
                            </Button>
                          </td>
                        ) : (
                          <td>{a.DESCPROCEDI}</td>
                        )}
                        <td>{a.SIGLACONV}</td>
                        <td>{a.dataFormatada}</td>
                        <td>{a.CODLAUDO}</td>
                      </tr>
                    ) : null
                  ) : null
                )}
          </tbody>
          <tfoot>
            <tr>
              <td style={{ textAlign: 'right' }} colSpan="3">
                TOTAL DE ATIVIDADES
              </td>
              <td style={{ textAlign: 'left' }} colSpan="2">
                {total}
              </td>
            </tr>
          </tfoot>
        </Table>
      )}

      <Modal show={showUpload} centered onHide={closeFileModal}>
        <Modal.Header closeButton>
          <Modal.Title>Anexar arquivo no laudo {codigoLaudo}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomFileInput
            id="file-upload"
            label="Selecionar arquivo"
            multiple={atividade?.CODLAUDO === 3 ? false : true}
            onChange={e => setFiles(Array.from(e.target.files))}
          />
          <ul>
            {files &&
              files.map((file, idx) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <li style={{ marginRight: 5 }}>{file.name}</li>
                  <FaTrashAlt
                    onClick={() => setFiles(f => f.filter((_, i) => i !== idx))}
                    cursor="pointer"
                    style={{ color: 'red' }}
                  />
                </div>
              ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          {uploadingFilesLoading ? (
            <Spinner animation="border" variant="dark" />
          ) : (
            <Button onClick={confirmUpload}>Confirmar</Button>
          )}
        </Modal.Footer>
      </Modal>

      {loading && <Loading />}

      {showEletroModal && (
        <div
          style={{
            position: 'fixed',
            zIndex: 1,
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            margin: 0,
            padding: 0,
            width: '100vw',
            height: '100vh',
            maxWidth: 'none !important',
            overflow: 'auto',
          }}
        >
          <div
            className="modal-content"
            style={{ width: '100% !important', height: '100vh' }}
          >
            <FormEcg
              ecg={laudo}
              isFromTab={false}
              setShowEletroModal={setShowEletroModal}
              showEletroModal={showEletroModal}
            />
          </div>
        </div>
      )}
    </>
  );
}
