import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Button, Card, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  loadingOpen,
  loadingClose,
  printOpen,
  printClose,
} from '../../../redux/features/context/contextSlice';
import Loading from '../../../pages/Index/Loading';
import api from '../../../services/api';
import s3 from '../../../util/s3';
import { dblaudo } from '../../../util/laudo';
import history from '../../../services/history';
import updateEcoById from '../../../services/eco';
import { liberarLaudoByPac } from '../../../services/prontuario';

export default function FormDoppler() {
  const dispatch = useDispatch();
  const { eco } = useSelector(state => state.eco);
  const medico = useSelector(state => state.doctor.profile);
  const { loading } = useSelector(state => state.contexto);
  const [showModal, setShowModal] = useState(false);
  const [urls, setURLs] = useState([]);
  const [files, setFiles] = useState([]);
  const [showUpload, setShowUpload] = useState(false);
  const [ecoLaudo, setEcoLaudo] = useState({
    ...eco,
    CODECO: eco?.CODECO || '',
    TIPOECO: eco?.TIPOECO || '',
    OBSERVACOES: eco?.OBSERVACOES || '',
  });

  useEffect(() => {
    if (medico?.tipo === 'Externo') history.push('/notAllowed');
  }, [medico]);

  useEffect(() => {
    s3.listObjectsV2(
      { Bucket: 'prevencor', Prefix: `eco/${eco?.CODECO}` },
      (err, data) => {
        if (err) toast.error('Erro ao buscar arquivos. Entre em contato.');
        else
          setFiles(
            data.Contents.filter(
              item =>
                item.Key.includes('.jpeg') ||
                item.Key.includes('.pdf') ||
                item.Key.includes('.mp4')
            )
          );
      }
    );
  }, [eco]);

  useEffect(() => {
    async function getFiles(f) {
      f = f.Key.split('/');

      return await api.get(
        `https://j87jesh814.execute-api.us-east-1.amazonaws.com/dev/image-upload/folder/fetal/laudo/${eco?.CODECO}/key/${f[2]}`
      );
    }

    async function showFiles() {
      const array = [];

      for (let file of files) {
        const url = await getFiles(file);
        array.push(url);
      }

      setURLs(array);
    }

    showFiles();
  }, [eco, files]);

  // eslint-disable-next-line
  const handleChangeOBSERVACOES = event => {
    setEcoLaudo({ ...ecoLaudo, OBSERVACOES: event.target.value });
  };

  // eslint-disable-next-line
  const handleTIPOECO = tipo => {
    let observacoes = '';
    switch (tipo) {
      case 'normal':
        observacoes = dblaudo.congenito[0].normal;
        setEcoLaudo({
          ...ecoLaudo,
          OBSERVACOES: observacoes,
          TIPOCONGENITO: tipo,
        });
        document.getElementById('editDescricao').focus();
        break;
      case 'civ':
        observacoes = dblaudo.congenito[1].civ;
        setEcoLaudo({
          ...ecoLaudo,
          OBSERVACOES: observacoes,
          TIPOCONGENITO: 'ECODOPPLERCARDIOGRAMA CONGÊNITO (CIV)',
        });
        document.getElementById('editDescricao').focus();
        break;
      case 'fop':
        observacoes = dblaudo.congenito[2].fop;
        setEcoLaudo({
          ...ecoLaudo,
          OBSERVACOES: observacoes,
          TIPOCONGENITO: 'ECODOPPLERCARDIOGRAMA CONGÊNITO (FOP)',
        });
        document.getElementById('editDescricao').focus();
        break;
      case 't4f':
        observacoes = dblaudo.congenito[3].t4f;
        setEcoLaudo({
          ...ecoLaudo,
          OBSERVACOES: observacoes,
          TIPOCONGENITO: 'ECODOPPLERCARDIOGRAMA CONGÊNITO (T4F)',
        });
        document.getElementById('editDescricao').focus();
        break;
      case 'pca':
        observacoes = dblaudo.congenito[4].pca;
        setEcoLaudo({
          ...ecoLaudo,
          OBSERVACOES: observacoes,
          TIPOCONGENITO: 'ECODOPPLERCARDIOGRAMA CONGÊNITO (PCA)',
        });
        document.getElementById('editDescricao').focus();
        break;
      case 'cia':
        observacoes = dblaudo.congenito[5].cia;
        setEcoLaudo({
          ...ecoLaudo,
          OBSERVACOES: observacoes,
          TIPOCONGENITO: 'ECODOPPLERCARDIOGRAMA CONGÊNITO (CIA)',
        });
        document.getElementById('editDescricao').focus();
        break;
      default:
    }
  };

  const handleGravar = async event => {
    event.preventDefault();
    dispatch(loadingOpen());
    await updateEcoById(ecoLaudo);
    dispatch(loadingClose());
  };

  function handleLiberarLaudo() {
    dispatch(loadingOpen());
    const fatura = { codLaudo: ecoLaudo.CODECO, codPac: ecoLaudo.CODPAC };
    liberarLaudoByPac({ fatura });
    dispatch(loadingClose());
  }

  async function handleUploadFile(file) {
    if (!file) return toast.warn('Selecione um arquivo!');

    const encodedFilename = encodeURIComponent(file.name);

    try {
      const { data } = await api.put(
        `https://j87jesh814.execute-api.us-east-1.amazonaws.com/dev/image-upload/folder/eco/laudo/${eco?.CODECO}/key/${encodedFilename}`
      );

      await fetch(data.preSignedUrl, { method: 'PUT', body: file });
    } catch (error) {
      toast.error('Erro ao fazer upload do arquivo!');
    }
  }

  async function confirmUpload() {
    for (const file of files) {
      await handleUploadFile(file);
    }

    toast.success('Arquivos anexados com sucesso!');
  }

  function closeFileModal() {
    setShowUpload(false);
    setFiles([]);
  }

  return (
    <>
      <Card>
        <Card.Header className="text-center">
          <Card.Title>LAUDO DE ECO DOPPLER - EM DESENVOLVIMENTO</Card.Title>
        </Card.Header>

        <Card.Body>
          <Form noValidate>
            <strong className="mr-auto">Nome do Paciente: </strong>
            {`${ecoLaudo.CODPAC} - ${ecoLaudo.NOMEPAC}`}
            <br></br>
            <strong className="mr-auto">Data de Nasc: </strong>
            {`${ecoLaudo.dataNasc}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Sexo: </strong>
            {`${ecoLaudo.Sexo}`}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Idade: </strong>
            {`${ecoLaudo.idadePAC} anos`}
            <br></br>
            <strong className="mr-auto">Convênio: </strong>
            {ecoLaudo.SIGLACONV}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Cod Laudo: </strong>
            {ecoLaudo.CODECO}&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Data do Exame: </strong>
            {ecoLaudo.dataEcoFmt}
            <br></br>
            <strong className="mr-auto">Médico que Realizou: Dr. </strong>
            {`${ecoLaudo.Realizou}`}
            <br></br>
            <strong className="mr-auto">Médico que Solicitou: Dr. </strong>
            {`${ecoLaudo.Solicitou}`}
            <hr></hr>{' '}
            <Card className="mt-3 text-center">
              <Card.Footer className="text-muted">
                <Button variant="success" onClick={handleGravar}>
                  Gravar
                </Button>{' '}
                <Button onClick={handleLiberarLaudo}>Liberar Laudo</Button>{' '}
                <Button
                  variant="secondary"
                  onClick={() => {
                    dispatch(printClose());
                    history.push('/reportEcoCongenitoTXT');
                  }}
                >
                  Imprimir
                </Button>{' '}
                <Button
                  variant="secondary"
                  onClick={() => {
                    dispatch(printOpen());
                    history.push('/reportEcoCongenitoTXT');
                  }}
                >
                  Imprimir Timbrado
                </Button>{' '}
                <Button variant="warning" onClick={() => setShowUpload(true)}>
                  Anexar
                </Button>{' '}
                <Button
                  variant="danger"
                  onClick={() => history.push('/agenda')}
                >
                  Fechar
                </Button>
              </Card.Footer>
            </Card>
          </Form>
        </Card.Body>
      </Card>

      {loading && <Loading />}

      <Modal show={showUpload} onHide={closeFileModal}>
        <Modal.Header closeButton>
          <Modal.Title>Anexar arquivo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="laudos">Selecione pelo menos 1 arquivo</label>
          <br />
          <input
            type="file"
            style={{ color: 'white' }}
            multiple
            onChange={e => setFiles(Array.from(e.target.files))}
          />
          <ul>{files && files.map(file => <li>{file.name}</li>)}</ul>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={confirmUpload}>Confirmar</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Visualização</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {urls.map(url => {
            return url.data.preSignedUrl.includes('.webm') ||
              url.data.preSignedUrl.includes('.mp4') ? (
              <div style={{ textAlign: 'center', display: 'block' }}>
                <video id="video-laudo" width="500" height="500" controls>
                  <source
                    src={url.data.preSignedUrl}
                    type={
                      url.data.preSignedUrl.endsWith('.mp4')
                        ? 'video/mp4'
                        : 'video/webm'
                    }
                  />
                </video>
              </div>
            ) : (
              <div style={{ textAlign: 'center', display: 'block' }}>
                <embed
                  src={url.data.preSignedUrl}
                  width="600px"
                  height="900px"
                />
              </div>
            );
          })}
        </Modal.Body>
      </Modal>
    </>
  );
}
