import { createGlobalStyle } from 'styled-components';

export const btnStyle = {
  background: 'none',
  color: 'blue',
  border: 'none',
  padding: 0,
  font: 'inherit',
  cursor: 'pointer',
  textDecoration: 'underline',
};

const ReportStyles = createGlobalStyle`
  @media print {
  @page {
    margin-top:4cm;
    margin-bottom: 3cm;
  }

  .page {
    page-break-after: always;
  }

  .page:first-of-type {
    margin-top: 0;
  }

  .page:last-of-type {
    margin-bottom: 0;
    page-break-after: auto;
  }
}`;

export default ReportStyles;
