import React, { useState, useRef } from 'react';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { useSelector, useDispatch } from 'react-redux';
import { SimpleTreeView } from '@mui/x-tree-view';
import {
  Col,
  Card,
  Form,
  Button,
  ButtonGroup,
  Alert,
  Spinner,
} from 'react-bootstrap';
import md5 from 'md5';
import QRCode from 'qrcode';
import sha256 from 'js-sha256';
import jsPDF from 'jspdf';
import { FaArrowRight, FaAngleDown } from 'react-icons/fa';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import header from '../../assets/icm-header.jpg';
import icp from '../../assets/icp-brasil.png';
import OrientacaoMarevan from '../../pages/laudo/prontuario/OrientacaoMarevan';
import OrientacaoDOC from '../../pages/laudo/prontuario/OrientacaoDOC';
import { updateReceita, insertReceita } from '../../services/prontuario';
import { digitalSignature } from '../../redux/features/certificate/certificateSlice';
import {
  signingOpen,
  signingClose,
} from '../../redux/features/context/contextSlice';
import dbprontuario from '../../util/prontuario';
import removeBeforeBase64 from '../../util/removeBeforeBase64';
import getPrescricao from '../../util/getPrescricao';
import { prefixo } from '../../util/getPrefix';

const QRCODE_RECEITA = 'https://icm-marilia.com/receita/validacao';

export default function ReceituarioMedico({
  closeModal,
  receita,
  setReceita,
  handleReceitas,
  receitas,
  renderTreeMedicamento = null,
  assinaturaDigital = null,
  setAssinaturaDigital = null,
}) {
  const orientacaoRef = useRef();
  const orientacaoDOCRef = useRef();
  const dispatch = useDispatch();
  const medico = useSelector(state => state.doctor.profile);
  const { signing } = useSelector(state => state.contexto);
  const { patient } = useSelector(state => state.paciente);
  const printOrientacaoMarevan = useReactToPrint({ contentRef: orientacaoRef });
  const printOrientacaoDOC = useReactToPrint({ contentRef: orientacaoDOCRef });
  const [usoReceita, setUsoReceita] = useState('oral-continuo');
  const [alert, setAlert] = useState({ variant: '', mensagem: '' });
  const [showAlertGravada, setShowAlertGravada] = useState(false);
  const [receitaCounter, setReceitaCounter] = useState(1);
  const isDoctorLoggedIn = !!medico;

  const handleGravarReceita = async () => {
    if (receita?.codReceita) {
      await updateReceita(receita);
      setShowAlertGravada(true);
      setReceitaCounter(1);
      setAlert({ variant: 'success', mensagem: 'Receita gravada!' });
    } else {
      toast.warn('Selecione uma receita!');
    }
  };

  const handlePrescreverNovaReceita = () => {
    const dt = format(new Date(), "dd 'de' MMMM 'de' yyyy", { locale: pt });
    const enderecoPac = `${patient?.LOGRA_PAC} ${patient?.ENDRESPAC}, ${patient?.ENDERECO_NR} - ${patient?.BAIRROPAC} - ${patient?.CIDADEPAC}/${patient?.ESTADOPAC}`;
    const cabecalho = `
  Marília/SP, ${dt}.\n\n
  Nome: ${patient?.NOMEPAC} - CPF: ${patient?.CPF ?? patient?.ENDMARPAC}
  Endereço: ${enderecoPac}\n
  ${
    usoReceita === 'oral-continuo'
      ? 'USO ORAL CONTÍNUO'
      : 'USO EXTERNO CONTÍNUO'
  }\n`;

    const payload = {
      codConsulta: receita.codConsulta,
      codMedico: medico.codMed,
      codPaciente: receita.codPaciente,
      tipo: 'Normal',
      descricao: cabecalho,
      status: 'ONLINE',
    };

    insertReceita(payload).then(response => {
      setShowAlertGravada(true);
      setAlert({
        variant: 'success',
        mensagem: 'Gerada Nova Receita. Selecione os Medicamentos da Listagem!',
      });
      setReceita({
        codReceita: response[0].CODRECEITA,
        codPaciente: response[0].CODPAC,
        codMedico: response[0].CODMEDICO,
        nomeMedico: response[0].NOMEMED,
        crm: response[0].CRMMED,
        cpf: response[0].CPFMED,
        rqe: response[0].RQE,
        nomePaciente: response[0].NOMEPAC,
        codConsulta: response[0].CODCONSULTA,
        dataRec: response[0].dataFormatada,
        tipo: response[0].TIPORECEITA,
        descricao: response[0].DESCRICAO,
        status: response[0].STATUS,
      });
      setReceitaCounter(1);
      handleReceitas(patient);
    });
  };

  const handleImprimirReceita = async (receita, timbrado) => {
    const styles = {
      title: { fontSize: 12, textAlign: 'center', marginTop: 20 },
      content: { fontSize: 11, marginLeft: timbrado ? 20 : 60, marginTop: 20 },
      footer: {
        fontSize: 9,
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 20,
      },
    };

    const doc = new jsPDF();

    const qrCodeCanvas = document.createElement('canvas');
    await QRCode.toCanvas(qrCodeCanvas, QRCODE_RECEITA);
    const qrCodeDataUrl = qrCodeCanvas.toDataURL('image/jpeg');
    const qrCodeImageWidth = 50;
    const qrCodeImageHeight = 50;
    const qrCodeImageX =
      (doc.internal.pageSize.getWidth() - qrCodeImageWidth) / 2;
    const qrCodeImageY =
      doc.internal.pageSize.getHeight() -
      styles.footer.marginTop -
      qrCodeImageHeight +
      20;

    if (assinaturaDigital) {
      dispatch(signingOpen());

      const imageX = (doc.internal.pageSize.getWidth() - 150) / 2;
      const linhas = doc.splitTextToSize(receita.descricao, 180);

      if (timbrado) doc.addImage(header, 'JPEG', imageX, 5, 150, 25);

      doc.setFontSize(styles.title.fontSize);
      doc.setFontSize(styles.content.fontSize);
      linhas.forEach((linha, indice) => {
        doc.text(
          `${linha}`,
          timbrado ? styles.content.marginLeft + 35 : styles.content.marginLeft,
          styles.title.marginTop + 40 + indice * 5,
          { align: 'justify', charSpace: 0 }
        );
      });

      doc.setFontSize(styles.footer.fontSize);
      doc.setFont('helvetica', 'bold');

      const x = doc.internal.pageSize.getWidth() / 2;

      doc.text(
        `Documento assinado digitalmente`,
        x,
        doc.internal.pageSize.getHeight() - 60,
        { align: 'center' }
      );
      doc.text(
        `Autenticação: ${md5(JSON.stringify(receita))}`,
        x,
        doc.internal.pageSize.getHeight() - 55,
        { align: 'center' }
      );
      doc.text(
        `Médico: ${prefixo(receita)} ${receita.nomeMedico}`,
        x,
        doc.internal.pageSize.getHeight() - 50,
        { align: 'center' }
      );
      doc.text(
        `CPF: ${receita.cpf} - CRM: ${receita.crm} ${
          receita.rqe ? `- RQE: ${receita.rqe}` : ''
        }`,
        x,
        doc.internal.pageSize.getHeight() - 45,
        { align: 'center' }
      );
      doc.text(
        `* A assinatura digital deste documento poderá ser verificada em https://validar.iti.gov.br/ *`,
        x,
        doc.internal.pageSize.getHeight() - 40,
        { align: 'center' }
      );
      doc.text(
        `* FARMÁCIA: Realizar a dispensação da RECEITA: ${receita.codReceita} - PACIENTE: ${receita.codPaciente} usando o QRCode *`,
        x,
        doc.internal.pageSize.getHeight() - 35,
        { align: 'center' }
      );

      doc.addImage(
        icp,
        'PNG',
        qrCodeImageX - 40,
        qrCodeImageY - 18,
        qrCodeImageWidth - 30,
        qrCodeImageHeight - 35
      );

      doc.addImage(
        qrCodeDataUrl,
        'JPEG',
        qrCodeImageX + 70,
        qrCodeImageY - 18,
        qrCodeImageWidth - 35,
        qrCodeImageHeight - 35
      );

      const fileReceita = doc.output('dataurlstring');
      await handleVidaasAssinar(fileReceita);
    } else {
      const imageX = (doc.internal.pageSize.getWidth() - 150) / 2;
      const linhas = doc.splitTextToSize(receita.descricao, 180); // Quebra o conteúdo em linhas de até 180 caracteres

      if (timbrado) doc.addImage(header, 'JPEG', imageX, 5, 150, 25); // x, y, w, h

      doc.setFontSize(styles.title.fontSize);
      doc.setFontSize(styles.content.fontSize);
      linhas.forEach((linha, indice) => {
        doc.text(
          `${linha}`,
          timbrado ? styles.content.marginLeft + 35 : styles.content.marginLeft,
          styles.title.marginTop + 40 + indice * 5,
          { align: 'justify', charSpace: 0 }
        );
      });

      doc.setFontSize(styles.footer.fontSize);
      doc.setFont('helvetica', 'bold');

      const x = doc.internal.pageSize.getWidth() / 2;

      doc.text(
        `Documento assinado eletronicamente`,
        x,
        doc.internal.pageSize.getHeight() - 50,
        { align: 'center' }
      );
      doc.text(
        `Autenticação: ${md5(JSON.stringify(receita))}`,
        x,
        doc.internal.pageSize.getHeight() - 45,
        { align: 'center' }
      );
      doc.text(
        `Médico: ${prefixo(receita)} ${receita.nomeMedico}`,
        x,
        doc.internal.pageSize.getHeight() - 40,
        { align: 'center' }
      );
      doc.text(
        `CPF: ${receita.cpf} - CRM: ${receita.crm} ${
          receita.rqe ? `- RQE: ${receita.rqe}` : ''
        }`,
        x,
        doc.internal.pageSize.getHeight() - 35,
        { align: 'center' }
      );
      doc.text(
        `* VALIDAÇÃO: Aponte a câmera do celular usando o QRCode ao lado *`,
        x,
        doc.internal.pageSize.getHeight() - 30,
        { align: 'center' }
      );
      doc.text(
        `* Realizar a dispensação da RECEITA: ${receita.codReceita} - PACIENTE: ${receita.codPaciente} *`,
        x,
        doc.internal.pageSize.getHeight() - 25,
        { align: 'center' }
      );
      doc.addImage(
        qrCodeDataUrl,
        'JPEG',
        qrCodeImageX + 85,
        qrCodeImageY + 7,
        qrCodeImageWidth - 30,
        qrCodeImageHeight - 30
      );
      doc.output('dataurlnewwindow');
    }
  };

  const handleRepetirNovaReceita = () => {
    const dt = format(new Date(), "dd 'de' MMMM 'de' yyyy", { locale: pt });
    const linhas = receita.descricao.split('\n');

    for (let i = 0; i < linhas.length; i++) {
      if (linhas[i].includes('Marília')) {
        linhas[i] = `Marília, ${dt}.`;
        break;
      }
    }

    const payload = {
      codConsulta: receita.codConsulta,
      codMedico: medico ? medico?.codMed : receita.codMedico,
      codPaciente: receita.codPaciente,
      tipo: 'Normal',
      descricao: linhas.join('\n'),
      status: 'Clonada',
    };

    insertReceita(payload).then(response => {
      setShowAlertGravada(true);
      setAlert({
        variant: 'success',
        mensagem: 'Gerada Nova Receita. Selecione os Medicamentos da Listagem!',
      });
      setReceita({
        codReceita: response[0].CODRECEITA,
        codPaciente: response[0].CODPAC,
        codMedico: response[0].CODMEDICO,
        nomeMedico: response[0].NOMEMED,
        crm: response[0].CRMMED,
        cpf: response[0].CPFMED,
        rqe: response[0].RQE,
        nomePaciente: response[0].NOMEPAC,
        codConsulta: response[0].CODCONSULTA,
        dataRec: response[0].dataFormatada,
        tipo: response[0].TIPORECEITA,
        descricao: response[0].DESCRICAO,
        status: response[0].STATUS,
      });
      handleReceitas(patient);
    });
  };

  const handleSelectMedicamentoReceita = (event, nodeId, elmID) => {
    dbprontuario.medicamentos.children.forEach(classe => {
      classe.children.forEach(e => {
        if (e.id === nodeId) {
          setReceitaCounter(receitaCounter + 1);
          setReceita({
            ...receita,
            descricao:
              (receita.descricao ?? '') +
              '\n' +
              getPrescricao(e.name, receitaCounter),
          });
          document.getElementById(elmID).focus();
          event.stopPropagation();
        }
      });
    });
  };

  const handleVidaasAssinar = async content => {
    const payload = {
      id: receita.codReceita.toString(),
      alias: receita.codReceita + '-receita.pdf',
      hash: sha256(JSON.stringify(content)),
      hash_algorithm: '2.16.840.1.101.3.4.2.1',
      signature_format: 'CAdES_AD_RT',
      base64_content: removeBeforeBase64(content),
    };

    dispatch(digitalSignature(payload)).then(resp => {
      dispatch(signingClose());
      if (JSON.stringify(resp) !== '{}' && resp?.status !== 403) {
        const decodedData = atob(resp?.signatures[0].file_base64_signed);
        const byteArray = new Uint8Array(decodedData.length);

        for (let i = 0; i < decodedData.length; i++) {
          byteArray[i] = decodedData.charCodeAt(i);
        }

        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const fileReceita = URL.createObjectURL(blob);
        document.open(fileReceita, '_blank', '');
      } else {
        toast.warn('Usuário ainda não AUTENTICADO!');
      }
    });
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: '0 0 12.5%' }}>
          {receitas?.length > 0 ? (
            <ButtonGroup vertical>
              {receitas.map((rec, index) => (
                <Button
                  size="md"
                  variant="outline-primary"
                  key={index}
                  onClick={() => {
                    setReceita({
                      codReceita: rec.CODRECEITA,
                      codPaciente: rec.CODPAC,
                      codMedico: rec.CODMEDICO,
                      nomeMedico: rec.NOMEMED,
                      crm: rec.CRMMED,
                      cpf: rec.CPFMED,
                      rqe: rec.RQE,
                      nomePaciente: rec.NOMEPAC,
                      codConsulta: rec.CODCONSULTA,
                      dataRec: rec.dataFormatada,
                      tipo: rec.TIPORECEITA,
                      descricao: rec.DESCRICAO,
                      status: rec.STATUS,
                    });
                  }}
                >
                  {rec.dataFormatada}
                </Button>
              ))}
            </ButtonGroup>
          ) : (
            <Alert variant="danger" className="mr-1">
              Não existe receita para esse paciente!
            </Alert>
          )}
        </div>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Card className="p-1">
            <Form noValidate>
              <Form.Row>
                <Form.Group as={Col} sm controlId="editNomePAC">
                  <Form.Label>
                    <strong>Nome do Paciente</strong>
                  </Form.Label>
                  <Form.Control readOnly value={receita.nomePaciente} />
                </Form.Group>
                <Form.Group as={Col} sm controlId="editNOMEMED">
                  <Form.Label>
                    <strong>Nome do Médico</strong>
                  </Form.Label>
                  <Form.Control readOnly value={receita.nomeMedico} />
                </Form.Group>
              </Form.Row>
              <div style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                {isDoctorLoggedIn && (
                  <Button size="sm" onClick={handlePrescreverNovaReceita}>
                    Nova Receita
                  </Button>
                )}
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={handleRepetirNovaReceita}
                >
                  Repetir Receita
                </Button>
                {isDoctorLoggedIn && (
                  <>
                    <Button
                      variant="outline-success"
                      size="sm"
                      onClick={printOrientacaoMarevan}
                    >
                      Orientação Marevan
                    </Button>{' '}
                    <Button
                      variant="outline-success"
                      size="sm"
                      onClick={printOrientacaoDOC}
                    >
                      Orientação DOC
                    </Button>
                    <div>
                      <Form.Check
                        inline
                        className="ml-5"
                        label="Normal"
                        checked={receita.tipo === 'Normal'}
                        name="groupRec"
                        type="radio"
                        id="Normal"
                        onClick={e =>
                          setReceita({ ...receita, tipo: e.target.id })
                        }
                      />
                      <Form.Check
                        inline
                        className="ml-5"
                        label="Controlada"
                        checked={receita.tipo === 'Controlada' ? true : false}
                        name="groupRec"
                        type="radio"
                        id="Controlada"
                        onClick={e =>
                          setReceita({ ...receita, tipo: e.target.id })
                        }
                      />
                    </div>
                    <div>
                      <Form.Check
                        inline
                        className="ml-5"
                        label="Uso oral contínuo"
                        checked={usoReceita === 'oral-continuo'}
                        name="uso-receita"
                        type="radio"
                        onClick={() => setUsoReceita('oral-continuo')}
                      />
                      <Form.Check
                        inline
                        className="ml-5"
                        label="Uso externo contínuo"
                        checked={usoReceita === 'oral-externo'}
                        name="uso-receita"
                        type="radio"
                        onClick={() => setUsoReceita('oral-externo')}
                      />
                    </div>
                  </>
                )}
              </div>
              <hr></hr>
              <Form.Row>
                <Col sm>
                  <Form.Group size="sm" controlId="editMedicamentosReceita">
                    <Form.Label>
                      <strong>
                        Descrição da Receita - {receita?.codReceita} -{' '}
                        {receita?.dataRec}
                      </strong>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      readOnly={isDoctorLoggedIn ? false : true}
                      style={{ fontSize: '12px' }}
                      rows={20}
                      value={receita.descricao ?? ''}
                      onChange={e =>
                        setReceita({ ...receita, descricao: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
                {renderTreeMedicamento && (
                  <SimpleTreeView
                    aria-label="rich object"
                    defaultCollapseIcon={<FaAngleDown size={10} />}
                    defaultExpanded={['root']}
                    slots={{
                      expandIcon: FaArrowRight,
                      collapseIcon: FaAngleDown,
                    }}
                    onSelectedItemsChange={(event, nodeId) =>
                      handleSelectMedicamentoReceita(
                        event,
                        nodeId,
                        'editMedicamentosReceita'
                      )
                    }
                  >
                    {renderTreeMedicamento(dbprontuario.medicamentos)}
                  </SimpleTreeView>
                )}
              </Form.Row>
            </Form>
            {showAlertGravada && (
              <Alert variant={alert.variant}>{alert.mensagem}</Alert>
            )}
            {isDoctorLoggedIn && (
              <Form.Check
                type="switch"
                id="custom-switch"
                defaultChecked={assinaturaDigital}
                label="Assinatura Digital"
                onChange={() => setAssinaturaDigital(!assinaturaDigital)}
              />
            )}
            <Card.Footer className="text-center">
              {isDoctorLoggedIn && (
                <Button onClick={handleGravarReceita}>Gravar</Button>
              )}{' '}
              <Button
                variant="warning"
                onClick={() => handleImprimirReceita(receita, false)}
              >
                Imprimir
              </Button>{' '}
              <Button
                variant="info"
                onClick={() => handleImprimirReceita(receita, true)}
              >
                Timbrado
              </Button>{' '}
              <Button variant="secondary" onClick={closeModal}>
                Fechar
              </Button>
              {signing && (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className=""> Assinando Receita...</span>
                </>
              )}
            </Card.Footer>
          </Card>
        </div>
      </div>

      <div style={{ display: 'none' }}>
        <OrientacaoMarevan ref={orientacaoRef} />
      </div>
      <div style={{ display: 'none' }}>
        <OrientacaoDOC ref={orientacaoDOCRef} />
      </div>
    </>
  );
}
