import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Button, Card } from 'react-bootstrap';
import fisio from '../../../assets/esp-fisio.png';
import styles from '../../../styles/styles.module.css';
import getPrefix from '../../../util/getPrefix';

export default function EspelhoFisio() {
  const { stress } = useSelector(state => state.stress);

  function removeDiv(divName) {
    const newdiv = document.createElement('div');
    const originalContents = document.body.innerHTML;
    newdiv.innerHTML = originalContents;
    document.getElementById(divName).remove(newdiv);
    window.print();
    document.body.innerHTML = originalContents;
  }

  return (
    <Card id="cardHeader">
      <div id="printMe">
        <Card.Header className="text-center">
          <Card.Title className={styles.titleReport}>
            LAUDO DE ECO ESTRESSE - FISIO
          </Card.Title>
        </Card.Header>

        <Card.Body>
          <Form noValidate className={styles.contentReport}>
            <strong className="mr-auto">Código: </strong>
            {stress[0]?.CODSTRESS}&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Nome do Paciente: </strong>
            {`${stress[0]?.CODPAC} - ${stress[0]?.NOMEPAC}`}
            <br></br>
            <strong className="mr-auto">Data de Nasc: </strong>
            {`${stress[0]?.dataNasc}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Sexo: </strong>
            {`${stress[0]?.SEXOPAC}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Idade: </strong>
            {`${stress[0]?.idadePAC} anos`}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Peso: </strong>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Alt: </strong>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">SC: </strong>
            <br></br>
            <strong className="mr-auto">Convênio: </strong>
            {stress[0]?.SIGLACONV}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Data do Stress: </strong>
            {stress[0]?.dataStress}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Fone: </strong>
            {stress[0]?.CELULARPAC}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <br></br>
            <strong className="mr-auto">
              Médico que Realizou: {getPrefix(stress[0]?.CODREALIZOU)}{' '}
            </strong>
            {`${stress[0]?.Realizou}`}
            <br></br>
            <strong className="mr-auto">
              Médico que Solicitou: {getPrefix(stress[0]?.CODSOLICITANTE)}{' '}
            </strong>
            {`${stress[0]?.Solicitou}`}
            <hr></hr>
            <div>
              <img
                width="80%"
                height="50%"
                loading="lazy"
                src={fisio}
                alt="espelho fisio"
              />{' '}
            </div>
          </Form>
        </Card.Body>
      </div>
      <div id="removeMe" className="text-right">
        <Button variant="outline-primary" onClick={() => removeDiv('removeMe')}>
          Imprimir
        </Button>{' '}
      </div>
    </Card>
  );
}
