import * as React from 'react';
import { Button } from 'react-bootstrap';
import history from '../../../services/history';

const images = [
  { url: '../images/buttons/agenda.jpg', title: 'Novo Agendamento' },
  { url: '/images/buttons/paciente.jpg', title: 'Meus Agendamentos' },
  { url: '/images/buttons/laudos.jpg', title: 'Resultado de Exames' },
];

export default function Dashboard() {
  function handlePaciente(title) {
    if (title === 'Novo Agendamento') history.push('/panelpac');
    if (title === 'Resultado de Exames') history.push('/frmatvpac');
    if (title === 'Meus Agendamentos') history.push('/agendapac');
  }

  return (
    <>
      {images.map(image => (
        <Button
          onClick={() => handlePaciente(image.title)}
          key={image.title}
          style={{
            marginTop: 10,
            width: '33%',
            height: 200,
            backgroundImage: `url(${image.url})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'relative',
            color: 'white',
            margin: '2px',
          }}
        >
          <div
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: 30,
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
              transition: 'background-color 0.7s',
            }}
            onMouseEnter={e =>
              (e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.6)')
            }
            onMouseLeave={e =>
              (e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.4)')
            }
          >
            <span>{image.title}</span>
          </div>
        </Button>
      ))}
    </>
  );
}
