import React from 'react';
import { useLocation } from 'react-router-dom';
import { Form, Button, Card, Alert, Spinner } from 'react-bootstrap';
import useSWR from 'swr';
import { getScan } from '../../../services/carotidas';
import espcarotida from '../../../assets/esp-carotida.png';
import styles from '../../../styles/styles.module.css';
import getPrefix from '../../../util/getPrefix';

export default function EspelhoCarotida() {
  const location = useLocation();
  const { codLaudo } = location.state;
  const { data: carotida, error, isLoading } = useSWR('carotida', () =>
    getScan(codLaudo)
  );

  if (error) {
    return (
      <Alert variant="danger" style={{ marginTop: 10 }}>
        <Alert.Heading>Erro ao carregar laudo!</Alert.Heading>
        <p>Tente novamente.</p>
      </Alert>
    );
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
        }}
      >
        <Spinner animation="border" variant="dark" size="lg" />
      </div>
    );
  }

  return (
    <Card>
      <Card.Body>
        <Form noValidate className={styles.titleEspelho}>
          <strong className="mr-auto">Código: </strong>
          {carotida?.carotida[0]?.CODSCAN}&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Paciente: </strong>
          {`${carotida?.carotida[0]?.CODPAC} - ${carotida?.carotida[0]?.NOMEPAC}`}
          <br></br>
          <strong className="mr-auto">Sexo: </strong>
          {`${carotida?.carotida[0]?.SEXOPAC}`}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Data de Nasc: </strong>
          {`${carotida?.carotida[0]?.dataNasc}`}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Idade: </strong>
          {`${carotida?.carotida[0]?.idadePAC} anos`}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Data do Laudo: </strong>
          {carotida?.carotida[0]?.dataScanFmt}
          <br></br>
          <strong className="mr-auto">
            Médico que Realizou: {getPrefix(carotida?.carotida[0]?.CODREALIZOU)}{' '}
          </strong>
          {`${carotida?.carotida[0]?.Realizou}`}
          <br></br>
          <strong className="mr-auto">
            Médico que Solicitou:{' '}
            {getPrefix(carotida?.carotida[0]?.CODSOLICITANTE)}{' '}
          </strong>
          {`${carotida?.carotida[0]?.Solicitante}`}
          <br></br>
          <strong className="mr-auto">Convênio: </strong>
          {carotida?.carotida[0]?.SIGLACONV}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Fone: </strong>
          {carotida?.carotida[0]?.CELULARPAC}
          <hr></hr>
          <div className={styles.imageReport}>
            <img
              width="1400px"
              height="1600px"
              loading="lazy"
              src={espcarotida}
              alt="espelho carotida"
            />{' '}
          </div>
        </Form>
      </Card.Body>

      <div className="text-right">
        <Button variant="outline-primary" onClick={() => window.print()}>
          Imprimir
        </Button>
      </div>
    </Card>
  );
}
